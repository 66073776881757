import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps
} from '@progress/kendo-react-grid';
import React from 'react';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { Button } from '@progress/kendo-react-buttons';
import { ThemeColor } from '../../types/theme-color';

export interface EditCellProps extends GridCellProps {
  onViewClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  viewThemeColor?: ThemeColor;
  viewIcon?: string | 'visibility';
  onEditClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  editIcon?: string | 'edit';
  editThemeColor?: ThemeColor;
}

export const EditCell = ({
  onViewClick,
  viewIcon,
  viewThemeColor,
  onEditClick,
  editIcon,
  editThemeColor,
  ...props
}: EditCellProps) => {
  const field = props.field || '';
  const value = props.dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      className={'k-table-td'}
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}>
      <div className={'d-flex justify-content-around align-items-center'}>
        <Button
          onClick={onEditClick}
          themeColor={editThemeColor ?? 'primary'}
          size={'small'}
          className={'small'}
          fillMode={'flat'}>
          <span className={'material-symbols-outlined'}>
            {editIcon ?? 'edit'}
          </span>
        </Button>
      </div>
    </td>
  );
};

export default EditCell;
