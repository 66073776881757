import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridDetailRowProps,
  GridExpandChangeEvent
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { GetExpandedEventsAsAdmin } from '../services/events';
import { ColumnMenu } from '../components/columnMenu';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { formatDateToDDMMYYYY } from '../utils/date';
import EventsScheduler from './events-scheduler';
import { ExpandedEvent } from '../types/event';

const initialDataState: State = {
  sort: [{ field: 'startDateUtc', dir: 'desc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};
interface ActivityData {
  startDateUtc?: Date;
  endDateUtc?: Date;
  name?: string;
  code?: string;
  type?: string;
  pax?: string;
  status?: string;
}

const DetailComponent = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem as ExpandedEvent;
  const assessmentData: ActivityData[] = dataItem.eventPlaylists.map(
    (item) => ({
      startDateUtc: item.startDateUtc,
      endDateUtc: item.endDateUtc,
      code: item.code,
      pax: item.gameApproxPax,
      name: item.assessmentName,
      type: 'Assessment',
      status: item.status
    })
  );

  const gameData: ActivityData[] = dataItem.eventPlaylists
    .filter((item) => item.gameName)
    .map((item) => ({
      startDateUtc: item.startDateUtc,
      endDateUtc: item.endDateUtc,
      code: item.code,
      pax: item.gameApproxPax,
      name: item.gameName,
      type: 'Game',
      status: item.status
    }));

  const feedbackData: ActivityData[] = dataItem.eventFeedbacks.map((item) => ({
    startDateUtc: item.startDateUtc,
    endDateUtc: item.endDateUtc,
    code: item.code,
    name: item.name,
    type: 'Feedback',
    status: item.status
  }));

  const data = [...assessmentData, ...gameData, ...feedbackData];

  return (
    <>
      {data.length !== 0 && (
        <table className="k-table k-grid-header-table k-table-md event-detail-table">
          <thead className="k-table-thead">
            <tr className="k-table-row">
              <th className=" k-table-th k-header k-sorted table-header">
                Start Date
              </th>
              <th className="k-table-th k-header k-sorted table-header">
                End Date
              </th>
              <th className="k-table-th k-header k-sorted table-header">
                Name
              </th>
              <th className="k-table-th k-header k-sorted table-header">
                Type
              </th>
              <th className="k-table-th k-header k-sorted table-header">
                Code
              </th>
              <th className="k-table-th k-header k-sorted table-header">Pax</th>
              <th className="k-table-th k-header k-sorted table-header">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={`row-${index}`} className="table-row">
                <td className="table-cell">
                  {formatDateToDDMMYYYY(item.startDateUtc!, true)}
                </td>
                <td className="table-cell">
                  {formatDateToDDMMYYYY(item.endDateUtc!, true)}
                </td>
                <td className="table-cell">{item.name}</td>
                <td className="table-cell">{item.type}</td>
                <td className="table-cell">{item.code}</td>
                <td className="table-cell">{item.pax}</td>
                <td className="table-cell">{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

const EventsManagementGrid = () => {
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [events, setEvents] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));
  const [gridMode, setGridMode] = useState<string>('grid');

  const fetchEvents = async () => {
    GetExpandedEventsAsAdmin(`${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        const data = response.data.map((item) => ({
          ...item,
          expanded: false
        }));

        response.data = data;
        setEvents(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchEvents();
    //eslint-disable-next-line
  }, [dataState]);

  const gameColumn = (props: GridCellProps) => {
    const dataItem = props.dataItem as ExpandedEvent;

    const isContainGame = dataItem.eventPlaylists.some((item) => item.gameName);

    return (
      <td className={'k-table-td'}>
        <span>{isContainGame ? 'Game' : 'Assessment'}</span>
      </td>
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        {formatDateToDDMMYYYY(props.dataItem.startDateUtc, true)}
      </td>
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const updatedData = events.data.map((item) => ({
      ...item,
      expanded:
        item.id === event.dataItem.id ? !event.dataItem.expanded : item.expanded
    }));

    setEvents({
      ...events,
      data: updatedData
    });
  };

  return (
    <>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search events'}
          columnsToSearch={['name', 'organisation']}
          showCalendarMode={true}
          showCardMode={false}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            const newState = {
              ...initialDataState
            };
            if (e.gridMode === 'grid') {
              newState.take = DefaultGridSettings.initialDataState.take;
            }
            setDataState(newState);
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={events.data}
          ref={gridExportRef}
          fileName={`all-events.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-events'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={events}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}>
              <Column
                field={'startDateUtc'}
                title={'Start date'}
                cell={DateCell}
              />
              <Column field={'endDateUtc'} title={'End date'} cell={DateCell} />
              <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
              <Column cell={gameColumn} field="Type" title={'Type'} />
              <Column
                field={'organisation'}
                title={'Organisation'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'approxPax'}
                title={'Pax'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'status'}
                title={'Status'}
                columnMenu={ColumnMenu}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'cal' && <EventsScheduler eventData={events} />}
        </ExcelExport>
      </div>
    </>
  );
};
export default EventsManagementGrid;
