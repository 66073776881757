import { Col, Row } from 'react-bootstrap';
import { EventFeedback } from '../types/event';
import { ColView } from './col-view';
import { useState } from 'react';
import QrCodeDialog from './dialogs/qr-code-dialog';
import { QRLink } from './QRLink';
import { formatDateToDDMMYYYY } from '../utils/date';

export interface FeedbackEventViewProps {
  feedback: EventFeedback;
  index: number;
}

export const FeedbackEventView = ({
  feedback,
  index
}: FeedbackEventViewProps) => {
  const [isOpenQR, setIsOpenQR] = useState<boolean>(false);

  return (
    <>
      <div className={'mt-4'}>Feedback {(index as number) + 1}</div>
      <div className={'card p-4'}>
        <Row className={'p-2'}>
          <ColView
            className={'d-flex flex-column'}
            colSpec={8}
            labelClassName={'text-muted'}
            label={'Date'}
            value={`${formatDateToDDMMYYYY(
              feedback?.startDateUtc!,
              true
            )} - ${formatDateToDDMMYYYY(feedback?.endDateUtc!, true)}`}
          />
        </Row>
        <Row className={'p-2'}>
          <ColView
            className={'d-flex flex-column fw-bold'}
            colSpec={6}
            labelClassName={'text-muted'}
            label={'Feedback'}
            value={feedback?.name}
          />
        </Row>

        <Row className={'p-2'}>
          <Col md={'12'} className={'d-flex flex-column'}>
            <small className={'text-muted'}>
              Feedback - Participant Link + QR Code
            </small>
            {feedback?.accessUrl && feedback?.accessUrl !== '' && (
              <QRLink
                title={'Feedback - Participant Link + QR Code'}
                url={`${feedback?.accessUrl}`}
              />
            )}
          </Col>
        </Row>

        <Row className={'p-2'}>
          <ColView
            className={'d-flex flex-column'}
            colSpec={12}
            labelClassName={'text-muted'}
            label={'Notes'}
            value={feedback?.notes}
          />
        </Row>
        {isOpenQR && (
          <QrCodeDialog
            title={'SCAN AND JOIN NOW'}
            url={feedback?.accessUrl!}
            onClose={() => setIsOpenQR(false)}
          />
        )}
      </div>
    </>
  );
};
