import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  AddZoneAsync,
  CopyZoneAsync,
  DeleteZoneAsync,
  UpdateGameDocState,
  UpdateZoneAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';

import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { ResourceEntity, ZoneEntity } from '../../../types/game-document/';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { ZoneEditorWindow } from '../../../features/game-document/zones';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import ViewEditDeleteCell from '../../../components/grid/view-edit-delete-cell';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnMenu } from '../../../components/columnMenu';
import { useSessionStorage } from 'usehooks-ts';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Zones = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-zones',
    initialDataState
  );
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<ZoneEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const navigate = useNavigate();
  const { gameId } = useParams();

  const titleGuid = uuid();

  const entity: ZoneEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid
  };

  const zoneResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: '',
    value: ''
  };

  useEffect(() => {
    // Set the page zone.
    document.title = `Zones - ${state.gameDocument?.name}`;
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const DeleteCell = (props: GridCellProps) => (
    <ViewEditDeleteCell
      onViewClick={() => onViewEntity(props.dataItem['id'])}
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}></ViewEditDeleteCell>
  );

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ZoneEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    if (editorEntity.isNew) {
      const addZoneResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        resourceEntity.find(
          (item) => item.entity.id === editorEntity.entity.titleResId!
        )?.entity.value!,
        titleGuid
      );
      Promise.all([addZoneResource]).then(() => {
        const {
          entity: { name, description }
        } = editorEntity;

        AddZoneAsync(state.gameDocument!, name, description, titleGuid).then(
          (updatedGameDocument) => {
            setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
            setEntityEditorIsVisible(false);
          }
        );
      });
    } else {
      const zoneResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.titleResId
      );

      const updateZoneResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.titleResId!,
        zoneResource?.entity!
      );

      Promise.all([updateZoneResource]).then(() => {
        UpdateZoneAsync(
          state.gameDocument!,
          editorEntity.entity.id,
          editorEntity.entity
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));

          setEntityEditorIsVisible(false);
        });
      });
    }
  };

  const onViewEntity = (entityId: string) => {
    navigate(
      `/designer/${gameId}/map/${entityId !== undefined ? entityId : ''}`
    );
  };

  const onEditEntity = (entityId: string) => {
    const editedEntity = state.gameDocument?.assets.zones?.find(
      (i) => i.id === entityId
    )!;

    setEntityEditorValue({
      isNew: false,
      entity: editedEntity
    });
    setResourceEditorValue([
      {
        isNew: true,
        entity: state.gameDocument?.resources.find(
          (item) => item.id === editedEntity.titleResId
        ) as ResourceEntity
      }
    ]);

    toggleEntityEditor();
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteZoneAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar
        title={'Zones'}
        buttonHelpSupport={{
          title: 'Zone assets guide',
          url: 'https://forum.catalystglobal.com/t/4635'
        }}>
        {entityEditorIsVisible && (
          <ZoneEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorResource={resourceEditorValue!}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search zones'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={state.gameDocument?.assets.zones ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} zones.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(state.gameDocument?.assets.zones ?? [], dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'name'}
              title={'Name'}
              cell={LinkedNameCell}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'description'}
              title={'Description'}
              columnMenu={ColumnMenu}
            />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Zones;
