import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useToggle } from 'usehooks-ts';
import { useAuth } from 'react-oidc-context';
import { LinkProps, NavLink } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Permission } from '../types/permission';
import { LayoutContext } from '../contexts/layout-provider';

interface MenuItemProps extends LinkProps {
  icon: string;
  label: string;
  end?: boolean;
  expanded?: boolean;
  iconSet?: string | 'outlined' | 'light';
  requiredRole?: string;
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );

  const key = Object.keys(Permission)[indexOfS];

  return key;
};

const MenuItem = ({
  icon,
  label,
  children,
  to,
  end,
  expanded = false,
  iconSet = 'outlined',
  requiredRole,
  ...props
}: MenuItemProps) => {
  const layout = useContext(LayoutContext);

  const auth = useAuth();
  const role = getPermissionKey(requiredRole!);
  const isInRole = !isEmpty(requiredRole)
    ? (auth.user?.profile.role as string[]).includes(role!)
    : true;
  const [isExpanded, setIsExpanded] = useToggle(expanded);
  const chevronIcon = isExpanded ? 'expand_more' : 'chevron_right';
  const chevronClass = isExpanded
    ? 'aside-menu__sub--expanded'
    : 'aside-menu__sub--collapsed';

  const navLinkClick = () => {
    layout.isMobile && layout.toggleNavIsCollapsed();
  };

  const Item = (
    <NavLink
      to={to}
      end={end ?? true}
      onClick={() => (children ? setIsExpanded() : navLinkClick())}
      {...props}
      className={`aside-menu__item ${
        children ? 'aside-menu__parent' : (props.className ?? '')
      }`.trim()}>
      <span className={'item__icon'}>
        <span
          className={`material-symbols-outlined material-symbols-outlined--${iconSet}`}>
          {icon}
        </span>
      </span>
      <span className={'item__label'}>{label}</span>
      {children && (
        <span className={'item__icon'}>
          <span
            className={'material-symbols-outlined'}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsExpanded();
            }}>
            {chevronIcon}
          </span>
        </span>
      )}
    </NavLink>
  );

  return (
    <>
      {isInRole && (
        <>
          {layout.navIsCollapsed ? (
            <OverlayTrigger
              placement={'right'}
              overlay={
                <Popover
                  className="border-1"
                  style={{
                    borderRadius: '0.25rem',
                    marginLeft: '0.25rem'
                  }}>
                  <Popover.Body className="px-2 py-1">{label}</Popover.Body>
                </Popover>
              }>
              {Item}
            </OverlayTrigger>
          ) : (
            Item
          )}

          {children && (
            <div className={`aside-menu__sub ${chevronClass}`}>{children}</div>
          )}
        </>
      )}
    </>
  );
};

export default MenuItem;
