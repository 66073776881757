import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import ProfileSide from './profile-side';
import {
  GetMyUserProfileAsync,
  PutMyUserProfileAsync
} from '../../services/users';
import { AddressResponse } from '../../types/responses/address-response';
import { StandardInput } from '../../components/forms';
import { Label } from '@progress/kendo-react-labels';
import { PhoneNumberResponse } from '../../types/responses/phone-number-response';
import {
  GetMyUserAddressesAsync,
  PostMyUserAddressAsync,
  UpdateMyUserAddressAsync
} from '../../services/user-address';
import {
  GetMyUserPhoneNumbersAsync,
  PostMyUserPhoneNumberAsync,
  UpdateMyUserPhoneNumberAsync
} from '../../services/user-phone-number';
import { toastStore } from '../../stores/toast-store';
import { ResetPassword } from '../../features/profile/reset-password';
import UserSettings, {
  groupedType,
  notificationGroupMap
} from './user-settings';
import UserSupport from './user-support';
import UserNotification from './notification';
import CameraUpload from './camera-upload';
import { FileExtension } from '../../types/fileExtensions';
import { PostMediaAsync } from '../../services/files';
import SaveSection from './save-section';
import { uuid } from '../../types/common-helper';
import { appStore } from '../../stores/app-store';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import CountryComboBox from '../../components/combobox/country-combobox';
import { UserResponse } from '../../types/responses/user-response';
import { AuthRequired } from '../../components/authentication';
import CountryCodeCombobox from '../../components/combobox/country-code-combobox';
import UserSupportTicketForm from './user-support-ticket-form';
import { NotificationName } from '../../types/responses/notification-type-response';
import {
  GetAppNotificationAsync,
  GetEmailNotificationAsync,
  GetNotificationTypesAsync,
  PutAppNotificationAsync,
  PutEmailNotificationAsync
} from '../../services/notification';
import { Autocomplete } from '../../components/auto-complete';
import { UserDepartmentResponse } from '../../types/responses/user-department';
import cloneDeep from 'lodash.clonedeep';
import {
  GetMyDepartmentsAsync,
  GetUserDepartmentsAsync,
  GetUserProfileDepartmentsAsync,
  PutMyDepartmentsAsync
} from '../../services/user-department';
import HelpSupport from '../../components/help-support';

export type GenericError<T> = { [x in keyof T]?: string };
export interface RequiredInput {
  id: string;
  name: string;
  errorMessage: string;
}

interface IUserProfile {
  user: UserResponse;
  userAddress: AddressResponse;
  userPhoneNumber: PhoneNumberResponse;
}

export interface IUserNotification {
  app: NotificationName[];
  email: NotificationName[];
}

const UserProfile = (props: any) => {
  const auth = useAuth();
  const userId = parseInt(auth.user?.profile.sub!);
  const [profilePhoto, setProfilePhoto] = useState<string>('');
  //set default value as Profile
  const [newProfilePicture, setNewProfilePicture] = useState<File>();
  const [selectedMenuItemName, setSelectedMenuItemName] = useState<string>(
    props.selectedMenuItemName
  );
  const [userProfile, setUserProfile] = useState<IUserProfile>();
  const [userNotification, setUserNotification] = useState<IUserNotification>({
    app: [],
    email: []
  });

  const departments = useRef<UserDepartmentResponse[]>([]);
  const [departmentData, setDepartmentData] = useState<
    UserDepartmentResponse[]
  >([]);
  const [userDepartments, setUserDepartments] = useState<
    UserDepartmentResponse[]
  >([]);

  // validation
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    {
      id: 'firstName',
      name: 'First Name',
      errorMessage: ''
    },
    {
      id: 'lastName',
      name: 'Last Name',
      errorMessage: ''
    },
    {
      id: 'userName',
      name: 'Username',
      errorMessage: ''
    }
  ]);

  const onSelectedImageCallback = (file: File | undefined) => {
    if (file) {
      if (file.type.includes('image')) {
        setNewProfilePicture(file);
      }
    }
  };

  const addRequestValidation = () => {
    const newState = requiredInputs.map((obj) => {
      if (
        userProfile?.user.hasOwnProperty(obj.id) &&
        userProfile.user[obj.id as keyof UserResponse] === ''
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      }
      return obj;
    });
    setRequiredInputs(newState);
    if (newState.filter((f) => f.errorMessage !== '').length > 0) {
      // if invalid stop all process
      return false;
    }
    return true;
  };

  const removeRequestValidation = (name: string) => {
    const updateRequestValidationState = requiredInputs.map((obj) => {
      if (obj.id === name) {
        return { ...obj, errorMessage: '' };
      }
      return obj;
    });
    setRequiredInputs(updateRequestValidationState);
  };

  const handleOnchangeNotificationToggle = (
    type: 'app' | 'email',
    notificationName: NotificationName,
    checked: boolean
  ) => {
    const temp = { ...userNotification };
    if (checked) {
      temp[type]?.push(notificationName);
    } else {
      const idx = temp[type].findIndex((notif) => notif === notificationName);
      temp[type].splice(idx, 1);
    }
    setUserNotification(temp);
  };

  const onChangeDepartmentEventHandler = (department: any) => {
    const exist = userDepartments.findIndex((x) => x.id === department.id);
    if (department && exist === -1) {
      let newUserDepartments = [...userDepartments];
      newUserDepartments.push({
        name: department.name ?? '',
        id: department.id ?? ''
      });
      setUserDepartments(newUserDepartments);
    }
  };

  const onDeleteDepartment = (department: UserDepartmentResponse) => {
    if (userDepartments) {
      const idx = userDepartments.indexOf(department);
      if (idx > -1 && userDepartments.length > 1) {
        let newData = cloneDeep(userDepartments);
        newData.splice(idx, 1);
        setUserDepartments(newData);
      } else if (userDepartments.length === 1) {
        setUserDepartments([]);
      }
    }
  };

  const getDepartments = async () => {
    // fetch new data
    GetUserDepartmentsAsync().then((res) => {
      departments.current = res;
      setDepartmentData(departments.current);
      fetchUserDepartments();
    });
  };

  const fetchUserDepartments = async () => {
    GetMyDepartmentsAsync().then((response) => {
      if (response) {
        if (departments.current) {
          const data = departments.current.filter((department) =>
            response.data.includes(department.id!)
          );
          setUserDepartments(data);
        } else {
          setUserDepartments([]);
        }
      }
    });
  };

  // update user
  const update = async () => {
    try {
      appStore.showLoading();

      if (newProfilePicture) {
        let newFile = new File(
          [newProfilePicture],
          `${uuid()}-${newProfilePicture?.name}`,
          {
            type: newProfilePicture.type
          }
        );

        const response = await PostMediaAsync(newFile);
        if (response) {
          userProfile!.user.avatarMediaId = response.id;
        }
      }

      await PutMyUserProfileAsync(userProfile?.user!);
      // address request
      if (userProfile?.userAddress !== undefined) {
        if (userProfile.userAddress.id! > 0) {
          // patch user address
          await UpdateMyUserAddressAsync(userProfile?.userAddress!);
        } else if (userProfile.userAddress.line1 !== '') {
          //post user address
          await PostMyUserAddressAsync(userProfile!.userAddress!);
        }
      }

      // Phone nummber request
      if (userProfile?.userPhoneNumber.id! > 0) {
        //patch phone number
        await UpdateMyUserPhoneNumberAsync(userProfile!.userPhoneNumber!);
      } else if (userProfile?.userPhoneNumber.number !== '') {
        //post phone number
        await PostMyUserPhoneNumberAsync(userProfile!.userPhoneNumber);
      }

      // Department update
      if (userDepartments) {
        const paramDepartments = userDepartments.map((i) => i.id!) ?? [];
        await PutMyDepartmentsAsync(paramDepartments);
      }

      await PutAppNotificationAsync(userNotification.app);
      await PutEmailNotificationAsync(userNotification.email);

      toastStore.show(
        'Users',
        <div>Details have been updated.</div>,
        'success'
      );
      // close popup window
      props.onToggleDialog();
    } catch (error) {
      toastStore.show('Users', <div>Details failed to update.</div>, 'error');
    } finally {
      appStore.hideLoading();
    }
  };

  const onDeletePictureHandler = () => {
    setUserProfile({
      ...userProfile!,
      user: { ...userProfile!.user!, avatarMediaId: null, avatarMediaUrl: '' }
    });
    setProfilePhoto('');
  };

  const save = async () => {
    if (addRequestValidation()) {
      update();
      return;
    }
  };

  const updateSelectedMenuItem = (name: string) => {
    setSelectedMenuItemName(name);
  };

  const saveSettings = () => {
    props.onToggleDialog();
  };
  const onChangeCountryEvent = (event: ComboBoxChangeEvent) => {
    setUserProfile({
      ...userProfile!,
      userAddress: {
        ...userProfile?.userAddress!,
        countryIso: event.value.iso! ?? '',
        country: event.value.country! ?? ''
      }
    });
  };

  const fetchUserDetail = useCallback(() => {
    let isSubscribed = true;

    const fetchUser = async () => {
      // load the user entity
      const userResponse = await GetMyUserProfileAsync();
      let userAddressResponse = {
        id: 0,
        type: 'Primary',
        line1: '',
        countryIso: '',
        country: ''
      } as AddressResponse;
      const getAllUserAddress = await GetMyUserAddressesAsync();
      if (getAllUserAddress?.data) {
        const mainAddress = getAllUserAddress?.data.find(
          (a) => a.type === 'Primary'
        );
        userAddressResponse = mainAddress!;
      }

      // call API Get User number
      let userPhoneResponse: PhoneNumberResponse = {
        id: 0,
        type: 'Work',
        number: '',
        countryIso: '',
        countryCode: ''
      };
      const getAllUserPhoneNumbers = await GetMyUserPhoneNumbersAsync();
      if (getAllUserPhoneNumbers?.data) {
        const userWorkPhoneNumber = getAllUserPhoneNumbers?.data.find((o) => {
          return o.type === 'Work';
        });

        if (userWorkPhoneNumber) {
          userPhoneResponse = {
            ...userWorkPhoneNumber,
            countryCode: `+${userWorkPhoneNumber.countryCode}`
          };
        }
      }
      getDepartments();

      if (isSubscribed) {
        // store the information into viewmodel
        setUserProfile({
          user: userResponse,
          userAddress: userAddressResponse!,
          userPhoneNumber: userPhoneResponse!
        });

        //set profile picture from userResponse
        setProfilePhoto(userResponse.avatarMediaUrl ?? '');
      }
    };
    fetchUser().catch(console.error);
    return () => {
      isSubscribed = false;
    };
  }, [userId]);

  const [isLoading, setIsloading] = useState<boolean>(false);
  const [notificationTypes, setNotificationTypes] = useState<groupedType[]>([]);

  const fetchNotificationTypes = async () => {
    try {
      setIsloading(true);
      const notificationType = await GetNotificationTypesAsync();
      const notificationApp = await GetAppNotificationAsync();
      const notificationEmail = await GetEmailNotificationAsync();

      const grouped: groupedType = {
        Event: [],
        Game: [],
        Assessment: [],
        Feedback: [],
        GlobalLibrary: []
      };

      notificationType.data.forEach((notif) => {
        const group = notif.name.split(
          '.'
        )[0] as keyof typeof notificationGroupMap;
        grouped[group].push({
          ...notif,
          checkedApp: Boolean(
            notificationApp.data.find((app) => app.name === notif.name)
          ),
          checkedEmail: Boolean(
            notificationEmail.data.find((email) => email.name === notif.name)
          )
        });
      });
      const appChecked = notificationApp.data.map((app) => app.name);
      const emailChecked = notificationEmail.data.map((email) => email.name);
      setUserNotification({
        app: appChecked,
        email: emailChecked
      });

      const result = Object.entries(grouped).map(([prop, value]) => {
        return { [prop]: value } as groupedType;
      });

      setNotificationTypes(result);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserDetail();
    fetchNotificationTypes();
  }, [userId, fetchUserDetail]);

  return (
    <AuthRequired>
      {userProfile ? (
        <>
          <div className={'d-flex w-100 h-100'}>
            <div
              className={
                'col-sm-4 col-md-4 col-lg-3 h-100 border-end position-relative bg-light'
              }
              style={{
                position: 'absolute',
                zIndex: '101'
              }}>
              <div className={'h-100 p-3'}>
                <ProfileSide
                  profileImg={userProfile.user.avatarMediaUrl!}
                  updateSelectedMenuItem={updateSelectedMenuItem}
                  onDeletePicture={onDeletePictureHandler}
                  selectedMenu={selectedMenuItemName}
                />
              </div>
            </div>
            <div
              className={
                'col h-100 px-3 d-flex flex-column overflow-auto position-relative'
              }
              style={{
                paddingTop: '40px'
              }}>
              {selectedMenuItemName === 'Profile' && (
                <>
                  <div className={'flex-grow-1 pt-2 d-flex flex-column gap-2'}>
                    <div
                      className={
                        'border-2 border-bottom d-flex align-items-center justify-content-between pb-2'
                      }>
                      <div
                        className={
                          'text-success d-flex align-items-center gap-2'
                        }>
                        <span className={'material-symbols-outlined'}>
                          account_circle
                        </span>
                        <h2 className={'m-0'}>Profile</h2>
                      </div>
                      <HelpSupport
                        title="Updating your profile and profile photo"
                        url="https://forum.catalystglobal.com/t/my-profile-profile/"
                      />
                    </div>
                    <div className={'row'}>
                      <div className={'col-4'}>
                        <StandardInput
                          label={'First Name'}
                          name={'First Name'}
                          value={userProfile.user.firstName}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              user: { ...userProfile.user, firstName: e.value }
                            });
                            if (e.value !== '') {
                              removeRequestValidation('firstName');
                            }
                          }}
                          validationMessage={
                            requiredInputs?.find(
                              (item) => item.name === 'First Name'
                            )?.errorMessage
                          }
                        />
                      </div>
                      <div className={'col-4'}>
                        <StandardInput
                          label={'Last Name'}
                          name={'Last Name'}
                          value={userProfile.user.lastName}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              user: { ...userProfile.user, lastName: e.value }
                            });
                            if (e.value !== '') {
                              removeRequestValidation('lastName');
                            }
                          }}
                          validationMessage={
                            requiredInputs?.find(
                              (item) => item.name === 'Last Name'
                            )?.errorMessage
                          }
                        />
                      </div>
                      <div className={'col-4'}>
                        <StandardInput
                          label={'Nickname'}
                          value={userProfile.user.preferredName}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              user: {
                                ...userProfile.user,
                                preferredName: e.value
                              }
                            });
                          }}
                          validationMessage={
                            requiredInputs?.find(
                              (item) => item.name === 'preferredName'
                            )?.errorMessage
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={'col-4'}>
                        <Label>Phone Number</Label>
                        <div className="row">
                          <div className="col-5 pr-0">
                            <CountryCodeCombobox
                              value={
                                userProfile.userPhoneNumber?.countryCode! ?? ''
                              }
                              columns={[
                                {
                                  field: 'code',
                                  header: 'Code',
                                  width: '100px'
                                },
                                {
                                  field: 'countryIso',
                                  header: 'Iso',
                                  width: '50px'
                                },
                                {
                                  field: 'country',
                                  header: 'Country'
                                }
                              ]}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  userPhoneNumber: {
                                    ...userProfile.userPhoneNumber,
                                    countryIso: e.value.countryIso,
                                    countryCode: e.value.code
                                  }
                                });
                              }}
                            />
                          </div>
                          <div className="col-7">
                            <StandardInput
                              value={userProfile.userPhoneNumber?.number! ?? ''}
                              onChange={(e) => {
                                setUserProfile({
                                  ...userProfile,
                                  userPhoneNumber: {
                                    ...userProfile.userPhoneNumber,
                                    number: e.value
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-4'}>
                        <Label>Country</Label>
                        <CountryComboBox
                          value={{
                            iso: userProfile!.userAddress
                              ? userProfile!.userAddress.countryIso!
                              : '',
                            country: userProfile!.userAddress!
                              ? userProfile!.userAddress!.country!
                              : ''
                          }}
                          onChange={(e) => onChangeCountryEvent(e)}
                        />
                      </div>
                      <div className={'col-8'}>
                        <Label>Address </Label>
                        <StandardInput
                          value={userProfile.userAddress?.line1! ?? ''}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              userAddress: {
                                ...userProfile.userAddress,
                                line1: e.value
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-8'}>
                        <Label>Username (email)</Label>
                        <StandardInput
                          name={'Username'}
                          readOnly={true}
                          title={"You can't edit email"}
                          value={userProfile.user.username ?? ''}
                        />
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className={'col-6'}>
                        <Label>Department</Label>
                        <Autocomplete<UserDepartmentResponse>
                          className={'d-flex justify-content-start w-100'}
                          items={departmentData ?? []}
                          textField="name"
                          onChange={
                            onChangeDepartmentEventHandler
                          }></Autocomplete>
                      </div>
                      <div className={'col-8 mt-2'}>
                        <ul className="list-group list-group-flush">
                          {userDepartments &&
                            userDepartments.map((department, index) => {
                              return (
                                <li
                                  key={index}
                                  className="list-group-item d-flex justify-content-between">
                                  <span className={'text-primary'}>
                                    {department?.name ?? ''}
                                  </span>
                                  <span
                                    className={
                                      'material-symbols-outlined text-danger'
                                    }
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onDeleteDepartment(department);
                                    }}>
                                    close
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <ResetPassword title={'Password reset'} userId={userId} />
                  </div>
                </>
              )}
              {(() => {
                switch (selectedMenuItemName) {
                  case 'Settings':
                    return (
                      <UserSettings
                        isLoading={isLoading}
                        notificationTypes={notificationTypes}
                        handleChange={handleOnchangeNotificationToggle}
                      />
                    );
                  case 'Support':
                    return (
                      <UserSupport
                        setSelectedMenuItemName={setSelectedMenuItemName}
                      />
                    );
                  case 'SupportTicket':
                    return (
                      <UserSupportTicketForm
                        setSelectedMenuItemName={setSelectedMenuItemName}
                      />
                    );
                  case 'Notifications':
                    return <UserNotification />;
                  case 'UploadPhoto':
                    return (
                      <CameraUpload
                        allowedExtension={[
                          FileExtension.JPG,
                          FileExtension.PNG
                        ]}
                        onSelectedImageCallback={onSelectedImageCallback}
                        profileImg={profilePhoto}
                      />
                    );
                }
              })()}

              {!['Notifications', 'Support', 'SupportTicket'].includes(
                selectedMenuItemName
              ) && (
                <SaveSection
                  createdDate={userProfile?.user?.createdDateUtc!}
                  onClose={props.onToggleDialog}
                  onSave={
                    ['Profile', 'UploadPhoto', 'Settings'].includes(
                      selectedMenuItemName
                    )
                      ? save
                      : saveSettings
                  }
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          className={
            'd-flex flex-column align-items-center justify-content-center w-100 gap-2'
          }>
          <span className={'loader'}></span>
          <label className={'text-gray'}>Loading...</label>
        </div>
      )}
    </AuthRequired>
  );
};

export default UserProfile;
