import React, { ComponentType } from 'react';

interface ToolbarProps {
  children?: React.ReactNode;
  title?: string;
}

const PageToolbar: ComponentType<ToolbarProps> = (props: ToolbarProps) => {
  return (
    <div
      className={
        'page-toolbar d-flex justify-content-between align-items-center'
      }>
      {props.title && <h2 className={'flex-shrink-0'}>{props.title}</h2>}
      <div
        className={`d-flex align-items-center gap-2 w-100 ${props.title ? 'justify-content-end' : ''} `}>
        {props.children}
      </div>
    </div>
  );
};

export default PageToolbar;
