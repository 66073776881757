import { GameDocumentContext } from '../../../contexts/game-document';
import { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import { GetResourceValue } from '../../../utils/game-document';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { ColorPicker } from '../../../components/color-picker';
import { SingleUpload } from '../../../components/single-upload';
import { Theme as ThemeModel, ThemeColor } from '../../../types/theme';
import {
  GetThemeAsync,
  PostThemeAsync,
  PutThemeAsync
} from '../../../services/games';
import { toastStore } from '../../../stores/toast-store';
import { appStore } from '../../../stores/app-store';

import { FontCombobox } from '../../../components/combobox/font-combobox';
import { formatDate } from '@progress/kendo-intl';
import { uuid12 } from '../../../types/common-helper';
import { PostResourceAsync } from '../../../services/files';
import cloneDeep from 'lodash.clonedeep';

const Theme = () => {
  const [state] = useContext(GameDocumentContext);
  const [isDataExist, setIsDataExist] = useState<boolean>(false);
  const [theme, setTheme] = useState<ThemeModel>({});

  const defaultColors: ThemeColor[] = [
    {
      defaultIndex: 0,
      type: 'Primary',
      color: '#4698CB'
    },
    {
      defaultIndex: 1,
      type: 'Secondary',
      color: '#A8B6BE'
    },
    {
      defaultIndex: 2,
      type: 'Background & Text',
      color: '#003A64'
    },
    {
      defaultIndex: 3,
      type: 'Info',
      color: '#0DC5E8'
    },
    {
      defaultIndex: 4,
      type: 'Success',
      color: '#14BF8B'
    },
    {
      defaultIndex: 5,
      type: 'Warning',
      color: '#FEB333'
    }
  ];

  const defaultValue = () => {
    let newTheme: ThemeModel = { ...theme };
    newTheme.fontFamily = '';
    newTheme.logoUrl = '';
    newTheme.footerUrl = '';
    newTheme.colors = defaultColors;

    setTheme(newTheme);
  };

  const onChangeThemeColor = (index: number, color: string) => {
    let newTheme: ThemeModel = { ...theme };
    newTheme.colors![index].color = color;
    setTheme(newTheme);
  };

  const showSavingNotification = (isSuccess: boolean) => {
    if (!isSuccess) {
      toastStore.show('', <div>Theme failed to save.</div>, 'error');
    }

    if (isSuccess) {
      toastStore.show('', <div>Theme saved.</div>, 'success');
    }
  };

  const uploadImage = async (imageBlob: string) => {
    try {
      const gameId = state.gameId;
      const gameVer = state.gameDocument?.version;
      const dateString = formatDate(new Date(), 'yyMMdd');
      const fileNamePrefix = `${gameId}-${dateString}-${gameVer}`;

      const blob = await fetch(imageBlob).then((r) => r.blob());
      const fileName = `${fileNamePrefix}-${uuid12()}`;
      const extractedBlob: File = new File([blob], fileName, {
        type: blob.type
      });

      const newResourceResponse = await PostResourceAsync(extractedBlob);
      return newResourceResponse.value;
    } catch (error) {
      console.log(error, '>>>>error');
    }
  };

  const onSaveTheme = async () => {
    try {
      appStore.showLoading();

      const newTheme: ThemeModel = cloneDeep(theme);
      if (theme.logoUrl) {
        const logo = await uploadImage(theme.logoUrl);
        if (logo) {
          newTheme.logoUrl = logo;
        }
      }

      if (theme.footerUrl) {
        const footer = await uploadImage(theme?.footerUrl ?? '');
        if (footer) {
          newTheme.footerUrl = footer;
        }
      }

      if (isDataExist) {
        await PutThemeAsync(state?.gameId!, newTheme);
      } else {
        await PostThemeAsync(state?.gameId!, newTheme);
      }
      setIsDataExist(true);
      showSavingNotification(true);
    } catch (error) {
      showSavingNotification(false);
    } finally {
      appStore.hideLoading();
    }
  };

  const addMissingColor = (colors: ThemeColor[]) => {
    const newColors: ThemeColor[] = defaultColors ?? [];

    newColors.forEach((newColor: ThemeColor) => {
      if (!colors?.find((x: ThemeColor) => x.type === newColor.type)) {
        colors.splice(newColor.defaultIndex, 0, newColor);
      }
    });

    return colors;
  };

  const populateTheme = async () => {
    try {
      let theme: ThemeModel = await GetThemeAsync(state?.gameId!);
      theme.colors = addMissingColor(theme.colors!);
      setTheme(theme);
      setIsDataExist(true);
    } catch (error) {
      defaultValue();
    }
  };

  useEffect(() => {
    let newTheme: ThemeModel = { ...theme };
    setTheme(newTheme);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set the page title.
    document.title = `Theme - ${GetResourceValue(
      state?.gameDocument!,
      state.gameDocument?.overview?.titleResId!
    )}`;
  }, [state]);

  useEffect(() => {
    populateTheme();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Toolbar
        title={'Theme'}
        showDefaultContent={false}
        buttonHelpSupport={{
          title: 'Theme guide',
          url: 'https://forum.catalystglobal.com/t/4613'
        }}>
        <Button themeColor={'primary'} className={'me-1'} onClick={onSaveTheme}>
          Save
        </Button>
      </Toolbar>
      <hr />
      <Row>
        <Col className={'d-flex flex-column'}>
          <Label>Typeface</Label>
          <span>
            Please type a font to be used to display text within the game
          </span>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col
          className={'d-flex flex-column gap-1'}
          xs={'12'}
          sm={'12'}
          md={'12'}
          lg={'3'}
          xl={'3'}>
          <Label>Font</Label>
          <FontCombobox
            value={theme?.fontFamily ? theme?.fontFamily : null}
            onChange={(e) => {
              setTheme({
                ...theme,
                fontFamily: e.target.value?.toString() ?? ''
              });
            }}
          />
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <Label>Colours</Label>
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col className={'d-flex flex-wrap'}>
          {theme?.colors?.map((color: ThemeColor, index: number) => (
            <div className={'mr-3 mb-3'} key={index}>
              <ColorPicker
                key={index}
                view={'gradient'}
                description={color?.type}
                value={color?.color}
                onChange={(val) => {
                  onChangeThemeColor(index, val!);
                }}
              />
            </div>
          ))}
        </Col>
      </Row>
      <Row className={'mt-3 mb-3'}>
        <Col className={'d-flex flex-column'}>
          <Label>Logo</Label>
          <span>Customise the logo displayed in the opening screens</span>
        </Col>
      </Row>
      <SingleUpload
        uploadTitle={'Upload logo'}
        allowedExtension={'image/*'}
        url={theme?.logoUrl}
        width={200}
        onUpload={(e) => {
          setTheme({
            ...theme,
            logoUrl: e.blobUrl
          });
        }}
        source="Logo"
      />
      <Row className={'mt-3 mb-3'}>
        <Col className={'d-flex flex-column'}>
          <Label>Footer</Label>
          <span>Add a transparent .png as a footer</span>
        </Col>
      </Row>
      <SingleUpload
        uploadTitle={'Upload footer'}
        allowedExtension={'image/png'}
        url={theme?.footerUrl}
        onUpload={(e) => {
          setTheme({
            ...theme,
            footerUrl: e.blobUrl
          });
        }}
        source="Footer"
      />
    </>
  );
};

export default Theme;
