import { PrivatePage } from '../../components/private-page';
import { useEffect, useRef, useState } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import {
  GetOrganisationAsync,
  GetTerritoriesOrganisationAsync
} from '../../services/organisations';
import { Col, Row } from 'react-bootstrap';
import { GetOrganisationEmailAsync } from '../../services/organisation-email';
import { OrganisationResponse } from '../../types/responses/organisation-response';
import { EmailAddressResponse } from '../../types/responses/email-address-response';
import {
  AddressResponse,
  TerritoriesResponse
} from '../../types/responses/address-response';
import { GetOrganisationAddressesAsync } from '../../services/organisation-address';
import { useAuth } from 'react-oidc-context';
import { GetCountriesAsync } from '../../services/country';
import { CountryResponse } from '../../types/responses/country-response';
import HelpSupport from '../../components/help-support';

export default function MyOrganisation() {
  const [organisation, setOrganisation] = useState<OrganisationResponse>({
    name: '',
    languages: '',
    country: '',
    status: '',
    taxId: '',
    tradeId: '',
    type: '',
    organisationLogoMediaId: null,
    avatarMediaId: null
  });

  const [id, setId] = useState('');
  const auth = useAuth();
  const countries = useRef<CountryResponse[]>([]);

  const [organisationAddress, setOrganisationAddress] =
    useState<AddressResponse>({
      line1: '',
      countryIso: ''
    });
  const [organisationEmail, setOrganisationEmail] =
    useState<EmailAddressResponse>({
      address: ''
    });
  const [organisationTerritories, setOrganisationTerritories] = useState<
    TerritoriesResponse[]
  >([]);
  const [countryData, setCountryData] = useState<CountryResponse[]>([]);

  const [logoSrc, setLogoSrc] = useState<
    string | ArrayBuffer | null | undefined
  >();

  const fetchOrganisation = async (orgId: number) => {
    GetOrganisationAsync(orgId)
      .then(async (response) => {
        if (response.organisationLogoMediaId !== null) {
          // set logo here
          setLogoSrc(response.logoUrl!);
        }
        setOrganisation(response);
      })
      .catch((error) => console.error('messages:', error));

    await GetOrganisationAddressesAsync(orgId)
      .then((response) => {
        const address = response.data.find((a) => a.type === 'Primary');

        if (address) {
          setOrganisationAddress(address);
        }
      })
      .catch((error) => console.error('messages:', error));

    await GetOrganisationEmailAsync(orgId)
      .then((response) => {
        const email = response.data.find((e) => e.type === 'Primary');

        if (email) {
          setOrganisationEmail(email);
        }
      })
      .catch((error) => console.error('messages:', error));
  };

  const fetchTerritories = async (
    orgId: number,
    countries?: CountryResponse[]
  ) => {
    const territories = await GetTerritoriesOrganisationAsync(orgId);
    if (territories) {
      if (countries) {
        const data = countries.filter((country) =>
          territories.includes(country.iso!)
        );
        setOrganisationTerritories(data);
      } else {
        setOrganisationTerritories([]);
      }
    }
  };

  const getCountries = async (orgId: number) => {
    // fetch new data
    const countryList = await GetCountriesAsync();
    if (countryList) {
      // sort alphabetically and store in ref with an intermediate collection for filtering
      countries.current = countryList.data.sort((a, b) =>
        a.country!.localeCompare(b.country!)
      );
      setCountryData(countries.current);
      fetchTerritories(orgId, countries.current);
    }
  };

  const getOrganisationId = () => {
    if (auth.user?.profile.catalyst_org_id) {
      setId(auth.user?.profile.catalyst_org_id as string);
    }
    return auth.user?.profile.catalyst_org_id;
  };

  useEffect(
    () => {
      getOrganisationId();

      if (id) {
        fetchOrganisation(parseInt(id));
        getCountries(parseInt(id));
      }
    },
    // eslint-disable-next-line
    [id]
  );

  useEffect(() => {
    document.title = ctGameTitle + ' - My organisation';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <>
      <PrivatePage
        breadcrumb={[
          { label: 'Dashboard', href: 'dashboard' },
          { label: 'My organisation', href: 'myorganisation' }
        ]}
        pageTitle={'My organisation'}>
        <div className="w-100 d-flex justify-content-end">
          <HelpSupport
            title="My organisation guide"
            url="https://forum.catalystglobal.com/t/4137"
          />
        </div>
        <Row>
          <Col xs="2" className={'p-5'}>
            <div
              className={
                'position-relative h-9 w-9 d-flex align-items-center justify-content-center organisation-logo'
              }>
              {logoSrc && (
                <div
                  className={'round-card rounded-full h-9 w-9'}
                  style={{
                    backgroundImage: `url('${logoSrc}')`
                  }}></div>
              )}
              {!logoSrc && (
                <div
                  className={
                    'rounded-circle bg-secondary d-flex justify-content-center align-items-center'
                  }
                  style={{ width: 150, height: 150 }}></div>
              )}
            </div>
          </Col>
          <Col xs="8">
            <Row>
              <span className={'mt-4'}>Information</span>
            </Row>
            <div className={'card p-5'}>
              <Row>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Organisation name</small>
                  <span className={'fw-bold'}>{organisation?.name}</span>
                </Col>
                <Col md={'8'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Address</small>
                  <span className={'fw-bold'}>{organisationAddress.line1}</span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Country</small>
                  <span className={'fw-bold'}>{organisation?.country}</span>
                </Col>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Language</small>
                  <span className={'fw-bold'}>{organisation.languages}</span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={'12'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Primary email address</small>
                  <span className={'fw-bold'}>
                    {organisationEmail?.address}
                  </span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={'12'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Website</small>
                  <span className={'fw-bold'}>{organisation?.website}</span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={'12'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Territory</small>
                  <span className={'fw-bold'}>
                    <Col md="12">
                      <ul className="list-group-flush">
                        {organisationTerritories &&
                          organisationTerritories
                            .filter((x) => !x.isDeleted)
                            .map((country, index) => {
                              return (
                                <li
                                  key={index}
                                  className="justify-content-between">
                                  <span>{country?.country ?? ''}</span>
                                </li>
                              );
                            })}
                      </ul>
                    </Col>
                  </span>
                </Col>
              </Row>
            </div>
            <Row>
              <span className={'mt-4'}>Financial details</span>
            </Row>
            <div className={'card p-5'}>
              <Row>
                <Col md="4" className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Tax Identification</small>
                  <span className={'fw-bold'}>{organisation.taxId}</span>
                </Col>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Trade Identification</small>
                  <span className={'fw-bold'}>{organisation.tradeId}</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </PrivatePage>
    </>
  );
}
