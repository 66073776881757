import { useEffect } from 'react';
import { GameDocumentProvider } from '../../contexts/game-document';
import { Route, Routes, useParams } from 'react-router-dom';
import GameOverview from './game-overview';
import GameMap from './game-map';
import {
  Inventory,
  Item,
  Map,
  Maps,
  Task,
  TaskContent,
  TaskContents,
  Tasks,
  Timer,
  Timers,
  Title,
  Titles,
  Zone,
  Zones
} from './assets';
import GameAnnouncements from './game-announcements';
import { Assessments, Assessment } from './assets/';
import Theme from './resources/theme';
import Language from './resources/language';
import GameMapZone from './game-map-zone';
import Page from './page';
import { GameDesigner as GameDesignerLayout } from '../../layouts';
import GameSettings from './game-settings';
import GameDiagnostics from './game-diagnostics';
import { Area, Areas } from './assets/';
import Resource from './resources/resource';
import { resetDatastateSession } from '../../utils/game-document/session-storage';
import GameSalesMarketing from './game-sales-marketing';

const GameDesigner = () => {
  let { gameId } = useParams();

  useEffect(() => {
    return () => {
      //remove session storage datastate grid assets in game designer
      resetDatastateSession();
    };
  }, []);

  // prettier-ignore
  return (
    <GameDocumentProvider gameId={gameId}>
      <Routes>
        <Route element={<GameDesignerLayout />}>
          <Route element={<Page className={'page-base--full'} />}>
            <Route path={'map'} element={<GameMap />} />      
            <Route path={'map/:zoneId'} element={<GameMapZone />} />      
            <Route path={'map/task/:taskId'} element={<GameMap />} />           
          </Route>
          <Route element={<Page className={'page-base--dynamic'} />}>
            <Route index element={<GameOverview />} />
            <Route path={'assets'}>
              <Route path={'assessments'} element={<Assessments />} />
              <Route path={'assessments/:assessmentId'} element={<Assessment />} />
              <Route path={'inventory'} element={<Inventory />} />
              <Route path={'inventory/:itemId'} element={<Item />} />
              <Route path={'tasks'} element={<Tasks />} />
              <Route path={'tasks/:taskId'} element={<Task />} />
              <Route path={'task-content'} element={<TaskContents />} />
              <Route path={'task-content/:taskContentId'} element={<TaskContent />} />
              <Route path={'task-content/:taskContentId/:taskId'} element={<TaskContent />} />
              <Route path={'timers'} element={<Timers />} />
              <Route path={'timers/:timerId'} element={<Timer />} />
              <Route path={'titles'} element={<Titles />} />
              <Route path={'titles/:titleId'} element={<Title />} />
              <Route path={'zones'} element={<Zones />} />
              <Route path={'zones/:zoneId'} element={<Zone />} />
              <Route path={'maps'} element={<Maps />} />
              <Route path={'maps/:mapId'} element={<Map />} />
              <Route path={'areas'} element={<Areas />} />
              <Route path={'areas/:areaId'} element={<Area />} />
            </Route>
            <Route path={'resources'}>
              <Route path={'language'} element={<Language />} />
              <Route path={'theme'} element={<Theme />} />
              <Route path={'resource'} element={<Resource />} />
            </Route>
            <Route path={'announcements'} element={<GameAnnouncements />} />
            <Route path={'sales'} element={<GameSalesMarketing />} />
            <Route path={'settings'} element={<GameSettings />} />
            <Route path={'diagnostics'} element={<GameDiagnostics />} />
            {/* regarding task 5957 Hide menu assessment on designer */}
            {/* <Route path={'assessment'} element={<GameAssessments/>}/> */}
          </Route>
        </Route>
      </Routes>
    </GameDocumentProvider>
  );
};

export default GameDesigner;
