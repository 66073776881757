import { Button } from '@progress/kendo-react-buttons';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { FeedbackContentFullEditor } from '../../features/feedback/feedback-content-full-editor';
import {
  AddResourcePackAsync,
  UpdateFeedbackResourcePackDetail
} from '../../features/feedback/feedback-document';
import { ResourcePackEditorWindow } from '../../features/game-document/resource-packs/resource-pack-editor-window';
import { GetLanguagesAsync } from '../../services/country';
import {
  addFeedbackAsync,
  getDocumentLatestAsync,
  getDocumentsByFeedbackIdAsync,
  getFeedbackById,
  publishFeedbackAsync,
  updateDocumentsByFeedbackIdAsync
} from '../../services/feedback';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { Feedback, FeedbackApproval } from '../../types/feedback';
import { ResourcePackEntity } from '../../types/game-document/entities';
import { EntityEditor } from '../../types/game-document/entity-editor';
import { GetUpdatedVersionAsync } from '../../utils/game-document';
import { Dialog } from '@progress/kendo-react-dialogs';
import SubmitConfirmation from '../../components/submit-to-global-library';
import HelpSupport from '../../components/help-support';
import {
  DisplayLanguagePublished,
  GetDisplayLanguagePublished
} from '../../services/admin-display-language';

const defaultEntity: Feedback = {
  id: 0,
  name: '',
  title: '',
  logoUrl: '',
  organisationId: 1,
  language: '',
  htmlContent: '',
  htmlOutroContent: `<b>Thank you for your time.</b><br><br>We value your feedback. The survey is complete. You can now close this page.`,
  description: '',
  version: 'v0.0.1',
  status: 'New'
};

const resourcePackEntity: ResourcePackEntity = {
  id: '',
  name: '',
  description: '',
  resources: []
};

const FeedbackDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Feedback>();
  const [languages, setLanguages] = useState<string[]>([]);
  const [displayLanguages, setDisplayLanguages] = useState<
    DisplayLanguagePublished[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<ResourcePackEntity>>();
  const [currentLocale, setCurrentLocale] = useState<string>();
  const [latestStatus, setLatestStatus] = useState<string>('');
  const [feedbackLanguages, setFeedbackLanguages] = useState<string[]>([]);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const newFeedbackBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Feedback', href: '../mylibrary/feedback' }
  ];

  const detailFeedbackBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Feedback', href: '../mylibrary/feedback' },
    {
      label: 'Feedback Details',
      href: `../feedback-details/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine feedback and feedback documents (resources)
      let feed1 = await getFeedbackById(parseInt(id!));
      let feed2 = await getDocumentsByFeedbackIdAsync(parseInt(id!));

      let feedback = merge(feed1, feed2);

      setEntity(feedback);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newnEntity: Feedback) => {
    setEntity(newnEntity);
  };

  const handleFeedbackLanguages = (assessmentLanguages: string[]) => {
    if (entity?.language && entity?.language?.toString()) {
      let index = assessmentLanguages.indexOf(entity?.language?.toString()!);

      if (index === -1) {
        setFeedbackLanguages(
          assessmentLanguages.concat(entity?.language?.toString()!)
        );
      } else {
        setFeedbackLanguages(assessmentLanguages);
      }
    } else {
      setFeedbackLanguages(assessmentLanguages);
    }
  };

  const onSubmitHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        // update feedback (only docs returned) and combine it with feedback object
        let feed1 = await updateDocumentsByFeedbackIdAsync(
          parseInt(id!),
          entity!
        );
        let feed2 = await getFeedbackById(parseInt(id!));

        let feedback = merge(feed1, feed2);
        setEntity(feedback);
      } else {
        await addFeedbackAsync(entity!);
        navigate(`/mylibrary/feedback`);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  /**
   * Publish feedback for approval
   */
  const onPublishHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        let newVersion = await GetUpdatedVersionAsync('minor', entity!.version);
        entity!.version = newVersion ?? '';
        const response = await updateDocumentsByFeedbackIdAsync(
          parseInt(id!),
          entity!
        );
        setEntity(response);

        let document = await getDocumentLatestAsync(parseInt(id!));

        let request: FeedbackApproval = {
          fileName: document?.fileName ?? '',
          version: response?.version ?? '',
          status: 'Published',
          isDeleted: false
        };

        let result = await publishFeedbackAsync(
          parseInt(id!),
          document.id,
          request
        );

        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            setEntity(newEntity!);
            setLatestStatus(result?.status ?? '');
          }
        }

        toastStore.show(
          'Feedback Detail',
          <div>Feedback published.</div>,
          'success'
        );
      }
    } catch (ex) {
      toastStore.show(
        'Feedback Detail',
        <div>Failed to publish feedback.</div>,
        'error'
      );
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  /**
   * Submit feedback for approval
   */
  const onSubmitToGlobalHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        let newVersion = await GetUpdatedVersionAsync('minor', entity!.version);
        entity!.version = newVersion ?? '';
        const response = await updateDocumentsByFeedbackIdAsync(
          parseInt(id!),
          entity!
        );
        setEntity(response);

        let document = await getDocumentLatestAsync(parseInt(id!));

        let request: FeedbackApproval = {
          fileName: document?.fileName ?? '',
          version: response?.version ?? '',
          status: 'Pending',
          isDeleted: false
        };

        let result = await publishFeedbackAsync(
          parseInt(id!),
          document.id,
          request
        );

        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            setEntity(newEntity!);
            setLatestStatus(result?.status ?? '');
          }
        }

        toastStore.show(
          'Feedback Detail',
          <div>Feedback submitted and waiting approval.</div>,
          'success'
        );
      }
    } catch (ex) {
      toastStore.show(
        'Feedback Detail',
        <div>Failed to publish feedback.</div>,
        'error'
      );
      console.error(ex);
    } finally {
      appStore.hideLoading();
      toggleDialog();
    }
  };

  const getLatestDocumentAsync = async () => {
    let document = await getDocumentLatestAsync(parseInt(id!));
    setLatestStatus(document?.status ?? '');
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    const languages: string[] = [];
    response.data.forEach((f) => {
      if (!languages.some((c) => c === f.language)) {
        languages.push(f.language!);
      }
    });
    setLanguages(languages);
  };

  const getDisplayLanguages = async () => {
    const response = await GetDisplayLanguagePublished();
    setDisplayLanguages(response.data);
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: resourcePackEntity
    });

    toggleEntityEditor();
  };

  const onEditEntity = () => {
    const resourcePack = entity?.resourcePacks!;
    const resourcePackIndex = entity?.resourcePacks?.findIndex(
      (i) => i.name === currentLocale
    );

    if (resourcePackIndex !== undefined && resourcePackIndex > -1) {
      let currentEntity = resourcePack[resourcePackIndex!];

      setEntityEditorValue({
        isNew: false,
        entity: {
          id: currentEntity.id,
          description: currentEntity.description,
          resources: currentEntity.resources,
          name: currentEntity.name,
          displayLanguage: currentEntity.displayLanguage,
          displayLanguageUrl: currentEntity.displayLanguageUrl
        }
      });
    }
    toggleEntityEditor();
  };

  const handleEntityEditorSubmit = async (
    editorEntity: EntityEditor<ResourcePackEntity>,
    isCopyOriginal?: boolean,
    displayLanguage?: string
  ) => {
    const selectedDisplayLanguage = displayLanguages.find(
      (i) => i.languageName === displayLanguage
    );
    if (editorEntity.isNew) {
      await AddResourcePackAsync(
        entity!,
        editorEntity?.entity?.name,
        isCopyOriginal,
        selectedDisplayLanguage?.languageName,
        selectedDisplayLanguage?.blobFileUrl
      ).then((response) => {
        setEntity((prev) => ({ prev, ...response }));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    } else {
      /* Updates the resource pack's detail in the Assessment Document.
       * Not Update resource.
       */
      UpdateFeedbackResourcePackDetail(
        entity!,
        editorEntity.entity.name,
        editorEntity.entity.id,
        selectedDisplayLanguage!.languageName!,
        selectedDisplayLanguage!.blobFileUrl!
      ).then((response) => {
        setEntity((prev) => ({ prev, ...response }));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    }
    toggleEntityEditor();
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const disableSave = (): boolean => {
    if (latestStatus === 'Pending') {
      return true;
    } else if (
      entity &&
      (!entity.name || !entity.language || !entity.displayLanguage)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (id) {
      getDetails();
      getLatestDocumentAsync();
    } else {
      setDefaultEntity();
    }
    getLanguages();
    getDisplayLanguages();
  }, []);

  return (
    <PrivatePage
      breadcrumb={id ? detailFeedbackBreadcrumb : newFeedbackBreadcrumb}
      pageTitle={'Feedback'}>
      <>
        <Toolbar title={id ? (entity?.name ?? '') : 'New Feedback'}>
          {id === undefined && (
            <HelpSupport
              title="Creating a feedback survey"
              url="https://forum.catalystglobal.com/t/4163"
            />
          )}
          <Button
            disabled={disableSave()}
            themeColor={'secondary'}
            onClick={onSubmitHandler}>
            Save
          </Button>
          {entity?.status === 'Published' && (
            <Button onClick={toggleDialog} themeColor={'primary'}>
              Submit to Global Library
            </Button>
          )}
          {id && (
            <Button
              disabled={disableSave() ?? undefined}
              themeColor={'warning'}
              onClick={onPublishHandler}>
              Publish
            </Button>
          )}

          {/* {selectedTab === 'language' && (
            <Button
              className={'ml-2'}
              themeColor={'primary'}
              onClick={onAddEntity}>
              Add Language
            </Button>
          )} */}
          {entityEditorIsVisible && (
            <ResourcePackEditorWindow
              toggleDialog={toggleEntityEditor}
              onClose={toggleEntityEditor}
              editorEntity={entityEditorValue!}
              editorMode={'basic'}
              onSubmit={handleEntityEditorSubmit}
              isLanguageCombobox={true}
              listedLanguages={feedbackLanguages}></ResourcePackEditorWindow>
          )}
        </Toolbar>
        <hr />
        <Container>
          {entity && (
            <FeedbackContentFullEditor
              languages={languages}
              displayLanguages={displayLanguages}
              entity={entity}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              onAddEntity={onAddEntity}
              onEditEntity={onEditEntity}
              handleEntityChange={handleEntityChange}
              handleFeedbackLanguages={handleFeedbackLanguages}
              toggleLanguageTab={toggleLanguageTab}
            />
          )}
        </Container>

        {showDialog && (
          <Dialog
            width={'50vw'}
            title={
              <span className="fw-bold text-primary">
                Submit to global library
              </span>
            }
            onClose={toggleDialog}>
            <SubmitConfirmation
              toggleDialog={toggleDialog}
              onSubmit={onSubmitToGlobalHandler}
              isPendingApproval={entity?.status === 'Pending'}
            />
          </Dialog>
        )}
      </>
    </PrivatePage>
  );
};

export default FeedbackDetail;
