import { process, State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridToolbar
} from '@progress/kendo-react-grid';
import {
  cloneElement,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { Col, Row } from 'react-bootstrap';
import { useSessionStorage } from 'usehooks-ts';
import YesNoDialog from '../../../components/dialogs/yes-no-dialog';
import {
  CustomColumnImage,
  CustomColumnPlainText,
  CustomColumnTextarea,
  CustomColumnVideo
} from '../../../components/grid/custom-column';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { GameDocumentContext } from '../../../contexts/game-document';
import {
  ResourceWindow,
  UploadedImage
} from '../../../features/game-document/image-resource/resource-window';
import { ResourcePackEditorWindow } from '../../../features/game-document/resource-packs/resource-pack-editor-window';
import { ResourcePackHtmlEditorWindow } from '../../../features/game-document/resource-packs/resource-pack-html-editor-window';
import { YesNoDialogResult } from '../../../types/dialog-result';
import { GameDocument, Overview } from '../../../types/game-document';
import { ResourcePackEntity } from '../../../types/game-document/entities';
import { LanguageMapping } from '../../../types/game-document/entities/resource-pack';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import {
  GetResourceEntity,
  GetResourceValue,
  UpdateGameDocState,
  UpdateResourceAsync
} from '../../../utils/game-document';
import {
  AddResourcePackAsync,
  AddResourcePackResourceAsync,
  GetResourcePackByName,
  GetResourcePackResource,
  GetResourcePacks,
  UpdateResourcePackDetailAsync,
  UpdateResourcePackResourceAsync
} from '../../../utils/game-document/resource-packs';
import Toolbar from '../toolbar';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';
import {
  DisplayLanguagePublished,
  GetDisplayLanguagePublished
} from '../../../services/admin-display-language';

type selectedResource = {
  isOriginal?: boolean;
  resourceId?: string;
  type?: string;
  value?: string;
};

const Language = () => {
  const initialDataState: State = {
    ...DefaultGridSettings.initialDataState
  };
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-game-languages',
    initialDataState
  );
  const resources = useGameDocumentResources();
  const [languages, setLanguages] = useState<LanguageMapping[]>([]);
  const [displayLanguages, setDisplayLanguages] = useState<
    DisplayLanguagePublished[]
  >([]);
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<ResourcePackEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };
  const [languageList, setLanguageList] = useState<string[]>();
  const [currentLocale, setCurrentLocale] = useState<string>();
  const [uploadMediaIsVisible, setUploadMediaIsVisible] =
    useState<boolean>(false);
  const [htmlEditorVisible, setHtmlEditorVisible] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<selectedResource>();
  const [showDeleteLanguageConfirm, setShowDeleteLanguageConfirm] =
    useState<boolean>(false);
  const [listedLanguages, setListedLanguages] = useState<string[]>([]);
  const [listedDisplayLanguages, setListedDisplayLanguages] = useState<
    string[]
  >([]);

  const toggleUploadMediaEditor = () => {
    setUploadMediaIsVisible(!uploadMediaIsVisible);
  };

  const toggleHtmlEditor = () => {
    setHtmlEditorVisible(!htmlEditorVisible);
  };

  let entity: ResourcePackEntity = {
    id: '',
    name: '',
    description: '',
    resources: []
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity
    });

    toggleEntityEditor();
  };

  const onEditEntity = () => {
    let resourcePacks = GetResourcePacks(state.gameDocument!);

    let resourcePacksIndex = resourcePacks.findIndex(
      (i) => i.name === currentLocale
    )!;

    if (resourcePacksIndex > -1) {
      let currentEntity = resourcePacks[resourcePacksIndex];
      setEntityEditorValue({
        isNew: false,
        entity: {
          id: currentEntity.id,
          description: currentEntity.description,
          resources: currentEntity.resources,
          name: currentEntity.name,
          displayLanguage: currentEntity.displayLanguage,
          displayLanguageUrl: currentEntity.displayLanguageUrl
        }
      });
    }

    toggleEntityEditor();
  };

  const handleUploadMediaSubmit = (uploadedMedia: UploadedImage) => {
    // no selected resource. exit early.
    if (!selectedResource) return;

    // no uploaded media. exit early.
    if (!uploadedMedia) return;

    // save the original resource
    if (selectedResource.isOriginal)
      resources.updateResourceFile(selectedResource.resourceId!, uploadedMedia);
    // save a resource pack resource
    else if (currentLocale && currentLocale !== '')
      resources.updateResourcePackFile(
        currentLocale,
        selectedResource.resourceId!,
        uploadedMedia
      );

    toggleUploadMediaEditor();
  };

  const handleHtmlEditorSubmit = (value: string) => {
    updateResourceHandler(
      selectedResource?.isOriginal!,
      selectedResource?.resourceId!,
      value!
    );

    toggleHtmlEditor();
  };

  const updateResourceValue = (resourceId: string, value: string) => {
    let resource = GetResourceEntity(state?.gameDocument!, resourceId);

    if (resource) {
      resource.value = value;
      UpdateResourceAsync(state?.gameDocument!, resource?.id, resource).then(
        (response) => {
          setState((prevState) => UpdateGameDocState(prevState, response));
        }
      );
    }
  };

  const updateResourcePackValue = (resourceId: string, value: string) => {
    if (currentLocale && currentLocale !== '') {
      let resource = GetResourcePackResource(
        state?.gameDocument!,
        currentLocale,
        resourceId
      );

      if (resource) {
        resource.value = value;
        UpdateResourcePackResourceAsync(
          state?.gameDocument!,
          currentLocale,
          resource.id,
          resource
        ).then((response) => {
          setState((prevState) => UpdateGameDocState(prevState, response!));
        });
      }

      if (!resource) {
        let originalResource = GetResourceEntity(
          state?.gameDocument!,
          resourceId
        );

        AddResourcePackResourceAsync(
          state?.gameDocument!,
          currentLocale,
          originalResource!
        ).then((response) => {
          setState((prevState) => UpdateGameDocState(prevState, response!));
        });
      }
    }
  };

  const updateResourceHandler = (
    isOriginal: boolean,
    resourceId: string,
    value: string
  ) => {
    if (isOriginal) {
      // Update the original resource on GameDocument.resources
      updateResourceValue(resourceId, value);
    }
    if (!isOriginal) {
      //Update the resource packs on GameDocument.resourcePacks
      updateResourcePackValue(resourceId, value);
    }
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ResourcePackEntity>,
    isCopyOriginal?: boolean,
    displayLanguage?: string
  ) => {
    const selectedDisplayLanguage = displayLanguages.find(
      (i) => i.languageName === displayLanguage
    );
    if (editorEntity.isNew) {
      AddResourcePackAsync(
        state?.gameDocument!,
        editorEntity?.entity?.name,
        isCopyOriginal,
        selectedDisplayLanguage?.languageName,
        selectedDisplayLanguage?.blobFileUrl
      ).then((response) => {
        setState((prevState) => UpdateGameDocState(prevState, response));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    } else {
      /* Updates the resource pack's detail in the Game Document.
       * Not Update resource.
       */
      UpdateResourcePackDetailAsync(
        state.gameDocument!,
        editorEntity.entity.name,
        editorEntity.entity.id,
        selectedDisplayLanguage!.languageName!,
        selectedDisplayLanguage!.blobFileUrl!
      ).then((response) => {
        setState((prevState) => UpdateGameDocState(prevState, response));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    }

    toggleEntityEditor();
  };

  const languageOrder = useCallback(() => {
    const {
      titleResId,
      shortDescriptionResId,
      longDescriptionResId,
      recommendedNumberOfPlayersResId,
      recommendedDurationResId,
      playersPerTeam,
      learningOutcomeResId
    } = state.gameDocument?.overview as Overview;
    let inventoryIds: { label: string; id: string }[] = [];
    let taskIds: { label: string; id: string }[] = [];
    let timerIds: { label: string; id: string }[] = [];
    let titleIds: { label: string; id: string }[] = [];
    let zoneIds: { label: string; id: string }[] = [];

    if (state.gameDocument) {
      const {
        items: inventories,
        tasks,
        timers,
        titles,
        zones
      } = state.gameDocument?.assets;

      if (inventories && inventories?.length > 0) {
        inventoryIds = [
          ...inventories.flatMap((i, idx) => [
            {
              label: `Inventory ${idx + 1} image`,
              id: i.imageResId as string
            },
            {
              label: `Inventory ${idx + 1} title`,
              id: i.titleResId as string
            },
            {
              label: `Inventory ${idx + 1} summary`,
              id: i.summaryResId as string
            }
          ])
        ];
      }

      if (tasks && tasks?.length > 0) {
        taskIds = [
          ...tasks.flatMap((i, idx) => [
            {
              label: `Task ${idx + 1} image`,
              id: i.imageResId as string
            },
            {
              label: `Task ${idx + 1} title`,
              id: i.titleResId as string
            }
          ])
        ];
      }

      if (timers && timers?.length > 0) {
        timerIds = [
          ...timers.flatMap((i, idx) => [
            {
              label: `Timer ${idx + 1} title`,
              id: i.titleResId as string
            }
          ])
        ];
      }

      if (titles && titles?.length > 0) {
        titleIds = [
          ...titles.flatMap((i, idx) => [
            {
              label: `Title ${idx + 1} image`,
              id: i.imageResId as string
            },
            {
              label: `Title ${idx + 1} title`,
              id: i.titleResId as string
            },
            {
              label: `Title ${idx + 1} summary`,
              id: i.summaryResId as string
            }
          ])
        ];
      }

      if (zones && zones?.length > 0) {
        zoneIds = [
          ...zones.flatMap((i, idx) => [
            {
              label: `Zone ${idx + 1} title`,
              id: i.titleResId as string
            }
          ])
        ];
      }
    }

    return [
      { label: 'Game title', id: titleResId },
      { label: 'Game description', id: shortDescriptionResId },
      { label: 'Game information for player', id: longDescriptionResId },
      {
        label: 'Recommended number of player',
        id: recommendedNumberOfPlayersResId
      },
      { label: 'Recommended duration', id: recommendedDurationResId },
      { label: 'Players per team', id: playersPerTeam },
      { label: 'Learning outcome', id: learningOutcomeResId },
      ...inventoryIds,
      ...taskIds,
      ...timerIds,
      ...titleIds,
      ...zoneIds
    ];
  }, [state]);

  const populateResources = () => {
    if (state?.gameDocument) {
      const map = GetResourcePackByName(
        state.gameDocument,
        currentLocale ?? ''
      );
      const orderedIds = languageOrder();
      map.sort(
        (a, b) =>
          orderedIds.findIndex((ordered) => ordered.id === a.id) -
          orderedIds.findIndex((ordered) => ordered.id === b.id)
      );

      const languageMapWithLabel = map.map((lang) => ({
        ...lang,
        label: orderedIds.find((ordered) => ordered.id === lang.id)?.label
      }));
      setLanguages(languageMapWithLabel);
    }
  };

  const handleUploadMedia = (
    isOriginal: boolean,
    resourceId: string,
    type: 'image' | 'video'
  ) => {
    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type
    });

    toggleUploadMediaEditor();
  };

  const handleHtmlEditor = (
    isOriginal: boolean,
    resourceId: string,
    type: 'Markdown' | 'Html'
  ) => {
    let value: string = '';

    if (isOriginal) {
      value = GetResourceValue(state?.gameDocument!, resourceId);
    }

    if (!isOriginal) {
      value =
        GetResourcePackResource(
          state?.gameDocument!,
          currentLocale!,
          resourceId
        )?.value ?? '';
    }

    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type,
      value: value
    });

    toggleHtmlEditor();
  };

  const CustomCell = (isOriginal: boolean, props: GridCellProps) => {
    const resource = languages?.find((x) => x.id === props.dataItem.id);

    if (!isOriginal && (!currentLocale || currentLocale === '')) {
      return <></>;
    }

    if (resource?.type === 'image') {
      return (
        <CustomColumnImage
          columnClass={'w-50'}
          containerClass={
            'd-flex flex-column gap-1 justify-content-between w-full p-2'
          }
          label={resource.label}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => handleUploadMedia(isOriginal, id!, 'image')}
          maxHeight={100}></CustomColumnImage>
      );
    }
    if (resource?.type === 'video') {
      return (
        <CustomColumnVideo
          columnClass={'w-50'}
          containerClass={'d-flex justify-content-end w-full p-2'}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => handleUploadMedia(isOriginal, id!, 'video')}
          maxHeight={100}></CustomColumnVideo>
      );
    }

    if (resource?.type === 'text') {
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          containerClass={
            'd-flex flex-column gap-1 justify-content-between w-full p-2'
          }
          label={resource.label}
          id={`${isOriginal ? 'original' : ''}-${resource?.id}`}
          onChange={(id, value) =>
            updateResourceHandler(isOriginal, resource?.id!, value!)
          }
          text={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          rows={1}
          autoSize
        />
      );
    }
    if (resource?.type === 'text-long') {
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          id={resource?.id}
          containerClass={'d-flex justify-content-center w-100 p-2'}
          onChange={(id, value) =>
            updateResourceHandler(isOriginal, id!, value!)
          }
          text={
            isOriginal ? resource?.originalLanguage : resource?.newLanguage
          }></CustomColumnTextarea>
      );
    }
    if (resource?.type === 'text-md') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => handleHtmlEditor(isOriginal, id!, 'Markdown')}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={'Markdown'}></CustomColumnPlainText>
      );
    }
    if (resource?.type === 'text-html') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => handleHtmlEditor(isOriginal, id!, 'Html')}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={resource.label ?? 'Html'}></CustomColumnPlainText>
      );
    }
    return (
      <CustomColumnPlainText
        columnClass={'w-50'}
        containerClass={'d-flex justify-content-between w-full p-2'}
        text={
          isOriginal ? resource?.originalLanguage : resource?.newLanguage
        }></CustomColumnPlainText>
    );
  };

  const rowRender = (trElement: ReactElement<HTMLTableRowElement>) => {
    const trProps: any = { className: 'k-master-row k-grid-edit-row d-flex' };
    return cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children as any
    );
  };

  const onDeleteHandler = () => {
    setShowDeleteLanguageConfirm(true);
  };

  const onConfirmDeleteLanguage = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const newGameDocument = {
        ...state.gameDocument,
        resourcePacks: state.gameDocument?.resourcePacks?.filter(
          (resourcePack) => resourcePack.name !== currentLocale
        )
      } as GameDocument;

      setState((prevState) => UpdateGameDocState(prevState, newGameDocument));
      setCurrentLocale('');
    }
    setShowDeleteLanguageConfirm(false);
  };

  const languageSelectedEvent = (event: DropDownListChangeEvent) => {
    setCurrentLocale(event.value);
  };

  const populateLanguages = () => {
    if (state.gameDocument) {
      let newLanguages: string[] = [];
      state.gameDocument.resourcePacks?.forEach((resourcePack) => {
        newLanguages.push(resourcePack?.name);
      });

      setLanguageList(newLanguages);
      // for add language combobox
      if (
        state.gameDocument.overview &&
        state.gameDocument.overview.languages
      ) {
        let index = newLanguages.indexOf(state.gameDocument.overview.languages);

        if (index === -1) {
          setListedLanguages(
            newLanguages.concat(state.gameDocument.overview.languages)
          );
        } else {
          setListedLanguages(newLanguages);
        }
      } else {
        setListedLanguages(newLanguages);
      }

      // for add display language combobox
      if (
        state.gameDocument.overview &&
        state.gameDocument.overview.displayLanguages
      ) {
        let indexDisplayLanguages = newLanguages.indexOf(
          state.gameDocument.overview.displayLanguages
        );

        if (indexDisplayLanguages === -1) {
          setListedDisplayLanguages(
            newLanguages.concat(state.gameDocument.overview.displayLanguages)
          );
        } else {
          setListedDisplayLanguages(newLanguages);
        }
      } else {
        setListedDisplayLanguages(newLanguages);
      }

      if (currentLocale === undefined || currentLocale === '') {
        setCurrentLocale(newLanguages[0]);
      }
    }
  };

  useEffect(() => {
    GetDisplayLanguagePublished().then((res) => {
      setDisplayLanguages(res.data);
    });
  }, []);

  useEffect(() => {
    // Set the page title.

    if (state?.gameDocument) {
      document.title = `Language - ${GetResourceValue(
        state.gameDocument,
        state.gameDocument.overview?.titleResId!
      )}`;
      populateLanguages();
      populateResources();
    }
  }, [state]);

  useEffect(() => {
    if (currentLocale !== '') {
      populateResources();
    }
    populateLanguages();
  }, [currentLocale]);

  useEffect(() => {
    populateLanguages();
  }, []);

  return (
    <>
      <Toolbar
        title={'Language'}
        notificationTitle={'Language'}
        buttonHelpSupport={{
          title: 'Language and culture guide',
          url: 'https://forum.catalystglobal.com/t/4161'
        }}>
        {entityEditorIsVisible && (
          <ResourcePackEditorWindow
            toggleDialog={toggleEntityEditor}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
            onSubmit={handleEntityEditorSubmit}
            isLanguageCombobox={true}
            listedLanguages={listedLanguages}></ResourcePackEditorWindow>
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search items'}
          columnsToSearch={['newLanguage', 'originalLanguage']}
          showCardMode={false}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>

        <Grid
          pageable={DefaultGridSettings.pagerSettings}
          editField={'isEditing'}
          className={'cg-grid3 w-full'}
          data={process(languages ?? [], dataState)}
          dataItemKey={'id'}
          {...dataState}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
          rowRender={rowRender}>
          <GridToolbar>
            <Row
              className={
                'd-flex justify-content-between w-100 align-items-center'
              }>
              <Col>
                <span className={'fw-bold'}>Original</span>
              </Col>
              <Col>
                <div className="d-flex justify-content-between gap-2">
                  <DropDownList
                    className={'bg-white fw-bold w-100'}
                    value={currentLocale}
                    onChange={languageSelectedEvent}
                    data={languageList}
                  />
                  {languageList && languageList.length > 0 && (
                    <Button
                      title={'Delete'}
                      onClick={onEditEntity}
                      themeColor={'secondary'}
                      size={'medium'}
                      className={'medium'}
                      fillMode={'flat'}>
                      <span className="material-symbols-outlined">
                        {'edit'}
                      </span>
                    </Button>
                  )}
                  {languageList && languageList.length > 0 && (
                    <Button
                      title={'Delete'}
                      onClick={onDeleteHandler}
                      themeColor={'error'}
                      size={'medium'}
                      className={'medium'}
                      fillMode={'flat'}>
                      <span className="material-symbols-outlined">
                        {'delete'}
                      </span>
                    </Button>
                  )}
                  <Button onClick={onAddEntity} themeColor={'success'}>
                    + Create new
                  </Button>
                </div>
              </Col>
            </Row>
          </GridToolbar>
          <Column
            groupable={false}
            field={'originalLanguage'}
            editable={false}
            headerClassName={'d-none'}
            cell={(props) => CustomCell(true, props)}
          />

          <Column
            groupable={false}
            field={'newLanguage'}
            headerClassName={'d-none'}
            className={'w-50'}
            cell={(props) => CustomCell(false, props)}
          />

          <GridNoRecords>
            <NoRecords />
          </GridNoRecords>
        </Grid>
      </div>
      {uploadMediaIsVisible && (
        <ResourceWindow
          toggleDialog={toggleUploadMediaEditor}
          onSubmit={handleUploadMediaSubmit}
          acceptedExtension={`${selectedResource?.type}/*`}
          title={`Upload ${selectedResource?.type}`}
          onClose={(e) => toggleUploadMediaEditor()}
        />
      )}
      {htmlEditorVisible && (
        <ResourcePackHtmlEditorWindow
          toggleDialog={toggleHtmlEditor}
          text={selectedResource?.value}
          type={selectedResource?.type as any}
          onSubmit={handleHtmlEditorSubmit}
          onClose={toggleHtmlEditor}
        />
      )}
      {showDeleteLanguageConfirm && (
        <YesNoDialog
          title="Confirm removal"
          onConfirm={onConfirmDeleteLanguage}
          onClose={() => setShowDeleteLanguageConfirm(false)}>
          Are you sure you want to remove the language?
        </YesNoDialog>
      )}
    </>
  );
};

export default Language;
