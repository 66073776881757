import { GameDocumentContext } from '../../contexts/game-document';
import { useCallback, useContext, useEffect, useState } from 'react';
import Toolbar from './toolbar';
import { Container } from 'react-bootstrap';
import { TabContentCG } from '../../components/tabs';
import {
  PreGameSetting,
  InGameSetting,
  DesignerSetting
} from '../../components/settings';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { ContentItem } from '../../types/tab-content';
import {
  AddPreGameSettingAsync,
  AddInGameSettingAsync,
  AddDesignerSettingAsync
} from '../../utils/game-document/settings';
import { UpdateGameDocState } from '../../utils/game-document';
import { GameDocument } from '../../types/game-document';

const GameSettings = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const onChangeHandler = useCallback(
    (updatedGameDocument: GameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
    },
    [setState]
  );

  const setGameSetting = () => {
    !state.gameDocument?.settings.designer &&
      AddDesignerSettingAsync(state.gameDocument!).then((updated) =>
        onChangeHandler(updated)
      );

    !state.gameDocument?.settings.preGame &&
      AddPreGameSettingAsync(
        state.gameDocument!,
        'Pre-Game Setting',
        'Pre-Game Setting'
      ).then((updated) => onChangeHandler(updated));

    !state.gameDocument?.settings.inGame &&
      AddInGameSettingAsync(
        state.gameDocument!,
        'In-Game Setting',
        'In-Game Setting'
      ).then((updated) => onChangeHandler(updated));
  };

  useEffect(() => {
    // Set the page title.
    document.title = `Settings - ${state.gameDocument?.name}`;
    setGameSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: ContentItem[] = [
    {
      title: 'Designer',
      content: <DesignerSetting state={state} onChange={onChangeHandler} />
    },
    {
      title: 'Pre-Game',
      content: <PreGameSetting state={state} onChange={onChangeHandler} />
    },
    {
      title: 'In-Game',
      content: <InGameSetting state={state} onChange={onChangeHandler} />
    }
  ];

  return (
    <div>
      <Toolbar
        title={'Settings'}
        buttonHelpSupport={{
          title: 'Game Designer settings guide',
          url: 'https://forum.catalystglobal.com/t/4528'
        }}></Toolbar>
      <Container className="setting-container pl-0" fluid>
        <TabContentCG
          selected={selectedTab}
          handleSelect={handleTabSelect}
          items={items}
        />
      </Container>
    </div>
  );
};

export default GameSettings;
