import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  GetOrganisationsForNetworkAsync,
  GetOrganisationsForReportAsync
} from '../services/organisations';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { ColumnMenu } from '../components/columnMenu';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { NoRecords } from '../components/grid/no-records';
import { DefaultGridSettings } from '../constants/grid-settings';
import { appStore } from '../stores/app-store';
import {
  CardViewDataStateChangeEvent,
  NetworkOrganisationsCard
} from './network-organisations-card';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const NetworkOrganisationsGrid = () => {
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [organisations, setOrganisations] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchOrganisations = async () => {
    GetOrganisationsForNetworkAsync(dataState)
      .then((response) => {
        setOrganisations(response);
      })
      .catch();
  };

  useEffect(() => {
    fetchOrganisations();
  }, [dataState]);

  const reportExportRef = React.useRef<ExcelExport | null>(null);

  const onExportExcel = async () => {
    appStore.showLoading();
    try {
      await GetOrganisationsForReportAsync().then((response) => {
        if (reportExportRef.current !== null) {
          reportExportRef.current.save(response);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  return (
    <>
      <Toolbar title={'Organisations'}>
        <HelpSupport
          title="My network guide"
          url="https://forum.catalystglobal.com/t//4215"
        />
      </Toolbar>
      <ExcelExport ref={reportExportRef} fileName={'OrganisationsReport.xlsx'}>
        <ExcelExportColumn field="name" title="Name" width={300} />
        <ExcelExportColumn field="email" title="Email" width={200} />
        <ExcelExportColumn field="phoneNumber" title="Phone" width={200} />
        <ExcelExportColumn field="territory" title="Territory(s)" width={350} />
        <ExcelExportColumn field="languages" title="Language" width={200} />
        <ExcelExportColumn field="website" title="Website" width={200} />
      </ExcelExport>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search organisations'}
          columnsToSearch={['name']}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          exportRef={reportExportRef}
          useCustomExportExcel={true}
          handleCustomExportToExcel={onExportExcel}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        {gridMode === 'grid' && (
          <Grid
            id={'grid-organisations'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={organisations}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
            <Column field={'email'} title={'Email'} columnMenu={ColumnMenu} />
            <Column
              field={'phoneNumber'}
              title={'Phone'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'territory'}
              title={'Territory(s)'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'languages'}
              title={'Language'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'website'}
              title={'Website'}
              columnMenu={ColumnMenu}
            />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        )}
        {gridMode === 'card' && (
          <NetworkOrganisationsCard
            data={organisations.data}
            total={organisations.total}
            {...dataState}
            onDataStateChange={(e: CardViewDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
          />
        )}
      </div>
    </>
  );
};
export default NetworkOrganisationsGrid;
