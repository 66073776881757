import React, { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Authenticating, AuthenticationError, SessionLost } from './';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import {
  getLastAuthenticatedURL,
  setLastAuthenticatedURL
} from '../../utils/authentication';
import { LayoutProvider } from '../../contexts/layout-provider';

interface AuthRequiredProps {
  children?: React.ReactNode;
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  // Function to handle authentication flow
  const handleAuthentication = async () => {
    // If there are no authentication parameters, the user is not authenticated, there's no active navigator,
    // and the page is not loading, then initiate the sign-in redirect
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      await auth.signinRedirect();
    }

    // Get the last authenticated URL from localStorage
    const lastURL = getLastAuthenticatedURL();

    // If the user is authenticated and a last URL exists, navigate to that URL and clear the last URL from localStorage
    if (auth.isAuthenticated && lastURL) {
      const url = new URL(lastURL);
      navigate(url.pathname);
      setLastAuthenticatedURL('');
    }
  };

  // Effect hook to handle authentication flow on component mount and dependencies change
  useEffect(() => {
    handleAuthentication();
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    navigate
  ]);

  // Function to handle force login action
  const handleForceLogin = async () => {
    await auth.removeUser();
    await auth.clearStaleState();
    await auth.signinRedirect();
  };

  // Function to handle force logout action
  const handleForceLogout = async () => {
    await auth.removeUser();
    await auth.clearStaleState();
    await auth.signoutRedirect();
  };

  // If the authentication process is loading, display an authenticating message
  if (auth.isLoading) {
    // If the page URL does not include 'scope', set the last authenticated URL in localStorage
    if (!window.location.href.includes('openid')) {
      setLastAuthenticatedURL(window.location.href);
    }
    return <Authenticating />;
  }

  // If there's an authentication error, display an authentication error message and provide options to force login or logout
  if (auth.error) {
    const errorMessage =
      auth.error.message || 'An error occurred during authentication.';
    return (
      <AuthenticationError>
        <>
          <p>{errorMessage}.</p>
          <div className={'d-flex justify-content-center'}>
            <Button
              themeColor={'warning'}
              size={'small'}
              fillMode={'flat'}
              onClick={handleForceLogin}>
              Force login
            </Button>
            <Button
              themeColor={'error'}
              size={'small'}
              fillMode={'flat'}
              onClick={handleForceLogout}>
              Force logout
            </Button>
          </div>
        </>
      </AuthenticationError>
    );
  }

  // If the user is authenticated, render the children components
  if (auth.isAuthenticated) {
    return <LayoutProvider>{children}</LayoutProvider>;
  }

  // If the user is not authenticated, display a session lost message
  return <SessionLost />;
};
