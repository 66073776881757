import { Logo } from '../logo';
import React, { useContext } from 'react';
import { Link, To } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import Actions from './actions';
import UserCard from './user-card';
import UserIcon from './user-icon';
import { LayoutContext } from '../../contexts/layout-provider';

export { default as Actions } from './actions';
export { default as LogoutButton } from './logout-button';
export { default as UserCard } from './user-card';
export { default as UserIcon } from './user-icon';
export { default as UserMenu } from './user-menu';

export interface HeaderBreadcrumb {
  text?: string;
  icon?: React.ReactNode;
  to: To;
}

interface HeaderProps {
  title: string;
  breadCrumbs?: HeaderBreadcrumb[];
}

export const Header = (props: HeaderProps) => {
  const layout = useContext(LayoutContext);
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div className={'page-base__header'}>
      <div className={'header__title'}>
        <h1>{props.title}</h1>
        <nav aria-label={'breadcrumb'}>
          <ol className={'breadcrumb'}>
            {props.breadCrumbs?.map((crumb, index) => {
              return (
                <li className={'breadcrumb-item'} key={`crumb-${index}`}>
                  <Link to={crumb.to}>{crumb.text}</Link>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <div className={'d-flex justify-content-between'}>
        {isMobile && (
          <div className={'d-flex align-items-center'}>
            <span
              className="material-symbols-outlined cursor-pointer text-decoration-none"
              onClick={layout.toggleNavIsCollapsed}>
              menu
            </span>
            <Logo
              className="p-2"
              primaryColor={'#4698cb'}
              width={'3rem'}
              height={'3rem'}
            />
          </div>
        )}
        <div className={'d-flex'}>
          <Actions />
          {!isMobile && <UserCard />}
          <UserIcon />
        </div>
      </div>
    </div>
  );
};

export default Header;
