import { Error, Label } from '@progress/kendo-react-labels';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ComboboxInput, FormInput } from './form-input';
import { User } from '../types/user';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';

import {
  EventPlaylist,
  EventPlaylistFacilitator,
  EventPlaylistLocation
} from '../types/event';
import { Library } from '../types/library';
import { Autocomplete } from './auto-complete';
import { EnvironmentList } from '../types/environment';
import { convertJsonDateToDate, getTotalMinutes } from '../utils/date';
import { CountryResponse } from '../types/responses/country-response';
import { TimeZoneResponse } from '../types/responses/timezone-response';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import LanguageComboBox from './combobox/language-combobox';
import { toastStore } from '../stores/toast-store';
import { Assessment } from '../types/assessment';
import YesNoDialog from './dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../types/dialog-result';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { plusIcon } from '@progress/kendo-svg-icons';
import cloneDeep from 'lodash.clonedeep';

export interface GameEventProps {
  users?: User[];
  index?: number;
  game?: EventPlaylist;
  languages?: string[];
  libraries?: Library[];
  assessments?: Assessment[];
  countries?: CountryResponse[];
  timezones?: TimeZoneResponse[];
  environments?: EnvironmentList[];
  locations?: EventPlaylistLocation[];
  facilitators?: EventPlaylistFacilitator[];
  onCopyGame?: (index: number) => void;
  onDeleteGame?: (index: number) => void;
  onChangeGameSettings?: (gameId: number) => void;
  onChange?: (game: EventPlaylist, index: number) => void;
  onChangeLocation: (locations: EventPlaylistLocation[]) => void;
  onChangeFacilitator: (facilitator: EventPlaylistFacilitator) => void;
}

export type dictionary = {
  id: number;
  name: string;
};

export const ActivityEvent = ({
  game,
  index,
  users,
  locations,
  libraries,
  countries,
  languages,
  timezones,
  assessments,
  facilitators,
  environments,
  onChange,
  onCopyGame,
  onDeleteGame,
  onChangeLocation = () => {},
  onChangeFacilitator = () => {},
  onChangeGameSettings = () => {}
}: GameEventProps) => {
  const [searchParams] = useSearchParams();
  const [eventGame, setEventGame] = useState<EventPlaylist>({});
  const [facilitatorsState, setFacilitatorsState] = useState<
    EventPlaylistFacilitator[]
  >([]);
  const [locationsState, setLocationsState] = useState<EventPlaylistLocation[]>(
    locations || []
  );

  const [gameData, setGameData] = useState<Library[]>(libraries?.slice() ?? []);
  const [assessmentData, setAssessmentData] = useState<Assessment[]>(
    assessments?.slice() ?? []
  );
  const [language, setLanguage] = useState<string[]>(languages?.slice() ?? []);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  useEffect(() => {
    onChangeLocation(locationsState);
  }, [locationsState]);

  useEffect(() => {
    if (facilitators) {
      setFacilitatorsState(facilitators);
    }
  }, [facilitators]);

  const validEditing = (showToast: boolean = true) => {
    const actionParams = searchParams.get('action');
    let totalMinute = getTotalMinutes(
      new Date(eventGame?.startDateUtc!),
      new Date()
    );
    if (actionParams !== 'copy' && eventGame?.id! > 0 && totalMinute > 0) {
      if (showToast) {
        toastStore.show(
          'Error',
          <div>{`Changes to an activity that has started are not permitted.`}</div>,
          'error'
        );
      }

      return false;
    }

    return true;
  };

  const onDeleteFacilitator = (facilitator: EventPlaylistFacilitator) => {
    if (!validEditing()) {
      return;
    }

    if (facilitatorsState) {
      const idx = facilitatorsState.indexOf(facilitator);
      if (idx > -1) {
        facilitatorsState[idx].isDeleted = true;
        onChangeFacilitator(facilitatorsState[idx]);
      }
    }
  };

  const onDeleteLocation = (
    location: EventPlaylistLocation,
    action: 'delete' | 'reset'
  ) => {
    if (!validEditing()) {
      return;
    }

    const idx = locationsState.indexOf(location);
    if (idx > -1) {
      setLocationsState((prev) => {
        const result = cloneDeep(prev);
        action === 'delete'
          ? result.splice(idx, 1)
          : result.splice(idx, 1, {
              id: 0,
              eventPlaylistId: 0,
              locationCountryISO: '',
              country: '',
              city: '',
              isDeleted: false
            });

        return result;
      });
    }
  };

  const handleDeleteGame = () => {
    if (!validEditing()) {
      return;
    }
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes' && onDeleteGame && index !== undefined) {
      try {
        await onDeleteGame(index);
        toastStore.show('My events', <div>Activity deleted.</div>, 'success');
      } catch {
        toastStore.show(
          'My events',
          <div>Failed to delete activity.</div>,
          'error'
        );
      }
    }
    setShowDeleteConfirm(false);
  };

  const handleChangeGameSettings = () => {
    onChangeGameSettings(game?.gameId!);
  };

  const handleCopyGame = () => {
    if (onCopyGame && index !== undefined) {
      onCopyGame(index);
    }
  };

  const onChangeField = (
    field: keyof EventPlaylist,
    value: string | boolean
  ) => {
    if (!validEditing()) {
      return;
    }

    let newEvent = { ...eventGame };

    if (newEvent && field) {
      newEvent[field] = value as any; // This type is set to any because it's possibly a string, number or date
    }

    if (newEvent) {
      onChangeActivityHandler(newEvent);
    }
  };

  const onChangeDateField = (
    field: keyof EventPlaylist,
    value: Date | null
  ) => {
    if (!validEditing()) {
      return;
    }
    if (value) {
      let newEvent = { ...eventGame };

      newEvent[field] = value as any;

      if (newEvent) {
        onChangeActivityHandler(newEvent);
      }
    }
  };

  const onChangeLocationHandler = (idx: number, country: CountryResponse) => {
    if (!validEditing()) {
      return;
    }

    if (country) {
      let newLocation = {
        id: 0,
        eventPlaylistId: eventGame.id ?? 0,
        locationCountryISO: country?.iso!,
        country: country?.country ?? '',
        isDeleted: false
      };
      setLocationsState((prev) => {
        const result = cloneDeep(prev);
        result.splice(idx, 1, newLocation);

        return result;
      });
    }
  };

  const onAddNewLocation = () => {
    const newLocation = {
      id: 0,
      eventPlaylistId: eventGame.id ?? 0,
      locationCountryISO: '',
      country: '',
      isDeleted: false
    };

    setLocationsState((prev) => [...prev, newLocation]);
  };

  const onChangeCity = (idx: number, city: string) => {
    if (!validEditing()) {
      return;
    }

    setLocationsState((prev) => {
      const result = cloneDeep(prev);
      result[idx].city = city;
      return result;
    });
  };

  const onChangeTimezoneHandler = (e: ComboBoxChangeEvent) => {
    if (!validEditing()) {
      return;
    }
    const newEvent = { ...eventGame };
    const timezone = e.value;

    if (newEvent) {
      newEvent.timeZone = timezone?.id;
    }

    onChangeActivityHandler(newEvent);
  };

  const onChangeEnvironmentHandler = (e: ComboBoxChangeEvent) => {
    if (!validEditing()) {
      return;
    }
    let newEvent = { ...eventGame };
    let env = e.value as dictionary;

    if (newEvent) {
      newEvent.environmentId = env?.id;
      if (game) {
        game.environmentId = env?.id;
      }
    }

    onChangeActivityHandler(newEvent);
  };

  const onChangeFacilitatorhandler = (user: User) => {
    if (!validEditing()) {
      return;
    }
    if (user) {
      let newFacilitator = {
        id: 0,
        firstName: user?.firstName,
        fullName: user?.fullName,
        facilitatorUserProfileId: user?.id,
        isDeleted: false
      };

      onChangeFacilitator(newFacilitator);
    }
  };

  const onChangeSelectGameHandler = (changeEvent: ComboBoxChangeEvent) => {
    if (!validEditing()) {
      return;
    }

    let newEvent = { ...eventGame };
    if (changeEvent.value) {
      newEvent.name = changeEvent.value?.name;
      newEvent.gameId = changeEvent.value?.id;
      newEvent.isGameActivity = true;
    } else {
      newEvent.name = undefined;
      newEvent.approxPax = undefined;
      newEvent.gameId = undefined;
      newEvent.isGameActivity = false;
    }

    onChangeActivityHandler(newEvent);
    if (onChange && (index as number) > -1) {
      onChange(newEvent, index as number);
    }
  };

  const onChangeSelectAssessmentHandler = (
    changeEvent: ComboBoxChangeEvent
  ) => {
    if (!validEditing()) {
      return;
    }

    let newEvent = { ...eventGame };
    if (changeEvent.value) {
      newEvent.isAssessmentActivity = true;
      newEvent.assessmentId = changeEvent.value?.id;
    } else {
      newEvent.isAssessmentActivity = false;
      newEvent.assessmentId = undefined;
    }

    onChangeActivityHandler(newEvent);
    if (onChange && (index as number) > -1) {
      onChange(newEvent, index as number);
    }
  };

  const onChangeActivityHandler = (game: EventPlaylist) => {
    if (!validEditing()) {
      return;
    }
    if (onChange && (index as number) > -1) {
      onChange(game, index as number);
    }
  };

  const [loading, setLoading] = useState<{
    game: boolean;
    assessment: boolean;
  }>({
    game: false,
    assessment: false
  });

  const filterData = (filter: FilterDescriptor) => {
    const data = languages!.slice();
    return filterBy(data, filter);
  };

  const filterGameData = (filter: FilterDescriptor) => {
    const data = libraries!.slice();
    return filterBy(data, filter);
  };

  const filterAssessmentData = (filter: FilterDescriptor) => {
    const data = assessments!.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setLanguage(filterData(event.filter));
  };

  // debounce 500ms so filtering doesnt take affect after every keystroke
  const timeout = useRef<any>(null);
  const filterGameChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setGameData(filterGameData(event.filter));
      setLoading((prev) => ({ ...prev, game: false }));
    }, 500);
    setLoading((prev) => ({ ...prev, game: true }));
  };

  const filterAssessmentChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setAssessmentData(filterAssessmentData(event.filter));
      setLoading((prev) => ({ ...prev, assessment: false }));
    }, 500);
    setLoading((prev) => ({ ...prev, assessment: true }));
  };

  const itemTemplate = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    if (itemProps.dataItem.type === 'AR' || itemProps.dataItem.type === 'VR') {
      itemChildren = (
        <div>
          <span>{itemProps.dataItem.name} </span>
          <span className={'text-success fw-bold'}>
            {itemProps.dataItem.type}
          </span>
        </div>
      );
    } else {
      itemChildren = <span>{itemProps.dataItem.name}</span>;
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  useEffect(() => {
    if (game) {
      let newEventGame = { ...game };
      if (game.gameId && game.gameId > 0) {
        newEventGame.isGameActivity = true;
      }
      if (game.assessmentId && game.assessmentId > 0) {
        newEventGame.isAssessmentActivity = true;
      }
      setEventGame(newEventGame);
    }
  }, [game]);

  return (
    <>
      <div className="mt-4">Activity {(index as number) + 1}</div>
      <div className="card p-4 d-flex flex-column gap-2">
        <Row className={'p-1'}>
          <Col md="7">
            <Label>Game name</Label>
            <ComboBox
              data={gameData}
              textField={'name'}
              itemRender={itemTemplate}
              dataItemKey={'id'}
              filterable={true}
              onFilterChange={filterGameChange}
              onChange={onChangeSelectGameHandler}
              loading={loading.game}
              allowCustom={false}
              value={{
                id: libraries?.find((x) => x.id === eventGame?.gameId)?.id,
                name: libraries?.find((x) => x.id === eventGame?.gameId)?.name
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="7">
            <Checkbox
              name={'allowGameAutomaticStart'}
              label={'Auto-start game based on start date'}
              size={'large'}
              onChange={(e) =>
                onChangeField('allowGameAutomaticStart', e.value)
              }
              value={game?.allowGameAutomaticStart}
              disabled={!eventGame.isGameActivity}
            />
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="7">
            <Label>Assessment name</Label>
            <ComboBox
              data={assessmentData}
              textField={'name'}
              dataItemKey={'id'}
              filterable={true}
              onFilterChange={filterAssessmentChange}
              onChange={onChangeSelectAssessmentHandler}
              loading={loading.assessment}
              allowCustom={false}
              value={{
                id: assessmentData?.find(
                  (x) => x.id === eventGame?.assessmentId
                )?.id,
                name: assessmentData?.find(
                  (x) => x.id === eventGame?.assessmentId
                )?.name
              }}
            />
          </Col>
        </Row>

        <Row className="p-1">
          <Col md="12">
            <div
              className="p-4"
              style={{
                backgroundColor: '#f7fcff',
                textAlign: 'justify'
              }}>
              <span>
                <span className="fw-bold text-primary">
                  Environment and Duration:
                </span>{' '}
                Accuracy is important. This information instructs the Server
                Environment and informs the support team of your event. Failure
                to add accurate information could jeapodise your event. You can
                edit this information before the activity starts.
              </span>
            </div>
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="7">
            <Label>Environment*</Label>
            <ComboboxInput
              data={environments}
              textField="name"
              filterable={true}
              value={
                environments?.find((x) => x.id === eventGame?.environmentId) ||
                ''
              }
              onChange={onChangeEnvironmentHandler}
            />
            {eventGame?.environmentId === undefined && (
              <Error>{'Environment is required'}</Error>
            )}
          </Col>

          <Col md="5">
            <Label
              style={{
                whiteSpace: 'nowrap'
              }}>
              Time zone
            </Label>
            <ComboboxInput
              data={timezones}
              textField="displayName"
              onChange={onChangeTimezoneHandler}
              value={timezones?.find((x) => x.id === eventGame?.timeZone) || ''}
              filterable
            />
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="4">
            <Label>Start date*</Label>
            <DatePicker
              value={convertJsonDateToDate(eventGame.startDateUtc)}
              format="dd/MMM/yyyy"
              onChange={(e) => onChangeDateField('startDateUtc', e.value)}
            />
            {!game?.startDateUtc && <Error>Start date is required</Error>}
          </Col>
          <Col md="3">
            <Label>Time*</Label>
            <TimePicker
              value={convertJsonDateToDate(eventGame.startDateUtc)}
              onChange={(e) => onChangeDateField('startDateUtc', e.value)}
            />
            {!game?.startDateUtc && <Error>Time is required</Error>}
          </Col>
        </Row>

        <Row className={'d-flex p-1'}>
          <Col md="4">
            <Label>End date*</Label>
            <DatePicker
              value={convertJsonDateToDate(eventGame.endDateUtc)}
              format="dd/MMM/yyyy"
              onChange={(e) => onChangeDateField('endDateUtc', e.value)}
            />
            {!game?.endDateUtc && <Error>End date is required</Error>}
          </Col>
          <Col md="3">
            <Label>Time*</Label>
            <TimePicker
              value={convertJsonDateToDate(eventGame.endDateUtc)}
              onChange={(e) => onChangeDateField('endDateUtc', e.value)}
            />
            {!game?.endDateUtc && <Error>Time is required</Error>}
          </Col>
        </Row>

        <Row className="p-1">
          <Col md="12">
            <div
              className="p-4"
              style={{
                backgroundColor: '#f7fcff',
                textAlign: 'justify'
              }}>
              <span>
                <span className="fw-bold text-primary">Important:</span> Your
                activity will automatically end 48 hours after the end date
                selected.
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="7">
            <Label>Language*</Label>
            <LanguageComboBox
              data={language}
              filterable={true}
              onFilterChange={filterChange}
              value={eventGame?.language}
              onChange={(e) => onChangeField('language', e.value ?? '')}
            />
            {!game?.language && <Error>Language is required</Error>}
          </Col>
          <Col md="5">
            <FormInput
              label="Pax*"
              value={game?.approxPax ?? ''}
              onChange={(e) => onChangeField('approxPax', e.value)}></FormInput>
            {!game?.approxPax && <Error>Pax is required</Error>}
          </Col>
        </Row>

        <Row>
          <Label className={'mt-2'}>Location(s)*</Label>
          <Col md={'12'} className={'px-4'}>
            {locationsState?.map((location, idx) => (
              <div
                key={idx}
                className={`d-flex align-items-center gap-2 ${idx !== 0 && 'mt-2'}`}>
                <div className={'d-flex align-items-start gap-2 w-100'}>
                  <div className={'d-flex flex-column w-50'}>
                    <Label>Country</Label>
                    <Autocomplete<CountryResponse>
                      className={'d-flex justify-content-start w-100'}
                      label={'Location/s'}
                      items={countries ?? []}
                      textField={'country'}
                      value={location}
                      onChange={(country) =>
                        onChangeLocationHandler(idx, country)
                      }
                      onClear={() => {
                        const result = cloneDeep(location);
                        result.country = '';
                        result.locationCountryISO = '';
                        onChangeLocationHandler(idx, result);
                      }}
                    />
                    {!location.country && <Error>Country is required</Error>}
                  </div>

                  <div className={'d-flex flex-column w-50'}>
                    <FormInput
                      label={'City'}
                      value={location.city}
                      onChange={(e) => {
                        onChangeCity(idx, (e.target.value as string) ?? '');
                      }}
                    />
                    {!location.city && <Error>City is required</Error>}
                  </div>
                </div>

                <div className={'d-flex flex-column'}>
                  <Label />
                  <span
                    className={'material-symbols-outlined text-danger ml-3'}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      onDeleteLocation(
                        location,
                        locationsState.length > 1 ? 'delete' : 'reset'
                      )
                    }>
                    delete
                  </span>
                  {(!location.country || !location.city) && (
                    <Error
                      style={{
                        color: 'transparent'
                      }}>
                      Error
                    </Error>
                  )}
                </div>
              </div>
            ))}
          </Col>

          <Col md="12" className="mt-2 px-4">
            <Button
              className="w-full"
              svgIcon={plusIcon}
              onClick={onAddNewLocation}>
              Add location
            </Button>
          </Col>
        </Row>

        <Row className={'d-flex p-1'}>
          <Col md="12">
            <Label>Facilitator/s</Label>
          </Col>
          <Col md="12">
            <Autocomplete<User>
              className={'d-flex justify-content-start w-100'}
              label={'Facilitator/s'}
              items={users?.filter((user) => user.fullName !== null) ?? []}
              textField="fullName"
              onChange={onChangeFacilitatorhandler}></Autocomplete>
          </Col>
          <Col md="12" className={'mt-2'}>
            <ul className="list-group list-group-flush">
              {facilitatorsState &&
                facilitatorsState
                  .filter((x) => !x.isDeleted)
                  .map((fc, index) => {
                    return (
                      <li className="list-group-item d-flex justify-content-between">
                        <span className={'text-primary'}>
                          {fc?.fullName ?? ''}
                        </span>
                        <span
                          className={'material-symbols-outlined text-danger'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            onDeleteFacilitator(fc);
                          }}>
                          close
                        </span>
                      </li>
                    );
                  })}
            </ul>
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="12">
            <Label>Activity notes</Label>
            <TextArea
              rows={4}
              value={game?.notes}
              onChange={(e) => onChangeField('notes', e.value)}></TextArea>
          </Col>
        </Row>

        <Row className={'p-1 mt-3'}>
          <Col md="6" className={'d-flex align-items-center mt-3'}></Col>
          <Col md="6" className={'d-flex justify-content-end mt-3'}>
            <span
              className={'material-symbols-outlined'}
              style={{ cursor: 'pointer' }}
              onClick={handleChangeGameSettings}>
              settings
            </span>
            <span
              className={'material-symbols-outlined'}
              style={{ cursor: 'pointer' }}
              onClick={handleCopyGame}>
              content_copy
            </span>
            <span
              className={'material-symbols-outlined text-danger'}
              style={{ cursor: 'pointer' }}
              onClick={handleDeleteGame}>
              delete
            </span>
          </Col>
        </Row>
      </div>

      {showDeleteConfirm && (
        <YesNoDialog
          title={'Remove Activity'}
          onConfirm={onConfirmDelete}
          onClose={() => setShowDeleteConfirm(false)}>
          Are you sure you want to delete this activity?
        </YesNoDialog>
      )}
    </>
  );
};
