import { GameDocumentContext } from '../../contexts/game-document';
import { useContext, useEffect, useRef, useState } from 'react';
import Toolbar from './toolbar';
import { Button } from '@progress/kendo-react-buttons';
import {
  AddExistingOverviewResources,
  GenerateGameDocument
} from '../../utils/game-document/factory';
import { ResourceEntity } from '../../types/game-document/entities';
import cloneDeep from 'lodash.clonedeep';
import { Coordinate } from '../../types/place';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { EnvironmentList } from '../../types/environment';
import { GetEnvironmentAsync } from '../../services/environments';
import { LaunchGameAsync } from '../../services/games';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE
} from '../../constants/coordinate';

const GameDiagnostics = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [selectEnvironmentVisible, setSelectEnvironmentVisible] =
    useState<boolean>(false);
  const [coordinate, setCoordinate] = useState<Coordinate>({
    lat: DEFAULT_LATITUDE,
    lng: DEFAULT_LONGITUDE
  });

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let newCoordinate: Coordinate = { ...coordinate };
        if (position.coords.latitude !== undefined) {
          newCoordinate.lat = position.coords.latitude;
        }
        if (position.coords.longitude !== undefined) {
          newCoordinate.lng = position.coords.longitude;
        }
        setCoordinate(newCoordinate);
      });
    }
  };

  const onResetDocument = async () => {
    let updatedGameDocument = await GenerateGameDocument();
    updatedGameDocument.name = state.gameDocument?.name ?? '';
    updatedGameDocument.description = state.gameDocument?.description ?? '';
    updatedGameDocument.keywords = state.gameDocument?.keywords ?? [];
    updatedGameDocument.homepage = state.gameDocument?.homepage ?? '';
    updatedGameDocument.license = state.gameDocument?.license ?? 'UNLICENSED';
    updatedGameDocument.author = state.gameDocument?.author ?? '';

    if (state?.gameDocument?.overview) {
      updatedGameDocument.overview = cloneDeep(state.gameDocument.overview);
    }

    let newResources: ResourceEntity[] = await AddExistingOverviewResources(
      cloneDeep(state?.gameDocument!)
    );

    if (newResources.length > 0) {
      updatedGameDocument.resources = newResources;
    }

    if (
      updatedGameDocument.assets.maps &&
      updatedGameDocument.assets.maps.length > 0
    ) {
      updatedGameDocument.assets.maps[0].latitude = coordinate.lat;
      updatedGameDocument.assets.maps[0].longitude = coordinate.lng;
    }

    setState((state) => ({
      ...state,
      isDirty: true,
      gameDocument: updatedGameDocument
    }));
  };

  const environmentComboBox = useRef<ComboBox>(null);
  const [environments, setEnvironments] = useState<EnvironmentList[]>([]);

  const fetchEnvironments = () => {
    GetEnvironmentAsync('').then((result) => {
      setEnvironments(result.data);
    });
  };

  const toggleLaunchGame = () => {
    setSelectEnvironmentVisible((prevState) => !prevState);
  };

  const onLaunchGame = () => {
    const environmentId = environmentComboBox.current?.value?.id as
      | number
      | undefined;

    if (!environmentId) {
      alert('Please select an environment.');
      return;
    }

    LaunchGameAsync(state.gameId!, environmentId).then((result: string) => {
      const gameUrl = result.replaceAll('"', '');
      window.open(`${gameUrl}`);
      window.open(`${gameUrl.replace('/games/', '/preload/')}`);
      window.open(`${gameUrl}/facilitator`);
    });

    setSelectEnvironmentVisible((prevState) => !prevState);
  };

  useEffect(() => {
    // Set the page title.
    document.title = `Diagnostics - ${state.gameDocument?.name}`;
  }, [state]);

  useEffect(() => {
    getLocation();
    fetchEnvironments();
  }, []);

  return (
    <>
      <Toolbar
        title={'Diagnostics'}
        buttonHelpSupport={{
          title: 'Game testing guide',
          url: 'https://forum.catalystglobal.com/t/4562'
        }}>
        <Button
          onClick={onResetDocument}
          themeColor={'error'}
          fillMode={'flat'}
          title={'Reset to blank game document'}>
          Reset
        </Button>
        <Button
          onClick={toggleLaunchGame}
          themeColor={'error'}
          fillMode={'flat'}
          title={'Launch a test game'}>
          Launch
        </Button>
      </Toolbar>
      <pre style={{ fontSize: '0.7rem' }}>
        {JSON.stringify(state.gameDocument, null, 2)}
      </pre>
      {selectEnvironmentVisible && (
        <Window
          modal={true}
          className={'shadow'}
          initialWidth={500}
          initialHeight={450}
          minimizeButton={undefined}
          maximizeButton={undefined}
          onClose={toggleLaunchGame}
          title={'Select environment'}>
          <p>
            This will launch the latest game document. <br />
            Please make sure you 'Save' first.
          </p>
          <ComboBox
            ref={environmentComboBox}
            data={environments}
            dataItemKey={'id'}
            textField={'name'}
            filterable={true}></ComboBox>
          <WindowActionsBar>
            <Button themeColor={'secondary'} onClick={() => toggleLaunchGame()}>
              Cancel
            </Button>
            <Button
              themeColor={'primary'}
              onClick={() => {
                onLaunchGame();
              }}>
              Launch
            </Button>
          </WindowActionsBar>
        </Window>
      )}
    </>
  );
};

export default GameDiagnostics;
