import React, { useState } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import LogoutButton from './logout-button';
import ProfileDialogWindow from '../dialogs/profile-dialog';
import UserProfile from '../../pages/profile/user-profile';
import { Button } from '@progress/kendo-react-buttons';
import HelpSupport from '../help-support';
// import { useAuth } from 'react-oidc-context';

interface UserMenuProps {
  avatarRef: React.MutableRefObject<null>;
  showUserMenu: boolean;
  showUserWindowByUrl?: boolean;
  activeTabUserWindowByUrl?: AvailableTabs;
  onBlurUserMenuTimeout: Function;
}

type AvailableTabs = 'Profile' | 'Settings' | 'Support' | 'Notifications';

export const UserMenu = (props: UserMenuProps) => {
  // const auth = useAuth();
  const userMenuRef = React.useRef<HTMLDivElement>(null);
  const blurUserMenuRef = React.useRef<NodeJS.Timeout | null>(null);

  // when the user menu [popup] is opened by the avatar, focus the content (user menu)
  const onUserMenuOpen = () => userMenuRef.current?.focus();

  // when the user menu is focused clear the blur timeout.
  const onUserMenuFocus = () =>
    blurUserMenuRef.current && clearTimeout(blurUserMenuRef.current);

  // when the user menu looses focus, set the timout for 200ms.
  const onUserMenuBlur = () => {
    if (blurUserMenuRef.current) {
      clearTimeout(blurUserMenuRef.current);
      blurUserMenuRef.current = setTimeout(onBlurUserMenuTimeout, 200);
    }
  };

  // hide the user menu when the timeout elapses.
  const onBlurUserMenuTimeout = () => props.onBlurUserMenuTimeout();

  // state for the user profile window
  const [profileWindow, setProfileWindow] = useState<{
    visible: boolean;
    activeTab: AvailableTabs;
  }>({ visible: false, activeTab: 'Profile' });

  // show the user profile window on the selected tab (hide the user menu)
  const showProfileWindow = (activeTab: AvailableTabs) => {
    onBlurUserMenuTimeout();
    setProfileWindow({ visible: true, activeTab });
  };

  // hide the user profile window (resets state to the profile tab)
  const hideProfileWindow = () => {
    // auth.signinSilent();
    setProfileWindow({
      visible: false,
      activeTab:
        props.showUserWindowByUrl && props.activeTabUserWindowByUrl
          ? props.activeTabUserWindowByUrl!
          : 'Profile'
    });
  };

  return (
    <>
      <Popup
        anchor={props.avatarRef.current}
        show={props.showUserMenu}
        onOpen={onUserMenuOpen}
        popupClass={'popup-content header__user-menu'}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}>
        <div
          className={'list-group'}
          ref={userMenuRef}
          tabIndex={0}
          onFocus={onUserMenuFocus}
          onBlur={onUserMenuBlur}>
          <Button
            fillMode={'flat'}
            className={
              'list-group-item list-group-item-action justify-content-between'
            }
            onClick={() => showProfileWindow('Profile')}>
            <span className={'material-symbols-outlined'}>account_circle</span>
            <span>Profile</span>
          </Button>
          <Button
            fillMode={'flat'}
            className={
              'list-group-item list-group-item-action justify-content-between'
            }
            onClick={() => showProfileWindow('Settings')}>
            <span className={'material-symbols-outlined'}>settings</span>
            <span>Settings</span>
          </Button>
          <Button
            fillMode={'flat'}
            className={
              'list-group-item list-group-item-action justify-content-between'
            }
            onClick={() => showProfileWindow('Support')}>
            <span className={'material-symbols-outlined'}>support</span>
            <span>Support</span>
          </Button>
          <LogoutButton />
        </div>
      </Popup>

      {profileWindow.visible && (
        <ProfileDialogWindow
          onClose={hideProfileWindow}
          width={'70vw'}
          height={'75vh'}
          className={'profile-window p-0'}>
          <div className={'d-flex w-100 h-100 position-relative'}>
            <div className="position-absolute top-0 end-0 mr-5 z-index-100 d-flex w-100 align-items-center justify-content-end bg-white gap-2 py-2">
              <HelpSupport
                title="My Profile and Logging Out"
                url="https://forum.catalystglobal.com/t/my-profile/"
              />
              <div
                className={
                  'cursor-pointer d-flex align-items-center text-decoration-none'
                }
                onClick={hideProfileWindow}>
                <span className={'material-symbols-outlined'}>close</span>
              </div>
            </div>
            <UserProfile
              onToggleDialog={hideProfileWindow}
              selectedMenuItemName={profileWindow.activeTab}
            />
          </div>
        </ProfileDialogWindow>
      )}
    </>
  );
};

export default UserMenu;
