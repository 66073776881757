import { GameDocumentState } from '../../contexts/game-document';
import {
  NumericTextBox,
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useState } from 'react';
import { UpdateInGameSettingAsync } from '../../utils/game-document/settings';
import { InGame } from '../../constants/settings';
import { GameDocument } from '../../types/game-document';
import HelpSupport from '../help-support';

interface InGameSettingsProps {
  state: GameDocumentState;
  onChange: (gameDocument: GameDocument) => void;
}

const InGameSettings = (props: InGameSettingsProps) => {
  const { state, onChange } = props;
  const [, setIsEnableLeaderboard] = useState<boolean>(false);

  const handleInGameSettingChange = (e: RadioButtonChangeEvent) => {
    const inGameSetting = state.gameDocument?.settings.inGame!;

    UpdateInGameSettingAsync(state.gameDocument!, {
      ...inGameSetting,
      [e.target.element?.name!]: e.value
    }).then((updatedGameDocument) => {
      onChange(updatedGameDocument);
    });

    if (e.target.element?.name === InGame.leaderboard) {
      setIsEnableLeaderboard(e.value);
    }
  };

  return (
    <>
      <span className={`d-flex justify-content-end`}>
        <HelpSupport
          title="Game settings guide"
          url="https://forum.catalystglobal.com/t/4655"
        />
      </span>
      <div
        className={'d-flex flex-column gap-1 align-items-start mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Menu + Functions</h2>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Info</span>
        <RadioButton
          name={InGame.information}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameInfo
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.information}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameInfo
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of info tab in the menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Teams</span>
        <RadioButton
          name={InGame.teams}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeams
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.teams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameTeams
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of teams tab in menu. Teams tab shows the teams in the game
          <br />
          and each team's members.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Create Teams</span>
        <RadioButton
          name={InGame.createTeams}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameCreateTeams
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.createTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameCreateTeams
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Displayed on the team tab. Enables players to create teams.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Join Team/Leave Team</span>
        <RadioButton
          name={InGame.joinLeaveTeams}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameJoinLeaveTeams
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.joinLeaveTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameJoinLeaveTeams
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Displayed on the team tab. Enables players to join or leave a team.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Inventory</span>
        <RadioButton
          name={InGame.inventory}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameInventory
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.inventory}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameInventory
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of inventory tab in the menu. Displays all available
          inventory, <br />
          player inventory and team inventory.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Titles</span>
        <RadioButton
          name={InGame.roles}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameRoles
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.roles}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameRoles
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of titles tab in the menu. Displays all available titles,
          <br />
          player titles and team titles.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Scoreboard</span>
        <RadioButton
          name={InGame.scoreboard}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameScoreboard
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.scoreboard}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameScoreboard
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of scoreboard in the menu. Displays player/team scores and
          leaderboard.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Player/Team score</span>
        <RadioButton
          name={InGame.playerTeamScore}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGamePlayerTeamScore
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.playerTeamScore}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGamePlayerTeamScore
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of the players score on scoreboard. In a team game,
          <br />
          displays the team's score and each team member's score.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Leaderboard</span>
        <RadioButton
          name={InGame.leaderboard}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameLeaderboard
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.leaderboard}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameLeaderboard
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of leaderboard with ranking, names and scores of all
          players/teams <br /> on the scoreboard tab.
        </span>
      </div>

      {/* <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Team chat</span>
        <RadioButton
          name={InGame.teamChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeamChat === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.teamChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeamChat === false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Ability of teams to message other teams.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>All players chat</span>
        <RadioButton
          name={InGame.allPlayerChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameAllPlayerChat === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.allPlayerChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameAllPlayerChat ===
              false
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Ability of players/teams to message all players in the game.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Direct chat</span>
        <RadioButton
          name={InGame.directChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameDirectChat === true
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.directChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameDirectChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Ability of players to message direct to other players in the game.
        </span>
      </div> */}

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Chat</span>
        <RadioButton
          name={InGame.chats}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.chats}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of chat in the menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Facilitator Chat</span>
        <RadioButton
          name={InGame.facilitatorChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameFacilitatorChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.facilitatorChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameFacilitatorChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message the game controller.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>All Player Chat</span>
        <RadioButton
          name={InGame.allPlayerChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameAllPlayerChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.allPlayerChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameAllPlayerChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in the game.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Direct Chat</span>
        <RadioButton
          name={InGame.directChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameDirectChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.directChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameDirectChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message another player.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Team Chat</span>
        <RadioButton
          name={InGame.teamChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTeamChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.teamChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameTeamChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in a team.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Group Chat</span>
        <RadioButton
          name={InGame.groupChat}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameGroupChat
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.groupChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameGroupChat
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in a group.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Trade</span>
        <RadioButton
          name={InGame.trade}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showInGameTrade
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.trade}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showInGameTrade
          }
          onChange={handleInGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to trade with another player.
        </span>
      </div>

      {/* chat limit */}

      <div
        className={'d-flex flex-column gap-1 align-items-start mt-4 mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Tasks</h2>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Tasks require proximity</span>
        <RadioButton
          name={InGame.tasksRequireProximity}
          value={true}
          label={'True'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.tasksRequireProximity
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.tasksRequireProximity}
          value={false}
          label={'False'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument.settings.inGame.tasksRequireProximity
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Player device must be within the set proximity before the task is
          active.
        </span>
      </div>

      <div>
        <div className={'d-flex gap-1 align-items-center mt-2 w-100'}>
          <span className={'mr-10 w-30'}>Set task proximity</span>
          <NumericTextBox
            name={InGame.setTaskProximity}
            className={'mr-1 w-15'}
            value={state?.gameDocument?.settings.inGame.setTaskProximity ?? 0}
            onChange={handleInGameSettingChange}
            min={0}
          />
          <span className={'w-30'}>Meters</span>
        </div>

        <div className={'d-flex gap-1 align-items-center mb-2'}>
          <span className={'mb-2 fs-6 text-black-50'}>
            <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
            Default task proximity is 3 - 10 metres. Depending on the GPS
            signal. <br />
            Change the default proximity for all tasks.
          </span>
        </div>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Pre-message text</span>
        <RadioButton
          name={InGame.premessage}
          value={true}
          label={'True'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.enablePremessageOutsideRange
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.premessage}
          value={false}
          label={'False'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument.settings.inGame.enablePremessageOutsideRange
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enable pre-message text when not in range.
        </span>
      </div>

      <div
        className={'d-flex flex-column gap-1 align-items-start mt-4 mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Map Display</h2>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Task title visibility</span>
        <RadioButton
          name={InGame.showTasknameVisibility}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument.settings.inGame.showTasknameVisibility
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showTasknameVisibility}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument.settings.inGame.showTasknameVisibility
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of the Task Title alongside the task icon.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Other players/teams</span>
        <RadioButton
          name={InGame.otherPlayerOrTeam}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showOtherPlayerOrTeamOnMap
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.otherPlayerOrTeam}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showOtherPlayerOrTeamOnMap
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of other players/teams on the map.{' '}
          <i>Note: A player's own icon is always visible.</i>
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Player score</span>
        <RadioButton
          name={InGame.showPlayerScoreOnMap}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerScoreOnMap
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showPlayerScoreOnMap}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showPlayerScoreOnMap
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          The player's score is displayed, beside the player icon.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Show detail on click</span>
        <RadioButton
          name={InGame.showPlayerDetailByClickOnMap}
          value={true}
          label={'On'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.inGame.showPlayerDetailByClickOnMap
          }
          onChange={handleInGameSettingChange}
        />

        <RadioButton
          name={InGame.showPlayerDetailByClickOnMap}
          value={false}
          label={'Off'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            !state.gameDocument?.settings.inGame.showPlayerDetailByClickOnMap
          }
          onChange={handleInGameSettingChange}
        />
      </div>

      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          When true, player icon is always on display. The icon is clicked to
          show, player name, <br />
          score, and team name. When false, player detail is always shown beside
          the player icon.
        </span>
      </div>
    </>
  );
};

export default InGameSettings;
