import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

import MenuWrapper from './menu-wrapper';
import MenuItem from '../components/menu-item';
import { LayoutContext } from '../contexts/layout-provider';

/**
 * The menu used for the game designer area layouts.
 */
const GameDesignerMenu: React.FC<{ classname?: string }> = ({
  classname = ''
}) => {
  const { gameId } = useParams();
  const baseDesignerUrl = `/designer/${gameId}`;
  const layout = useContext(LayoutContext);

  layout.setDrawerMobileMenu(
    <GameDesignerMenu classname={'app-side-nav--mobile-drawer'} />
  );

  return (
    <MenuWrapper classname={classname}>
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/dashboard'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={`${baseDesignerUrl}/`} icon={'draw'} label={'Overview'} />
        <MenuItem to={`${baseDesignerUrl}/map`} icon={'map'} label={'Map'} end={false} />
        <MenuItem to={`${baseDesignerUrl}/assets/inventory`} icon={'category'} label={'Assets'} expanded={true}>
         {/** temporary hide related ticket #4887 <MenuItem to={'assets/assessments'} icon={'fact_check'} label={'Assessments'} end={false} iconSet={'light'} /> */} 
          <MenuItem to={`${baseDesignerUrl}/assets/task-content`} icon={'checklist'} label={'Task Content'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/tasks`} icon={'checklist'} label={'Tasks'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/inventory`} icon={'inventory_2'} label={'Inventory'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/titles`} icon={'military_tech'} label={'Titles'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/timers`} icon={'timer'} label={'Timers'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/maps`} icon={'map'} label={'Maps'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/zones`} icon={'activity_zone'} label={'Zones'} end={false} iconSet={'outlined'} />
          <MenuItem to={`${baseDesignerUrl}/assets/areas`} icon={'flag'} label={'Areas'} end={false} iconSet={'outlined'} />
         </MenuItem>
        <MenuItem to={`${baseDesignerUrl}/resources/resource`} icon={'perm_media'} label={'Resource'} />
        {/* regarding task 5957 Hide menu assessment on designer */}
        {/* <MenuItem to={'assessment'} icon={'task'} label={'Assessment'} /> */}
        <MenuItem to={`${baseDesignerUrl}/resources/theme`} icon={'palette'} label={'Theme'} />
        <MenuItem to={`${baseDesignerUrl}/resources/language`} icon={'translate'} label={'Language'} />
        <MenuItem to={`${baseDesignerUrl}/settings`} icon={'settings'} label={'Settings'} />
        <MenuItem to={`${baseDesignerUrl}/sales`} icon={'newspaper'} label={'Sales and Marketing'} />
        <MenuItem to={`${baseDesignerUrl}/diagnostics`} icon={'data_object'} label={'Diagnostics'} />
      </div>
    </MenuWrapper>
  );
};

export default GameDesignerMenu;
