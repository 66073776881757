import React, { JSX, useCallback, useEffect, useState } from 'react';
import {
  DataItem,
  DayView,
  MonthView,
  Scheduler,
  SchedulerItem,
  SchedulerItemHandle,
  SchedulerItemMouseEvent,
  SchedulerItemProps,
  WeekView
} from '@progress/kendo-react-scheduler';
import { DataResult } from '@progress/kendo-data-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import EventsView from './events-view';

interface SchedulerProps {
  eventData: DataResult;
}

const CustomItem =
  (onClickHandler: ((event: SchedulerItemMouseEvent) => void) | undefined) =>
  (
    props: JSX.IntrinsicAttributes &
      SchedulerItemProps &
      React.RefAttributes<SchedulerItemHandle>
  ) => <SchedulerItem {...props} onClick={onClickHandler} />;

const schedulerModel = {
  id: 'id',
  title: 'name',
  start: 'startDateUtc',
  end: 'endDateUtc'
};

const isAllDay = (startDateUtc: string, endDateUtc: string) => {
  const msBetweenDates = Math.abs(
    new Date(startDateUtc).getTime() - new Date(endDateUtc).getTime()
  );
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
  return hoursBetweenDates >= 24 ? true : false;
};

const EventsScheduler = ({ eventData }: SchedulerProps) => {
  const [view, setView] = useState<string>('day');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<DataItem[]>();
  const [selectedId, setSelectedId] = useState<number>(0);
  const handleViewChange = useCallback(
    (event: { value: React.SetStateAction<string> }) => {
      setView(event.value);
    },
    [setView]
  );

  const toggleDialog = () => {
    setShowModal(!showModal);
  };

  const onClickHandler = useCallback(
    (e: SchedulerItemMouseEvent) => {
      setSelectedId(e.target.props.dataItem.id);
      setShowModal(!showModal);
    },
    [showModal]
  );

  useEffect(() => {
    // remove data that didnt have start/end date, than transform to scheduler data model
    const eventDataScheduler = eventData.data
      .filter((dataItem) => {
        if (!dataItem.startDateUtc || !dataItem.endDateUtc) return false;
        return true;
      })
      .map((dataItem) => {
        return {
          ...dataItem,
          startDate: dataItem.startDateUtc,
          endDate: dataItem.endDateUtc,
          isAllDay: isAllDay(dataItem.startDateUtc, dataItem.endDateUtc)
        };
      }) as any;
    setData(eventDataScheduler);
  }, [eventData.data]);

  return (
    <>
      <Scheduler
        data={data}
        view={view}
        onViewChange={handleViewChange}
        editable={{
          select: true,
          remove: false,
          drag: false,
          resize: false,
          add: false,
          edit: false
        }}
        item={CustomItem(onClickHandler)}
        modelFields={schedulerModel}>
        <DayView />
        <WeekView />
        <MonthView />
      </Scheduler>

      {showModal && (
        <Dialog
          title={'Events Details'}
          onClose={toggleDialog}
          width={'60%'}
          height={'90%'}>
          <EventsView
            id={selectedId.toString()}
            onBackButtonClick={toggleDialog}
          />
        </Dialog>
      )}
    </>
  );
};
export default EventsScheduler;
