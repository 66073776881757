import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { ColumnMenu } from '../components/columnMenu';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import { ActionCell } from '../components/grid/actions-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import {
  getGlobalDocumentLatestAsync,
  getGlobalFeedback,
  publishFeedbackAsync
} from '../services/feedback';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { CustomFilterLanguage } from './filter/custom-filter-language';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { NoRecords } from '../components/grid/no-records';
import { YesNoDialogResult } from '../types/dialog-result';
import { FeedbackApproval } from '../types/feedback';
import { FeedbackManagementCardView } from './feedbacks-management-cardview';
import { formatDateToDDMMYYYY } from '../utils/date';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const FeedbacksManagementGrid = () => {
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [feedbacks, setFeedbacks] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchFeedbacks = async () => {
    try {
      appStore.showLoading();

      const response = await getGlobalFeedback(
        `${toDataSourceRequestString(dataState)}`
      );
      setFeedbacks(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchFeedbacks();
    //eslint-disable-next-line
  }, [dataState]);

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionCell
          title={'Delete feedback'}
          icon={'delete'}
          themeColor={'error'}
          className={'fs-2'}
          onClick={() => onDelete(props.dataItem.id)}
        />
      </>
    );
  };

  const onDelete = (id: number) => {
    onDeleteFeedback(id);
  };

  const onDeleteFeedback = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const unpublishFeedback = async (feedbackId: number) => {
    // Get latest document
    let latestDocument = await getGlobalDocumentLatestAsync(feedbackId);

    // Patch the document status
    let request: FeedbackApproval = {
      fileName: latestDocument?.fileName ?? '',
      version: latestDocument?.version ?? '',
      status: 'New',
      isGlobal: false,
      isDeleted: false
    };
    await publishFeedbackAsync(feedbackId!, latestDocument.id, request);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await unpublishFeedback(selectedId);
        fetchFeedbacks();

        toastStore.show(
          'Global library feedbacks',
          <div>Feedback deleted.</div>,
          'success'
        );
      } catch {
        toastStore.show(
          'Global library feedbacks',
          <div>Failed to delete feedback.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        {formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Global Feedback'}></Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search feedback'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={feedbacks.data}
          ref={gridExportRef}
          fileName={`feedback.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-feedback'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={feedbacks}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
              <Column
                field={'createdDateUtc'}
                title={'Created'}
                cell={DateCell}
              />
              <Column
                field={'version'}
                title={'Version'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'language'}
                title={'Languages'}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              <Column
                field={'organisation'}
                title={'Organisation Name'}
                columnMenu={ColumnMenu}
              />

              <Column title={'Actions'} cell={onActionRow} width={120} />

              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}

          {gridMode === 'card' && (
            <FeedbackManagementCardView
              data={feedbacks.data}
              total={feedbacks.total}
              onDelete={onDelete}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>

        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove feedback'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this feedback from Global Library?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default FeedbacksManagementGrid;
