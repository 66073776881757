import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  GetResourceById,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { TaskContentEntity } from '../../../types/game-document/';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  AddTaskContentAsync,
  CopyTaskContentAsync,
  DeleteTaskContentAsync,
  ExportTaskContents,
  ImportTaskContents,
  UpdateTaskContentAsync
} from '../../../utils/game-document/assets/task-contents';
import { TaskContentEditorWindow } from '../../../features/game-document/task-contents';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useSessionStorage } from 'usehooks-ts';
import { downloadJSON, handleFileUpload } from '../../../utils/files';
import { toastStore } from '../../../stores/toast-store';
import { ExportedTaskContentData } from '../../../types/game-document/entities/exported-task-content';
import { getCurrentTimeStamp } from '../../../utils/date';
import { isContentHtmlEmpty } from '../../../utils/game-document/html-content';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

interface TaskContentGridEntity extends TaskContentEntity {
  selected: boolean;
}

const SELECTED_FIELD = 'selected';

const TaskContents = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-task-contents',
    initialDataState
  );

  const [filteredTaskContent, setFilteredTaskContent] = useState<
    TaskContentGridEntity[] | undefined
  >([]);

  const [selectedTaskContentIds, setSelectecTaskContentIds] = useState<
    string[]
  >([]);

  const [searchText, setSearchText] = useSessionStorage<string>(
    'searchstate-task-contents',
    ''
  );

  useEffect(() => {
    // Set the page task.
    document.title = `Task Content - ${state.gameDocument?.name}`;
    const taskContents = state.gameDocument?.assets?.taskContents!.filter(
      (x) => x.id !== '-'
    ) as TaskContentGridEntity[] | undefined;

    setFilteredTaskContent(taskContents);
  }, [state]);

  const handleJsonExport = async () => {
    if (selectedTaskContentIds.length === 0) {
      toastStore.show(
        'Error',
        'Please select the task content you want to export first.',
        'error'
      );
      return;
    }
    const exportedTaskContents = ExportTaskContents(
      state.gameDocument!,
      selectedTaskContentIds
    );
    const formattedTimestamp = getCurrentTimeStamp();

    downloadJSON(
      exportedTaskContents,
      `task-content-${formattedTimestamp}.json`
    );
  };

  const handleJsonImport = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFileUpload<ExportedTaskContentData>(
      event,
      async (exportedTaskContent) => {
        ImportTaskContents(state.gameDocument!, exportedTaskContent).then(
          (updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            toastStore.show('Info', 'Import Success', 'info');
          }
        );
      },
      (message) => {
        toastStore.show('Error', message, 'error');
      }
    );
  };

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props} />
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const DescriptionCell = (props: GridCellProps) => {
    const descriptions = props.dataItem?.description;
    if (descriptions.length <= 250) {
      return (
        <td role={'gridcell'} className={'k-table-td'}>
          {descriptions}
        </td>
      );
    }
    return (
      <td title={descriptions} role={'gridcell'} className={'k-table-td'}>
        {descriptions.slice(0, 250)}...
      </td>
    );
  };

  const PremessageCell = (props: GridCellProps) => {
    const value = GetResourceById(
      state.gameDocument!,
      props.dataItem.preMessageResId
    )?.value;
    return (
      <td className={'k-table-td'}>
        {isContentHtmlEmpty(value!) ? 'No' : 'Yes'}
      </td>
    );
  };

  const IntroCell = (props: GridCellProps) => {
    const value = GetResourceById(
      state.gameDocument!,
      props.dataItem.contentResId
    )?.value;
    return (
      <td className={'k-table-td'}>
        {isContentHtmlEmpty(value!) ? 'No' : 'Yes'}
      </td>
    );
  };

  const FormCell = (props: GridCellProps) => {
    const isExistingForms = props.dataItem?.forms?.length > 0 ? 'Yes' : 'No';
    return (
      <td role={'gridcell'} className={'k-table-td'}>
        {isExistingForms}
      </td>
    );
  };

  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TaskContentEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = async (
    editorEntity: EntityEditor<TaskContentEntity>
  ) => {
    if (editorEntity.isNew) {
      const titleGuid = uuid(); //titleResId
      const preMessageGuid = uuid(); //preMessageResId
      const contentGuid = uuid(); //contentResId
      const taskContentId = uuid();

      const addTitleResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        '',
        titleGuid
      );
      const addContentResource = AddResourceAsync(
        state.gameDocument!,
        'Content',
        '',
        'text-md',
        '',
        contentGuid
      );
      const addPreMessageResource = AddResourceAsync(
        state.gameDocument!,
        'PreMessage',
        '',
        'text-md',
        '',
        preMessageGuid
      );
      Promise.all([
        addTitleResource,
        addContentResource,
        addPreMessageResource
      ]).then(() => {
        AddTaskContentAsync(
          state.gameDocument!,
          editorEntity.entity.name,
          editorEntity.entity.description,
          editorEntity.entity.bodyType!,
          titleGuid,
          preMessageGuid,
          contentGuid,
          taskContentId
        )
          .then((updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            setEntityEditorIsVisible(false);
          })
          .finally(() => {
            window.location.replace(
              `/designer/${state.gameId}/assets/task-content/${taskContentId}`
            );
          });
      });
    } else {
      UpdateTaskContentAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    }
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    setEntityEditorValue({
      isNew: false,
      entity: state.gameDocument?.assets.taskContents?.find(
        (i) => i.id === entityId
      )!
    });
    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyTaskContentAsync(state?.gameDocument!, entityId).then((response) => {
      setState((state) => UpdateGameDocState(state, response));
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTaskContentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const selectedTaskContentId = event.dataItem['id'];

    const updatedTaskContents = filteredTaskContent?.map((item) =>
      item.id === selectedTaskContentId
        ? { ...item, selected: !item.selected }
        : item
    );
    setFilteredTaskContent(updatedTaskContents);

    const taskContent = updatedTaskContents?.find(
      (item) => item.id === selectedTaskContentId
    );

    if (taskContent) {
      const updatedSelectedTaskContentIds = taskContent.selected
        ? [...selectedTaskContentIds, selectedTaskContentId]
        : selectedTaskContentIds.filter((id) => id !== selectedTaskContentId);

      setSelectecTaskContentIds(updatedSelectedTaskContentIds);
    }
  };

  const onHeaderSelectionChange = () => {
    const updatedTaskContents = filteredTaskContent?.map((item) => ({
      ...item,
      selected: !item.selected
    }));

    const updatedSelectedTaskContentIds = updatedTaskContents?.reduce<string[]>(
      (ids, item) => {
        if (item.selected) {
          return [...ids, item.id];
        } else {
          return ids.filter((id) => id !== item.id);
        }
      },
      [...(selectedTaskContentIds || [])]
    );

    setSelectecTaskContentIds(updatedSelectedTaskContentIds || []);
    setFilteredTaskContent(updatedTaskContents);
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar
        title={'Task Content'}
        buttonHelpSupport={{
          title: 'Task action & events guide',
          url: 'https://forum.catalystglobal.com/t/4529'
        }}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add task content
        </Button>
        {entityEditorIsVisible && (
          <TaskContentEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search task content'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          showJsonExport={true}
          handleJsonExport={handleJsonExport}
          showJsonImport={true}
          handleJsonImport={handleJsonImport}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
          searchTerm={searchText}
          onSearchChange={(searchTerm) => {
            setSearchText(searchTerm);
          }}
        />
        <Tooltip openDelay={300} position={'right'} anchorElement={'target'}>
          <ExcelExport
            data={filteredTaskContent ?? []}
            ref={gridExportRef}
            fileName={`${state.gameDocument?.name} task contents.xlsx`}>
            <Grid
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              className={'cg-grid3'}
              selectedField={SELECTED_FIELD}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              data={process(filteredTaskContent ?? [], dataState)}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column filterable={false} field={SELECTED_FIELD} width={50} />
              <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
              <Column
                field={'description'}
                title={'Description'}
                cell={DescriptionCell}
              />
              <Column title={'PreMessage'} cell={PremessageCell} />
              <Column title={'Intro'} cell={IntroCell} />
              <Column field={'forms'} title={'Form'} cell={FormCell} />
              <Column cell={DeleteCell} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          </ExcelExport>
        </Tooltip>
      </div>
    </>
  );
};

export default TaskContents;
