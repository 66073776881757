import React, { useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../../contexts/game-document';
import Toolbar from '../toolbar';
import {
  AddAreaAsync,
  DeleteAreaAsync,
  UpdateAreaAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { EditCell, LinkCell } from '../../../components/grid';
import { AreaEntity } from '../../../types/game-document/';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { AreaEditorWindow } from '../../../features/game-document/areas';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { ColumnMenu } from '../../../components/columnMenu';
import { useSessionStorage } from 'usehooks-ts';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Areas = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-areas',
    initialDataState
  );
  // comment for Ticket - 4884
  // const entity: AreaEntity = {
  //   id: '',
  //   name: '',
  //   description: ''
  // };

  useEffect(() => {
    // Set the page zone.
    document.title = `Areas - ${state.gameDocument?.name}`;
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<AreaEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (editorEntity: EntityEditor<AreaEntity>) => {
    if (editorEntity.isNew) {
      const {
        entity: { name, description }
      } = editorEntity;
      AddAreaAsync(state.gameDocument!, name, description).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntityEditorIsVisible(false);
        }
      );
    } else {
      UpdateAreaAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    }
  };

  // comment for Ticket - 4884
  // const onAddEntity = () => {
  //   setEntityEditorValue({
  //     isNew: true,
  //     entity
  //   });
  //   toggleEntityEditor();
  // };

  const onEditEntity = (entityId: string) => {
    const editedEntity = state.gameDocument?.assets.areas?.find(
      (i) => i.id === entityId
    )!;

    setEntityEditorValue({
      isNew: false,
      entity: editedEntity
    });

    toggleEntityEditor();
  };

  // comment for Ticket - 4884
  // const onCloneEntity = (entityId: string) => {
  //   CopyAreaAsync(state.gameDocument!, entityId).then((updatedGameDocument) => {
  //     setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
  //   });
  // };

  const onDeleteEntity = async (entityId: string) => {
    DeleteAreaAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar
        title={'Areas'}
        buttonHelpSupport={{
          title: 'Area assets guide',
          url: 'https://forum.catalystglobal.com/t/4589'
        }}>
        {/* comment for Ticket - 4884 */}
        {/* <Button onClick={onAddEntity} themeColor={'primary'}>
          Add area
        </Button> */}
        {entityEditorIsVisible && (
          <AreaEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search areas'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
        />
        <ExcelExport
          data={state.gameDocument?.assets.areas ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} areas.xlsx`}>
          <Grid
            pageable={{ info: true, pageSizes: [20, 50, 100] }}
            pageSize={20}
            sortable={true}
            className={'cg-grid3'}
            data={process(state.gameDocument?.assets.areas ?? [], dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'name'}
              title={'Name'}
              cell={LinkedNameCell}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'description'}
              title={'Description'}
              columnMenu={ColumnMenu}
            />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Areas;
