import Avatar from '../avatar';
import { GameDocument } from '../../types/game-document';
import { GetTotalGameSize } from '../../utils/game-document';

type Props = {
  author?: string;
  authorAvatar?: string;
  createdDate?: string;
  updateDate?: string;
  version?: string;
  gameDocument?: GameDocument;
  isGameOverview?: boolean;
};

const Summary = ({
  author,
  authorAvatar,
  createdDate,
  updateDate,
  version,
  gameDocument,
  isGameOverview = false
}: Props) => {
  const calculateJsonSize = (obj: GameDocument): number => {
    const totalGameSize = GetTotalGameSize(gameDocument!);
    return totalGameSize / 1024; //convert bytes to KB
  };

  return (
    <div className={'flex-grow-1'}>
      <div className={'sticky-top z-index-1'}>
        <div
          className={
            'author-card border border-1 bg-light p-2 rounded d-inline-block'
          }>
          <h6>Author</h6>
          {authorAvatar && <Avatar name={author} img={authorAvatar} />}

          {!authorAvatar && (
            <div className={'d-flex flex-items-center gap-2 mt-2 mb-2'}>
              <span
                className={'material-symbols-outlined'}
                style={{ fontSize: '36px' }}>
                account_circle
              </span>
              <span>{author}</span>
            </div>
          )}

          <div className={'d-flex flex-column mt-4'}>
            <h6>Created:</h6>
            <span>{createdDate}</span>
          </div>

          <div className={'d-flex flex-column mt-4'}>
            <h6>Last updated:</h6>
            <span>{updateDate}</span>
          </div>
          {isGameOverview && (
            <div className={'d-flex flex-column mt-4'}>
              <h6>Game Size:</h6>
              <span>
                {gameDocument ? calculateJsonSize(gameDocument).toFixed(2) : 0}{' '}
                KB
              </span>
            </div>
          )}

          <div className={'d-flex flex-column mt-4'}>
            <h6>Version:</h6>
            <code>v{version}</code>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
