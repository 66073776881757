import { useEffect, useRef, useState, useCallback } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { toastStore } from '../stores/toast-store';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { appStore } from '../stores/app-store';
import { Window } from '@progress/kendo-react-dialogs';
import { Error, Label } from '@progress/kendo-react-labels';
import LanguageComboBox from '../components/combobox/language-combobox';
import { StandardInput } from '../components/forms';
import { Button } from '@progress/kendo-react-buttons';
import {
  AdminAddDisplayLanguageAsync,
  AdminAddDisplayLanguageDocumentAsync,
  AdminUpdateDisplayLanguageContentAsync,
  GetAllAdminDisplayLanguageAsync
} from '../services/admin-display-language';
import { GetLanguagesAsync } from '../services/country';
import { CountryResponse } from '../types/responses/country-response';
import { useNavigate } from 'react-router-dom';

const initialDataState: State = {
  sort: [{ field: 'languageName', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const LanguagesManagementGrid = () => {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [libraries, setLibraries] = useState<DataResult>({
    data: [],
    total: 0
  });
  const [addLanguage, setAddLanguage] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [languageDescription, setLanguageDescription] = useState<string>('');
  const [countries, setCountries] = useState<CountryResponse[]>([]);
  const gridExportRef = useRef<ExcelExport | null>(null);
  const [isLanguageDuplicate, setIsLanguageDuplicate] = useState(false);

  const fetchLibrary = useCallback(async () => {
    try {
      appStore.showLoading();
      const result = await GetAllAdminDisplayLanguageAsync(dataState);
      setLibraries(result);
    } catch (error) {
      console.error('Failed to fetch display languages:', error);
    } finally {
      appStore.hideLoading();
    }
  }, [dataState]);

  const getCountries = useCallback(async () => {
    try {
      const result = await GetLanguagesAsync();
      setCountries(result?.data ?? []);
    } catch (error) {
      console.error('Failed to fetch countries:', error);
    }
  }, []);

  const onColumnTextClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const handleTitleClick = (id: number) => {
    navigate('/administration/languages/manage/' + id);
  };

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        <span
          className={'link-primary cursor-pointer'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.languageName}
        </span>
      </td>
    );
  };

  useEffect(() => {
    fetchLibrary();
  }, [dataState, fetchLibrary]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const onSubmit = useCallback(async () => {
    try {
      appStore.showLoading();

      const countryISO = countries.find(
        (x) => x.language === selectedLanguage
      )?.countryIso;

      if (!countryISO) return;

      const language = await AdminAddDisplayLanguageAsync({
        languageCode: countryISO,
        description: languageDescription
      });

      const document = await AdminAddDisplayLanguageDocumentAsync(language.id, {
        documentStatus: 'New',
        version: 'v.0.0.1',
        fileName: `display-language-${language.id}-v.0.0.1.json`
      });

      await AdminUpdateDisplayLanguageContentAsync(language.id, document.id, {
        languageCode: countryISO,
        description: languageDescription,
        resources: []
      });

      setAddLanguage(false);
      toastStore.show(
        'Display language',
        <div>Language added successfully.</div>,
        'success'
      );

      fetchLibrary();
    } catch (error) {
      appStore.hideLoading();
      console.error('Failed to add language:', error);
      toastStore.show(
        'Display language',
        <div>Failed to add language.</div>,
        'error'
      );
    }
  }, [selectedLanguage, languageDescription, countries, fetchLibrary]);

  const handleDataStateChange = (
    e: GridDataStateChangeEvent | GridToolbarDataStateChangeEvent
  ) => {
    const newState = {
      ...e.dataState,
      filter: {
        logic: 'and',
        filters: [e.dataState.filter!]
      }
    } as State;
    setDataState(newState);
  };

  return (
    <>
      <Toolbar title="Global languages">
        <Button onClick={() => setAddLanguage(true)} themeColor="primary">
          Add new
        </Button>
      </Toolbar>
      <div className="pt-2">
        <GridToolBar
          showCardMode={false}
          searchPlaceholder="Search global languages"
          columnsToSearch={['languageName', 'description']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => setGridMode(e.gridMode)}
          {...dataState}
          onDataStateChange={handleDataStateChange}
        />
        <ExcelExport
          data={libraries.data}
          ref={gridExportRef}
          fileName="libraries.xlsx">
          {gridMode === 'grid' && (
            <Grid
              id="grid-library"
              pageable={DefaultGridSettings.pagerSettings}
              sortable
              data={libraries}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field="languageName"
                title="Title"
                cell={onColumnTextClick}
              />
              <Column
                field="description"
                title="Description"
                columnMenu={ColumnMenu}
              />
              <Column
                field="createdDateUtc"
                title="Date Created"
                format="{0:g}"
              />
              <Column field="version" title="Version" />
              <Column field="status" title="Status" />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
        </ExcelExport>
      </div>
      {addLanguage && (
        <Window
          width={400}
          onClose={() => setAddLanguage(false)}
          title="New global languages">
          <div>
            <Label>Language title</Label>
            <LanguageComboBox
              name="language"
              onChange={(v) => {
                const value: string = v?.target?.value;
                const findExistingLanguage = libraries.data
                  .map((item) => item.languageName)
                  .find((i: string) => i.toLowerCase() === value.toLowerCase());

                if (!findExistingLanguage) {
                  setIsLanguageDuplicate(false);
                } else {
                  setIsLanguageDuplicate(true);
                }
                setSelectedLanguage(value);
              }}
            />
            {isLanguageDuplicate && (
              <div className={'mt-1'}>
                <Error>
                  The selected language has already been added, please choose
                  another.
                </Error>
              </div>
            )}
          </div>
          <div>
            <StandardInput
              name={'description'}
              label={'Description'}
              onChange={(e) =>
                setLanguageDescription((e?.target?.value as string) ?? '')
              }
            />
          </div>
          <div className={'d-flex justify-content-end mt-2'}>
            <Button
              disabled={isLanguageDuplicate}
              onClick={onSubmit}
              themeColor={'primary'}>
              Confirm
            </Button>
          </div>
        </Window>
      )}
    </>
  );
};

export default LanguagesManagementGrid;
