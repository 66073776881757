import { useToggle, useMediaQuery } from 'usehooks-ts';
import { createContext, PropsWithChildren, useMemo, useState } from 'react';

import DrawerMobileContainer from '../layouts/mobile-drawer/container';

export interface LayoutProviderState {
  isMobile: boolean;
  navIsCollapsed: boolean;
  toggleNavIsCollapsed: () => void;
  drawerMobileMenu: React.ReactNode;
  setDrawerMobileMenu: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

const LayoutContext = createContext<LayoutProviderState>({
  isMobile: false,
  navIsCollapsed: true,
  toggleNavIsCollapsed: () => {},
  drawerMobileMenu: null,
  setDrawerMobileMenu: () => {}
});

/**
 * Creates a layout provider component that manages the state of the layout.
 * @param {PropsWithChildren} props - The properties passed to the component.
 * @returns {ReactElement} The rendered layout provider component.
 */
const LayoutProvider = (props: PropsWithChildren) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [navIsCollapsed, toggleNavIsCollapsed] = useToggle(isMobile);
  const [drawerMobileMenu, setDrawerMobileMenu] =
    useState<React.ReactNode>(null);

  const value = useMemo(
    () => ({
      isMobile,
      navIsCollapsed,
      drawerMobileMenu,
      setDrawerMobileMenu,
      toggleNavIsCollapsed
    }),
    [isMobile, navIsCollapsed]
  );

  return (
    <LayoutContext.Provider value={value}>
      {isMobile ? (
        <DrawerMobileContainer>{props.children}</DrawerMobileContainer>
      ) : (
        props.children
      )}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
