import { useEffect, useState } from 'react';
import DashboardCardView from './dashboard-cardview';
import { useNavigate } from 'react-router-dom';
import { DefaultGridSettings } from '../constants/grid-settings';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { GetEvents } from '../services/events';
import { getAssessments } from '../services/assessment';
import { GetGamesAsync, GetGlobalGamesAsync } from '../services/games';
import { appStore } from '../stores/app-store';
import { Permission } from '../types/permission';
import { useAuth } from 'react-oidc-context';
import { isEmpty } from 'lodash';
import { getFeedback } from '../services/feedback';
import { KendoGridResult } from '../types/KendoGridResult';

const supportData = [
  {
    name: 'Getting Started',
    url: 'https://forum.catalystglobal.com/c/catalyst-games',
    isOpenInNewTab: true
  },
  {
    name: 'Submit a support ticket',
    url: 'showtype=user-support',
    isShowPopup: true
  },
  {
    name: 'Manage Notifications',
    url: 'showtype=user-notifications',
    isShowPopup: true
  },
  {
    name: 'FAQ',
    url: 'https://forum.catalystglobal.com/t/4463',
    isOpenInNewTab: true
  }
];

const usefulLinkData = [
  {
    name: 'Forum',
    url: 'https://forum.catalystglobal.com/',
    isOpenInNewTab: true
  },
  {
    name: 'The Big Picture',
    url: 'https://bpo.catalystglobal.com/',
    isOpenInNewTab: true
  },
  {
    name: 'Catalyst Website CMS',
    url: 'https://cms-admin.catalystglobal.com/',
    isOpenInNewTab: true
  },
  {
    name: 'Need4Speed',
    url: 'https://need4speed.catalystglobal.com/games',
    isOpenInNewTab: true
  },
  {
    name: 'Peak Performance (USA)',
    url: 'https://peakperformance.games/site/login',
    isOpenInNewTab: true
  },
  {
    name: 'Peak Performance (SG)',
    url: 'https://sgp.peakperformance.games/site/login',
    isOpenInNewTab: true
  },
  {
    name: 'Quickfire',
    url: 'https://manage.quickfire.games/site/login',
    isOpenInNewTab: true
  },
  {
    name: 'The Infinite Loop',
    url: 'https://partnersinterface.com/',
    isOpenInNewTab: true
  }
];

const initialDataEventState: State = {
  sort: [{ field: 'id', dir: 'desc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};

export interface newDataModified {
  id?: number;
  name: string;
  date?: Date;
  url?: string;
  isOpenInNewTab?: boolean;
}
export interface CardPropertiesState {
  position: string;
  isVisible: boolean;
  title: string;
  descriptions?: string;
  icon?: string;
  data?: any[];
  buttonTitle?: string;
  buttonHandler?: () => void;
  fetcher?: Promise<KendoGridResult<any>>;
  permission?: Permission;
  type?: string;
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );

  const key = Object.keys(Permission)[indexOfS];

  return key;
};

const DashboardContent = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [dataState] = useState<State>({
    ...initialDataEventState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [supports] = useState<newDataModified[]>(supportData);
  const [usefulLinks] = useState<newDataModified[]>(usefulLinkData);

  const isInRole = (requiredRole: string) => {
    const role = getPermissionKey(requiredRole!);

    return !isEmpty(requiredRole)
      ? (auth.user?.profile.role as string[]).includes(role!)
      : true;
  };

  const cardProperties: CardPropertiesState[] = [
    {
      position: '1',
      isVisible: isInRole(Permission.Events),
      title: 'My events',
      descriptions: 'Upcoming events',
      icon: 'calendar_month',
      buttonTitle: 'Create new event',
      buttonHandler: () => navigate('/myeventdetails/new'),
      permission: Permission.Events,
      type: 'events',
      fetcher: GetEvents(
        `${toDataSourceRequestString({
          ...dataState,
          sort: [{ field: 'startDateUtc', dir: 'desc' }]
        })}`
      )
    },
    {
      position: '2',
      isVisible: isInRole(Permission['MyLibrary.Games']),
      title: 'My games',
      descriptions: 'Open recent assets',
      icon: 'library_books',
      buttonTitle: 'Create new game',
      buttonHandler: () => navigate('/mylibrary/games/new'),
      permission: Permission['MyLibrary.Games'],
      type: 'games',
      fetcher: GetGamesAsync(`${toDataSourceRequestString(dataState)}`)
    },
    {
      position: '3',
      isVisible: isInRole(Permission['MyLibrary.Assessments']),
      title: 'My assessments',
      descriptions: 'Open recent assets',
      icon: 'task',
      buttonTitle: 'Create assessment',
      buttonHandler: () => navigate('/mylibrary/assessment/new'),
      permission: Permission['MyLibrary.Assessments'],
      type: 'assessments',
      fetcher: getAssessments(`${toDataSourceRequestString(dataState)}`)
    },
    {
      position: '4',
      isVisible: true,
      title: 'My feedback',
      descriptions: 'Open recent assets',
      icon: 'insert_chart',
      buttonTitle: 'Create feedback',
      buttonHandler: () => navigate('/mylibrary/feedback/new'),
      permission: Permission['MyLibrary.Feedback'],
      type: 'feedback',
      fetcher: getFeedback(`${toDataSourceRequestString(dataState)}`)
    },
    {
      position: '5',
      isVisible: isInRole(Permission['GlobalLibrary.Games']),
      title: 'Global library',
      descriptions: 'Open recent assets',
      icon: 'collections_bookmark',
      permission: Permission['GlobalLibrary.Games'],
      type: 'global-library',
      fetcher: GetGlobalGamesAsync(`${toDataSourceRequestString(dataState)}`)
    },
    {
      position: '6',
      isVisible: true,
      title: 'Support',
      descriptions: '',
      icon: 'help_center',
      data: supports
    },
    {
      position: '7',
      isVisible: true,
      title: 'Useful links',
      descriptions: '',
      icon: 'link',
      data: usefulLinks
    }
  ];

  const renderCardContent = () => {
    return cardProperties
      .filter((item: CardPropertiesState) => item.isVisible)
      .map((item: CardPropertiesState, index: number) => {
        return (
          <div className={'p-2 col-xs-12 col-3'}>
            <DashboardCardView
              additionalClassName={'dashboard-content-bg p-2 border'}
              position={item.position}
              data={item.data!}
              icon={item.icon!}
              cardDescription={item}
              buttonTitle={item.buttonTitle}
              fetcher={item.fetcher}
              permission={item.permission}
              type={item.type}
              buttonHandler={item.buttonHandler}
            />
          </div>
        );
      });
  };

  return (
    <div className={'dashboard-content d-flex flex-wrap row w-100'}>
      {renderCardContent()}
    </div>
  );
};
export default DashboardContent;
