import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Label } from '@progress/kendo-react-labels';
import {
  Event,
  EventFeedback,
  EventPlaylist,
  EventPlaylistFacilitator,
  EventPlaylistLocation
} from '../types/event';
import { ActivityEventView } from '../components/activity-event-view';
import { ColView } from '../components/col-view';
import {
  GetEventById,
  GetEventFeedbacks,
  GetEventPlaylistFacilitators,
  GetEventPlaylistLocations,
  GetEventPlaylists
} from '../services/events';
import { formatDateToDDMMYYYY, getFormattedDateString } from '../utils/date';
import { FeedbackEventView } from '../components/feedback-event-view';
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent
} from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';

export type dictionary = {
  id: number;
  name: string;
};

type EventPlaylistMapper = {
  eventPlaylist?: EventPlaylist;
  facilitators?: EventPlaylistFacilitator[];
  locations?: EventPlaylistLocation[];
};

interface EventViewProps {
  id: string | undefined;
  onBackButtonClick: () => void;
}

export default function EventsView(props: EventViewProps) {
  const { id, onBackButtonClick } = props;
  const [logoSrc, setLogoSrc] = useState<
    string | ArrayBuffer | null | undefined
  >();
  const [event, setEvent] = useState<Event>({
    id: 0,
    name: '',
    imageLogoUrl: undefined
  });
  const [eventDate, setEventDate] = useState<string>('');
  const [populatePlaylist, setPopulatePlaylist] =
    useState<EventPlaylistMapper>();
  const [eventPlaylists, SetEventPlaylists] = useState<EventPlaylistMapper[]>(
    []
  );
  const [eventFeedbacks, setEventFeedbacks] = useState<EventFeedback[]>([]);
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const backHandler = useCallback(() => {
    onBackButtonClick();
  }, [onBackButtonClick]);

  useEffect(() => {
    if (populatePlaylist) {
      if (
        eventPlaylists?.find(
          (x) => x.eventPlaylist?.id === populatePlaylist.eventPlaylist?.id
        ) === undefined
      ) {
        let newPlaylists: EventPlaylistMapper[] = [...eventPlaylists];
        newPlaylists.push(populatePlaylist);
        newPlaylists.sort(function (a, b) {
          return a.eventPlaylist!.id! - b.eventPlaylist!.id!;
        });
        SetEventPlaylists(newPlaylists);
      }
    }
  }, [populatePlaylist]);

  const populateEvent = async () => {
    if (id) {
      GetEventById(parseInt(id)).then(async (responseEvent) => {
        setEvent(responseEvent);
        if (responseEvent.logoMediaId) {
          setLogoSrc(responseEvent?.imageLogoUrl!);
        }
      });

      Promise.all([
        GetEventPlaylists(parseInt(id)),
        GetEventFeedbacks(parseInt(id))
      ]).then((responseData) => {
        responseData[0]?.data?.forEach(async (playlist) => {
          let newMapper: EventPlaylistMapper = {
            eventPlaylist: playlist
          };

          const [facilitators, locations] = await Promise.all([
            GetEventPlaylistFacilitators(parseInt(id), playlist.id!),
            GetEventPlaylistLocations(parseInt(id), playlist.id!)
          ]);

          newMapper.facilitators = facilitators?.data ?? [];
          newMapper.locations = locations.data ?? [];
          setPopulatePlaylist(newMapper);
        });
        setEventFeedbacks(responseData![1]?.data);
      });
    }
  };
  const createEventDate = () => {
    if (eventPlaylists) {
      const playlistLength = eventPlaylists?.length;
      if (playlistLength === 1) {
        const playlistDate = eventPlaylists[0];
        const displayDate = `${formatDateToDDMMYYYY(
          playlistDate.eventPlaylist?.startDateUtc!,
          true
        )} - ${formatDateToDDMMYYYY(
          playlistDate.eventPlaylist?.endDateUtc!,
          true
        )}`;
        setEventDate(displayDate);
      }
      if (playlistLength > 1) {
        // Find event date from start till end from playlist

        const sortedMin = eventPlaylists!.sort(
          (a, b) =>
            Number(new Date(a.eventPlaylist!.startDateUtc!)) -
            Number(new Date(b.eventPlaylist!.startDateUtc!))
        );
        const eventStart = sortedMin[0].eventPlaylist?.startDateUtc;

        const sortedMax = eventPlaylists!.sort(
          (a, b) =>
            Number(new Date(a.eventPlaylist!.endDateUtc!)) -
            Number(new Date(b.eventPlaylist!.endDateUtc!))
        );
        const eventEnd = sortedMax.reverse()[0]?.eventPlaylist?.endDateUtc!;

        const displayDate = `${getFormattedDateString(
          new Date(eventStart!)
        )} - ${getFormattedDateString(new Date(eventEnd!))}`;
        setEventDate(displayDate);
      }
    }
  };

  useEffect(() => {
    populateEvent();
  }, []);

  useEffect(() => {
    createEventDate();
  }, [eventPlaylists]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <>
            <div className={'card p-4'}>
              <Row>
                <Col md={8}>
                  <Row className={'p-1'}>
                    <ColView
                      className={'d-flex flex-column'}
                      colSpec={12}
                      labelClassName={'text-muted'}
                      label={'Event title'}
                      value={event?.name}
                    />
                  </Row>
                  <Row className={'p-1'}>
                    <ColView
                      className={'d-flex flex-column'}
                      colSpec={12}
                      labelClassName={'text-muted'}
                      label={' Event Date + Time'}
                      value={eventDate}
                    />
                  </Row>
                </Col>
              </Row>
            </div>

            {eventPlaylists &&
              eventPlaylists.map((event, index) => {
                return (
                  <ActivityEventView
                    key={index}
                    event={event.eventPlaylist}
                    index={index}
                    facilitators={event?.facilitators ?? []}
                    locations={event?.locations}
                  />
                );
              })}

            {eventFeedbacks &&
              eventFeedbacks.map((feedback, index) => {
                return (
                  <FeedbackEventView
                    key={index}
                    feedback={feedback}
                    index={index}
                  />
                );
              })}

            <div className={'mt-4'}>
              <span className={'label'}>Client/Agency details</span>
            </div>
            <div className={'card p-4'}>
              <Row className={'p-2'}>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={8}
                  labelClassName={'text-muted'}
                  label={'Client'}
                  value={event?.clientName}
                />
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={4}
                  labelClassName={'text-muted'}
                  label={'Agent'}
                  value={event?.agency}
                />
              </Row>
              <Row className={'p-2'}>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={8}
                  labelClassName={'text-muted'}
                  label={'Contact Name'}
                  value={event?.clientContact}
                />
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={4}
                  labelClassName={'text-muted'}
                  label={'Phone Number'}
                  value={event?.clientPhoneNumber}
                />
              </Row>
              <Row className={'p-2'}>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={8}
                  labelClassName={'text-muted'}
                  label={'Email Address'}
                  value={event?.clientEmailAddress}
                />
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={4}
                  labelClassName={'text-muted'}
                  label={'Industry'}
                  value={event?.industry}
                />
              </Row>
            </div>

            <ExpansionPanel
              title={'Staffing'}
              className={'mt-4'}
              expanded={isExpand}
              onAction={(event: ExpansionPanelActionEvent) =>
                setIsExpand(!event.expanded)
              }>
              <Reveal>
                {isExpand && (
                  <ExpansionPanelContent>
                    <div>
                      <span className={'label'}>Sales person</span>
                    </div>
                    <div className={'card p-4'}>
                      <Row className={'p-2'}>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Name'}
                          value={event?.salesName}
                        />
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Phone number'}
                          value={event?.salesPhoneNumber}
                        />
                      </Row>
                      <Row className={'p-2'}>
                        <Col md={4}>&nbsp;</Col>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={8}
                          labelClassName={'text-muted'}
                          label={'Email address'}
                          value={event?.salesEmailAddress}
                        />
                      </Row>
                    </div>

                    <div className={'mt-4'}>
                      <span className={'label'}>Lead facilitator</span>
                    </div>
                    <div className={'card p-4'}>
                      <Row className={'p-2'}>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Facilitator name'}
                          value={event?.leadFacilitatorName}
                        />
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Facilitator phone number'}
                          value={event?.leadFacilitatorPhoneNumber}
                        />
                      </Row>
                      <Row className={'p-2'}>
                        <Col md={4}>&nbsp;</Col>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={8}
                          labelClassName={'text-muted'}
                          label={'Facilitator email address'}
                          value={event?.leadFacilitatorEmailAddress}
                        />
                      </Row>
                    </div>

                    <div className={'mt-4'}>
                      <span className={'label'}>Event director</span>
                    </div>
                    <div className={'card p-4'}>
                      <Row className={'p-2'}>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Director name'}
                          value={event?.directorName}
                        />
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={4}
                          labelClassName={'text-muted'}
                          label={'Director phone number'}
                          value={event?.directorPhoneNumber}
                        />
                      </Row>
                      <Row className={'p-2'}>
                        <Col md={4}>&nbsp;</Col>
                        <ColView
                          className={'d-flex flex-column'}
                          colSpec={8}
                          labelClassName={'text-muted'}
                          label={'Director email address'}
                          value={event?.directorEmailAddress}
                        />
                      </Row>
                    </div>
                  </ExpansionPanelContent>
                )}
              </Reveal>
            </ExpansionPanel>

            <div className={'mt-4'}>
              <span className={'label'}>Event notes</span>
            </div>
            <div className={'card p-4'}>
              <Row className={'p-1'}>
                <Col md={12} className={'d-flex flex-column'}>
                  <Label>{event?.notes}</Label>
                </Col>
              </Row>
            </div>
            <hr />
            <Row className={'mt-3'}>
              <Col
                md={12}
                className={'d-flex justify-content-end align-items-center'}>
                <Button
                  variant={'secondary'}
                  className={'w-10 text-light'}
                  onClick={backHandler}>
                  Back
                </Button>
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </Container>
  );
}
