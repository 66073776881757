import { useEffect, useState } from 'react';
import { PrivatePage } from '../../components/private-page';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Label } from '@progress/kendo-react-labels';
import { useNavigate, useParams } from 'react-router-dom';
import { toastStore } from '../../stores/toast-store';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { Summary } from '../../components/overview';
import Hero from '../../components/hero';
import { formatDateToDDMMYYYY } from '../../utils/date';
import {
  GetGameSummaryAsAdminAsync,
  GetPendingGameAsyncById
} from '../../services/games';
import { GameResponse } from '../../types/responses/game-response';
import {
  GetPendingGameDocumentContentAsync,
  GetPendingGameDocumentLatestAsync
} from '../../services/json-document';
import { GameDocument } from '../../types/game-document';
import { GameDocumentVersionResponse } from '../../types/responses/game-document-version-response';

import {
  ApprovalDocumentAsync,
  GetResourceValue
} from '../../utils/game-document';
import { FileUrl } from '../game-designer/game-overview';
import { YesNoDialogResult } from '../../types/dialog-result';
import { appStore } from '../../stores/app-store';
import _ from 'lodash';
import PdfViewerComponent from '../../components/media/pdf-viewer';
import { GameSummaryResponse } from '../../types/responses/game-summary-response';
export function LibrarApprovalPage() {
  const { id } = useParams();
  const libApprovalID = parseInt(id!);
  const [game, setGames] = useState<GameResponse>();
  const [gameDocumentContent, setGameDocumentContent] =
    useState<GameDocument>();
  const [gameDocumentLatest, setGameDocumentLatest] =
    useState<GameDocumentVersionResponse>();
  const [gameSummary, setGameSummary] = useState<GameSummaryResponse>();
  const [openApproveSectionDialog, setopenApproveSectionDialog] =
    useState(false);
  const [approvalStatus, setApprovalStatus] = useState<string>(''); //'Approve' | 'Reject'
  const [confirmMessage, setConfirmMessage] = useState<string>();
  const [confirmTitle, setConfirmTitle] = useState<string>();
  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  const navigate = useNavigate();

  const showApproveSectionDialog = (approval: 'Approved' | 'Rejected') => {
    setApprovalStatus(approval);
    if (approval === 'Approved') {
      setConfirmTitle('Approve game');
      setConfirmMessage('Are you sure you want to approve this game?');
    } else {
      setConfirmTitle('Reject game');

      setConfirmMessage('Are you sure you want to reject this game?');
    }
    setopenApproveSectionDialog(true);
  };

  const closeApproveSectionDialog = () => {
    setopenApproveSectionDialog(false);
  };

  const confirmApproveSectionDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        await ApprovalDocumentAsync(parseInt(id!), approvalStatus as any, true);
        navigate('../globallibraries/pending');
      } catch (e: any) {
        toastStore.show(
          'Library approval',
          'Failed to approve template.',
          'error'
        );
      }
    }
    closeApproveSectionDialog();
  };

  useEffect(() => {
    fetchGame(libApprovalID);
  }, [libApprovalID]);

  const fetchGame = async (gameId: number) => {
    try {
      appStore.showLoading();
      const game = await GetPendingGameAsyncById(gameId);
      const gameDocumentLatest =
        await GetPendingGameDocumentLatestAsync(gameId);
      const gameDocumentContent = await GetPendingGameDocumentContentAsync(
        gameId,
        gameDocumentLatest.id!
      );
      const summary = await GetGameSummaryAsAdminAsync(gameId);
      setGames(game);
      setGameDocumentLatest(gameDocumentLatest);
      setGameSummary(summary);
      setGameDocumentContent(gameDocumentContent);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));

      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url);
      }
    }
  };

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Administration',
          href: 'Administration'
        },
        {
          label: 'Pending approval',
          href: 'administration/globallibraries/pending'
        },
        {
          label: 'Pending approval details',
          href: 'administration/libraryapproval/' + id
        }
      ]}
      pageTitle={'Administration'}>
      <h2>Pending approval details</h2>
      <hr />

      <div className={'d-flex mt-2 flex-column flex-xl-row'}>
        <Container>
          <Row>
            <Col xs={12} className={'mb-3'}>
              <Hero
                src={
                  gameDocumentContent &&
                  GetResourceValue(
                    gameDocumentContent!,
                    gameDocumentContent?.overview?.headerResId!
                  )
                }
                editable={false}
              />
              <div
                className={'d-flex h-4 align-items-center'}
                style={{
                  background: 'rgb(0, 58, 100)'
                }}>
                <div>
                  <h4 className={'text-light fw-bold ml-22 mb-0'}>
                    {game?.name}
                  </h4>
                </div>
              </div>
            </Col>

            <Col xs={12} className={'mb-5 w-100'}>
              <Col md={3}>
                <div className={'d-flex flex-column'}>
                  <div
                    className={'round-card rounded-full h-9 w-9 mr-12 bg-white'}
                    style={{
                      marginTop: '-130px',
                      backgroundImage: `url("${
                        gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent,
                          gameDocumentContent?.overview?.logoResId!
                        )
                      }")`
                    }}
                  />
                </div>
              </Col>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <p className={'fw-bold'}>{game && game.about}</p>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <Row>
                <Col xs={4}>
                  <span>Players</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.recommendedPlayers}
                  </p>
                </Col>
                <Col xs={4}>
                  <span>Duration</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.recommendedDuration}
                  </p>
                </Col>
                <Col xs={4}>
                  <span>Players per Team</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.playersPerTeam}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <Label className={'fw-bold'}>How it works</Label>
              <p>{game && game.howItWorks}</p>
            </Col>

            <Col xs={12} className={'mb-3'}>
              <div className={'learning-outcomebox'}>
                <Label className={'fw-bold'}>Learning outcomes</Label>
                <ul>
                  {game &&
                    game.learningOutcome?.map((outcome, idx) => (
                      <li key={idx}>{outcome}</li>
                    ))}
                </ul>
              </div>
            </Col>

            <Col>
              <Label className={'fw-bold'}>Unique selling points</Label>
              <ul>
                {game &&
                  game.sellingPoints?.map((selling, idx) => (
                    <li key={idx}>{selling}</li>
                  ))}
              </ul>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <div className="video-wrapper">
                {previewItemType === 'video' && (
                  <video
                    key={previewItem}
                    className="video"
                    loop
                    controls
                    preload="none">
                    <source src={previewItem} type="video/mp4" />
                  </video>
                )}
                {previewItemType === 'image' && (
                  <img src={previewItem} className={'img-fluid'} />
                )}

                {previewItemType === 'pdf' && (
                  <PdfViewerComponent source={previewItem}></PdfViewerComponent>
                )}
              </div>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <div className={'thumb-nails d-flex flex-wrap'}>
                {game?.promotionalMedias &&
                  game.promotionalMedias.length > 0 &&
                  game.promotionalMedias.map((file, index) => (
                    <div
                      key={index}
                      className={
                        'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center cursor-pointer'
                      }
                      onClick={() =>
                        onClickMedia({
                          url: file.mediaUrl,
                          type: _.lowerCase(file.mediaType)
                        })
                      }>
                      {file && _.lowerCase(file.mediaType) === 'video' && (
                        <div
                          className={
                            'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                          }
                          style={{
                            backgroundImage: `url("${file.mediaUrl}")`,
                            backgroundPosition: 'center'
                          }}>
                          <button className={'btn text-light'}>
                            <span className={'material-symbols-outlined'}>
                              play_arrow
                            </span>
                          </button>
                        </div>
                      )}
                      {file && _.lowerCase(file.mediaType) === 'image' && (
                        <img
                          src={file.mediaUrl ?? ''}
                          className={'rounded'}
                          alt={'media'}
                        />
                      )}

                      {file && _.lowerCase(file.mediaType) === 'pdf' && (
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: '50px' }}>
                          picture_as_pdf
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className={'mb-2'}>
              <hr />
            </Col>
          </Row>

          {gameDocumentLatest?.status === 'Pending' && (
            <Row>
              <Col md={6}>
                <Button
                  variant={'danger'}
                  className={'w-100'}
                  onClick={() => showApproveSectionDialog('Rejected')}>
                  Reject
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant={'success'}
                  className={'w-100'}
                  onClick={() => showApproveSectionDialog('Approved')}>
                  Approve
                </Button>
              </Col>
            </Row>
          )}
        </Container>
        <Summary
          author={gameSummary?.createdBy}
          authorAvatar={gameSummary?.creatorAvatar}
          createdDate={
            gameSummary?.createdDateUtc &&
            formatDateToDDMMYYYY(gameSummary?.createdDateUtc!, true)
          }
          updateDate={
            gameSummary?.modifiedDateUtc &&
            formatDateToDDMMYYYY(gameSummary?.modifiedDateUtc!, true)
          }
          version={gameSummary?.version}
        />
      </div>

      {openApproveSectionDialog && (
        <YesNoDialog
          title={confirmTitle}
          onConfirm={confirmApproveSectionDialog}
          onClose={closeApproveSectionDialog}>
          {confirmMessage}
        </YesNoDialog>
      )}
    </PrivatePage>
  );
}
