import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { useNavigate } from 'react-router-dom';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { DefaultGridSettings } from '../constants/grid-settings';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { NoRecords } from '../components/grid/no-records';
import { GlobalLibraryPendingCardView } from './global-library-pending-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { appStore } from '../stores/app-store';
import { GetPendingGamesAsync } from '../services/games';
import { formatDateToDDMMYYYY } from '../utils/date';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

export default function GlobalLibraryPendingGrid() {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [libraries, setLibraries] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchLibrary = async () => {
    try {
      appStore.showLoading();
      const response = await GetPendingGamesAsync(
        `${toDataSourceRequestString(dataState)}`
      );
      setLibraries(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchLibrary();
  }, [dataState]);

  const onColumnTextClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        <span
          className={'link-primary cursor-pointer'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const handleTitleClick = (id: number) => {
    navigate('/administration/libraryapproval/' + id);
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        {formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Games pending approval'}></Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search games'}
          columnsToSearch={['name', 'createdBy', 'languages']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            const newState = {
              ...e.dataState,
              filter: {
                logic: 'and',
                filters: [e.dataState.filter!]
              }
            } as State;
            setDataState(newState);
          }}></GridToolBar>
        <ExcelExport
          data={libraries.data}
          ref={gridExportRef}
          fileName={`libraries.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-library'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={libraries}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTextClick}
              />
              <Column
                field={'createdBy'}
                title={'Game Designer'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'createdDateUtc'}
                title={'Date Created'}
                cell={DateCell}
              />
              <Column
                field={'languages'}
                title={'Languages'}
                columnMenu={ColumnMenu}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'card' && (
            <GlobalLibraryPendingCardView
              data={libraries.data}
              onTitleClick={handleTitleClick}
              total={libraries.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>
      </div>
    </>
  );
}
