import React, { useContext } from 'react';

import { LayoutContext } from '../contexts/layout-provider';
import { Logo, LogoText } from '../components/logo';

const MenuWrapper: React.FC<{
  children: React.ReactNode;
  classname?: string;
}> = ({ children, classname = '' }) => {
  const layout = useContext(LayoutContext);

  return (
    <aside
      className={`app-side-nav ${layout.navIsCollapsed ? 'app-side-nav--collapsed' : ''} overflow-auto ${classname}`}>
      <div className={'nav-header'}>
        <div className={`hamburger`}>
          <span
            className="material-symbols-outlined cursor-pointer text-decoration-none"
            onClick={layout.toggleNavIsCollapsed}>
            menu
          </span>
        </div>
        <div className={`d-flex w-100 h-100 align-items-center pe-3`}>
          <Logo
            className="p-2"
            primaryColor={'#4698cb'}
            width={'3rem'}
            height={'3rem'}
          />
          <LogoText primaryColor={'#4698cb'} />
        </div>
      </div>
      <hr />
      {children}
    </aside>
  );
};

export default MenuWrapper;
