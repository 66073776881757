import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';
import PdfViewerComponent from '../media/pdf-viewer';

interface MediaPreviewDialogProps {
  title: string;
  mediaType: string;
  mediaUrl: string;
  onClose: () => void;
}

const MediaPreviewDialog: React.FC<MediaPreviewDialogProps> = ({
  title,
  mediaType,
  mediaUrl,
  onClose
}) => {
  return (
    <Dialog closeIcon title={title} onClose={onClose}>
      {mediaType === 'video' ? (
        <video key={mediaUrl} className="video" controls>
          <source src={mediaUrl} type="video/mp4"></source>
        </video>
      ) : mediaType === 'image' ? (
        <img className={'w-100 h-100'} src={mediaUrl} alt="" />
      ) : mediaType === 'audio' ? (
        <audio src={mediaUrl} controls autoPlay>
          <source src={mediaUrl} type="audio/mpeg" />
        </audio>
      ) : (
        mediaType == 'pdf' && (
          <PdfViewerComponent source={mediaUrl}></PdfViewerComponent>
        )
      )}
    </Dialog>
  );
};

export default MediaPreviewDialog;
