import { User } from '../types/user';
import { State } from '@progress/kendo-data-query';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../constants/grid-settings';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import MediaCardBackground from './mediacard-bg';
import { UserResponse } from '../types/responses/user-response';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface NetworkMembersCardProps extends State {
  data?: UserResponse[];
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  total?: number;
}
export const NetworkMembersCard = ({
  data,
  total = 0,
  ...props
}: NetworkMembersCardProps) => {
  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  return (
    <div className="mt-3">
      <div className={'row'}>
        {data &&
          data.map((user, index) => {
            return (
              <div className={'col-sm-4'} key={index}>
                <div className={'card mt-4'}>
                  <div className={'row p-3'}>
                    <div className={'col-sm-4'}>
                      <div className={'d-grid col-3 col-md-5 col-lg-3'}>
                        <MediaCardBackground imageUrl={user.avatarMediaUrl!} />
                      </div>
                    </div>
                    <div className={'col-sm-8'}>
                      <div className={'card-body-right p-2'}>
                        <span className={'card-text align-middle'}>
                          Name: {user?.firstName} {user?.lastName}
                        </span>
                        <br />
                        <span className={'card-text align-middle'}>
                          Email: {user?.username}
                        </span>
                        <br />
                        <span className={'card-text align-middle'}>
                          Phone: {user?.phoneNumber}
                        </span>
                        <br />
                        <span className={'card-text align-middle'}>
                          Organisation: {user?.organisation}
                        </span>
                        <br />
                        <span className={'card-text align-middle'}>
                          Country: {user?.country}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
