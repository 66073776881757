import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnMenu } from '../components/columnMenu';
import { ActionCell, ActionsCell } from '../components/grid/actions-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import {
  copyGlobalFeedbackToLibrary,
  getGlobalContentsByFeedbackIdAsync,
  getGlobalDocumentLatestAsync,
  getGlobalFeedback,
  getGlobalFeedbackById
} from '../services/feedback';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { Feedback } from '../types/feedback';
import { CustomFilterLanguage } from './filter/custom-filter-language';
import { GlobalFeedbackCardView } from './global-feedback-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { NoRecords } from '../components/grid/no-records';
import merge from 'lodash.merge';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const GlobalFeedbackGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [feedbacks, setFeedbacks] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchFeedbacks = async () => {
    try {
      appStore.showLoading();

      const response = await getGlobalFeedback(
        `${toDataSourceRequestString(dataState)}`
      );
      setFeedbacks(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchFeedbacks();
    //eslint-disable-next-line
  }, [dataState]);

  const handleTitleClick = (id: number) => {
    navigate(`../global-feedback-details/${id}`);
  };

  const onColumnTitleClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        <span
          className={'link-primary cursor-pointer p-0 text-start'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionsCell {...props}>
          <ActionCell
            title={'View feedback'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => handleTitleClick(props.dataItem.id)}
          />
          <ActionCell
            title={'Copy feedback'}
            icon={'content_copy'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onCopyFeedback(props.dataItem)}
          />
        </ActionsCell>
      </>
    );
  };

  const onCopyFeedback = async (feedback: Feedback) => {
    try {
      appStore.showLoading();

      const document = await getGlobalDocumentLatestAsync(feedback.id);

      // combine feedback and feedback documents (resources)
      let feed1 = await getGlobalFeedbackById(feedback.id);
      feed1.source = 'Imported';
      let feed2 = await getGlobalContentsByFeedbackIdAsync(
        feedback.id,
        document.id
      );

      let mergedFeedback = merge(feed1, feed2);

      await copyGlobalFeedbackToLibrary(mergedFeedback);

      toastStore.show(
        'Feedback list',
        <div>Feedback copied to Feedback list.</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'Feedback list',
        <div>Failed to copy to Feedback list.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      fetchFeedbacks();
    }
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return (
      <td className={'k-table-td'}>
        {formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}
      </td>
    );
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.language} className={'k-table-td'}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.language}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Global Feedback'}>
        <HelpSupport
          title="Global feedback library guide"
          url="https://forum.catalystglobal.com/t/4274"
        />
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search feedback'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={feedbacks.data}
          ref={gridExportRef}
          fileName={`feedback.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-feedback'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={feedbacks}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTitleClick}
              />
              <Column
                field={'createdDateUtc'}
                title={'Created'}
                cell={DateCell}
              />
              <Column
                field={'version'}
                title={'Version'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'language'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              <Column
                field={'organisation'}
                title={'Organisation Name'}
                columnMenu={ColumnMenu}
              />

              <Column title={'Actions'} cell={onActionRow} width={120} />

              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}

          {gridMode === 'card' && (
            <GlobalFeedbackCardView
              data={feedbacks.data}
              onTitlelick={handleTitleClick}
              onCopy={onCopyFeedback}
              onView={handleTitleClick}
              total={feedbacks.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>
      </div>
    </>
  );
};
export default GlobalFeedbackGrid;
