import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ComboboxInput } from './form-input';

import cloneDeep from 'lodash.clonedeep';
import { EnvironmentList } from '../types/environment';
import { EventFeedback } from '../types/event';
import { Feedback } from '../types/feedback';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { convertJsonDateToDate } from '../utils/date';

export interface FeedbackEventProps {
  feedback: EventFeedback;
  feedbackList?: Feedback[];
  index?: number;
  environments?: EnvironmentList[];
  onChange?: (feedback: EventFeedback, index: number) => void;
  onDeleteFeedback?: (index: number) => void;
  onCopyFeedback?: (index: number) => void;
}

export type dictionary = {
  id: number | string;
  name: string;
};

export const FeedbackEvent = ({
  feedback,
  feedbackList = [],
  index,
  environments,
  onChange,
  onDeleteFeedback,
  onCopyFeedback
}: FeedbackEventProps) => {
  const [eventFeedback, setEventFeedback] = useState<EventFeedback>(feedback);
  const [filteredFeedbackList, setFilteredFeedbackList] =
    useState<Feedback[]>(feedbackList);

  const filterFeedbackListData = (filter: FilterDescriptor) => {
    const data = feedbackList?.slice();
    return filterBy(data, filter);
  };

  const filterFeedbackListChange = (event: ComboBoxFilterChangeEvent) => {
    setFilteredFeedbackList(filterFeedbackListData(event.filter));
  };

  const handleDeleteGame = () => {
    if (onDeleteFeedback && index !== undefined && index >= 0) {
      onDeleteFeedback(index);
    }
  };

  const handleCopyFeedback = () => {
    if (onCopyFeedback && index !== undefined && index >= 0) {
      onCopyFeedback(index);
    }
  };

  const onChangeField = (field: keyof EventFeedback, value: string) => {
    if (eventFeedback) {
      let newEventFeedback = cloneDeep(eventFeedback);
      if (newEventFeedback && field) {
        newEventFeedback![field] = value as never;
      }
      setEventFeedback(newEventFeedback);
      if (onChange && (index as number) > -1) {
        onChange(newEventFeedback, index as number);
      }
    }
  };

  const onChangeFeedbackList = (e: ComboBoxChangeEvent) => {
    let newEventFeedback = cloneDeep(eventFeedback);
    let optionSelected = e.value as dictionary;
    const fieldName = e.target.name;

    if (newEventFeedback && optionSelected) {
      if (fieldName === 'feedbackId') {
        newEventFeedback.feedbackId = optionSelected.id as number;
        newEventFeedback.name = optionSelected.name;
      } else {
        newEventFeedback.environmentId = optionSelected.id as number;
      }
    } else {
      if (fieldName === 'feedbackId') {
        newEventFeedback.feedbackId = 0;
        newEventFeedback.name = '';
      } else {
        newEventFeedback.environmentId = 0;
      }
    }

    setEventFeedback(newEventFeedback);
    if (onChange && (index as number) > -1) {
      onChange(newEventFeedback, index as number);
    }
  };

  const onChangeDateField = (
    field: keyof EventFeedback,
    value: Date | null
  ) => {
    if (value) {
      const newFeedback: EventFeedback = { ...eventFeedback, [field]: value };

      if (newFeedback) {
        if (onChange && (index as number) > -1) {
          onChange(newFeedback, index as number);
        }
      }
    }
  };

  useEffect(() => {
    if (feedback) {
      const newEventFeedback = { ...feedback };
      setEventFeedback(newEventFeedback);
    }
  }, [feedback]);

  return (
    <>
      <div className={'mt-4'}>Feedback {(index as number) + 1}</div>
      <div className={'card p-4'}>
        <Row className={'p-1'}>
          <Col md={'8'}>
            <Label>Feedback template*</Label>
            <ComboboxInput
              name={'feedbackId'}
              data={filteredFeedbackList}
              textField={'name'}
              filterable={true}
              onFilterChange={filterFeedbackListChange}
              value={feedbackList?.find((x) => x.id === feedback?.feedbackId)}
              onChange={onChangeFeedbackList}
            />
            {(!feedback?.feedbackId || feedback?.feedbackId === 0) && (
              <Error>{'Feedback template is required'}</Error>
            )}
          </Col>
        </Row>

        <Row className="p-1">
          <Col md="12">
            <div
              className="p-4"
              style={{
                backgroundColor: '#f7fcff',
                textAlign: 'justify'
              }}>
              <span>
                <span className="fw-bold text-primary">
                  Environment and Duration:
                </span>{' '}
                Accuracy is important. This information sets the Server
                Environment. For flexibility, enter a start date one day before
                your event and an end date one day after.
              </span>
            </div>
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="8">
            <Label>Environment*</Label>
            <ComboboxInput
              name={'environmentId'}
              data={environments}
              textField="name"
              filterable={true}
              value={environments?.find(
                (x) => x.id === feedback?.environmentId
              )}
              onChange={onChangeFeedbackList}
            />
            {feedback?.environmentId === undefined ||
              (feedback?.environmentId === 0 && (
                <Error>{'Environment is required'}</Error>
              ))}
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md="4">
            <Label>Start date*</Label>
            <DatePicker
              value={convertJsonDateToDate(feedback.startDateUtc)}
              format="dd/MMM/yyyy"
              onChange={(e) => onChangeDateField('startDateUtc', e.value)}
            />
            {!feedback?.startDateUtc && <Error>Start date is required</Error>}
          </Col>
          <Col md="3">
            <Label>Time*</Label>
            <TimePicker
              value={convertJsonDateToDate(feedback.startDateUtc)}
              onChange={(e) => onChangeDateField('startDateUtc', e.value)}
            />
            {!feedback?.startDateUtc && <Error>Time is required</Error>}
          </Col>
        </Row>

        <Row className={'d-flex p-1'}>
          <Col md="4">
            <Label>End date*</Label>
            <DatePicker
              value={convertJsonDateToDate(feedback.endDateUtc)}
              format="dd/MMM/yyyy"
              onChange={(e) => onChangeDateField('endDateUtc', e.value)}
            />
            {!feedback?.endDateUtc && <Error>End date is required</Error>}
          </Col>
          <Col md="3">
            <Label>Time*</Label>
            <TimePicker
              value={convertJsonDateToDate(feedback.endDateUtc)}
              onChange={(e) => onChangeDateField('endDateUtc', e.value)}
            />
            {!feedback?.endDateUtc && <Error>Time is required</Error>}
          </Col>
        </Row>

        <Row className="p-1">
          <Col md="12">
            <div
              className="p-4"
              style={{
                backgroundColor: '#f7fcff',
                textAlign: 'justify'
              }}>
              <span>
                <span className="fw-bold text-primary">Important:</span> Be
                precise. Your feedback will automatically start and end on the
                dates you specify. You can edit this information before the
                feedback starts.
              </span>
            </div>
          </Col>
        </Row>

        <Row className={'p-1'}>
          <Col md={'12'}>
            <Label>Feedback notes</Label>
            <TextArea
              rows={4}
              value={feedback?.notes}
              onChange={(e) => onChangeField('notes', e.value)}></TextArea>
          </Col>
        </Row>

        <Row className={'p-1 mt-3'}>
          <Col md={'6'} className={'d-flex align-items-center mt-3'}></Col>
          <Col md={'6'} className={'d-flex justify-content-end mt-3'}>
            <span
              className={'material-symbols-outlined'}
              style={{ cursor: 'pointer' }}
              onClick={handleCopyFeedback}>
              content_copy
            </span>
            <span
              className={'material-symbols-outlined text-danger'}
              style={{ cursor: 'pointer' }}
              onClick={handleDeleteGame}>
              delete
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};
