import React from 'react';
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps
} from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';

export const FontCombobox: React.FC<{
  value: string | null;
  onChange: (event: ComboBoxChangeEvent) => void;
}> = ({ value, onChange }) => {
  const fontRef = React.useRef<string[] | undefined>(undefined);
  const [fontList, setFontList] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (fontRef.current) {
      setFontList(fontRef.current);
      return;
    }

    fontRef.current = [
      'Arial',
      'Verdana',
      'Tahoma',
      'Trebuchet MS',
      'Times New Roman',
      'Georgia',
      'Garamond',
      'Courier New',
      'Brush Script MT'
    ];
    setFontList(fontRef.current);
  }, []);

  const filterData = (filter: FilterDescriptor) => {
    const data = fontRef.current!.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setFontList(filterData(event.filter));
  };

  const fontItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span
        style={{
          fontFamily: itemProps.dataItem
        }}>
        {itemProps.dataItem}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <ComboBox
      style={{
        width: '100%',
        fontFamily: value ?? 'Open Sans'
      }}
      placeholder={'Select font'}
      data={fontList}
      onFilterChange={filterChange}
      itemRender={fontItemRender}
      allowCustom={false}
      filterable={true}
      onChange={onChange}
      value={value}
    />
  );
};
