import { useContext, useEffect, useState } from 'react';
import Toolbar from './toolbar';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { TextareaInput, FormInput } from '../../components/form-input';
import { FieldWrapper } from '@progress/kendo-react-form';
import {
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import PopupMenu from '../../components/popupMenu';
import { FileUrl } from './game-overview';
import { MenuList } from '../../features/game-document/maps/map-images-window';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../types/dialog-result';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import { PostResourceAsync } from '../../services/files';
import { GameDocumentContext } from '../../contexts/game-document';
import { formatDate } from '@progress/kendo-intl';
import { GameMarketing, PromotionalMedia } from '../../types/marketing';
import {
  CreateGameMarketingAsync,
  GetGameMarketingAsync,
  UpdateGameMarketingAsync
} from '../../services/marketing';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import PdfViewerComponent from '../../components/media/pdf-viewer';
import _ from 'lodash';
import {
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../../services/json-document';
import {
  PublishGameDocumentAsync,
  SubmitToGlobalLibraryGameDocumentAsync
} from '../../utils/game-document';
import { Dialog } from '@progress/kendo-react-dialogs';
import SubmitConfirmation from '../../components/submit-to-global-library';

type FileUpload = 'Background' | 'Logo' | 'Media';

const GameSalesMarketing = () => {
  const [marketingData, setMarketingData] = useState<
    GameMarketing & { isDirty: boolean }
  >({
    about: '',
    recommendedDuration: '',
    playersPerTeam: '',
    recommendedPlayers: '',
    howItWorks: '',
    learningOutcome: [''],
    sellingPoints: [''],
    promotionalMedias: [],
    isDirty: false
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [state, setState] = useContext(GameDocumentContext);

  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');
  const [tempThumbnailUrl, setTempThumbnailUrl] = useState<string>('');
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const [deleteMediaResourceIndex, setDeleteMediaResourceIndex] =
    useState<string>('');
  const [editMediaResourceIndex, setEditMediaResourceIndex] =
    useState<string>('');
  const [isEditMedia, setIsEditMedia] = useState<boolean>(false);
  const [showDeleteMediaConfirm, setShowDeleteMediaConfirm] =
    useState<boolean>(false);
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [extensionUploadedFile, setExtensionUploadedFile] =
    useState<string>('');
  const [gameStatus, setGameStatus] = useState<
    string | 'New || Pending || Approved || Rejected || Published'
  >('');
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleChange = (
    event: InputChangeEvent | TextAreaChangeEvent,
    data: keyof typeof marketingData
  ) => {
    const value = event.value;
    setMarketingData((prev) => ({
      ...prev,
      isDirty: true,
      [data]: value
    }));
  };

  const handleAddList = (data: 'sellingPoints' | 'learningOutcome') => {
    setMarketingData((prev) => ({
      ...prev,
      isDirty: true,
      [data]: [...prev[data], '']
    }));
  };

  const handleChangeList = (
    event: InputChangeEvent,
    data: 'sellingPoints' | 'learningOutcome',
    index: number
  ) => {
    const updatedValue = event.value;
    setMarketingData((prev) => {
      const updatedData = [...prev[data]];
      updatedData[index] = updatedValue;
      return {
        ...prev,
        isDirty: true,
        [data]: updatedData
      };
    });
  };

  const handleRemoveList = (
    data: 'sellingPoints' | 'learningOutcome',
    index: number
  ) => {
    setMarketingData((prev) => {
      const newData = [...prev[data]];
      newData.splice(index, 1);
      return { ...prev, isDirty: true, [data]: newData };
    });
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));
      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url as any);
      }
    }
  };

  const generateMediaPopupMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setMenuList(newMenus);
  };

  const updateMedia = (updatedMedia: PromotionalMedia) => {
    setMarketingData((prev) => {
      const updatedMedias = [...prev.promotionalMedias];
      updatedMedias[parseInt(editMediaResourceIndex)] = updatedMedia; // Update the specific media object
      return {
        ...prev,
        isDirty: true,
        promotionalMedias: updatedMedias
      };
    });

    setEditMediaResourceIndex('');

    setSelectedImageUrl('');
    setIsEditMedia(false);
    clearMediaPreview();

    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const deleteMedia = () => {
    setMarketingData((prev) => {
      const updatedMedias = prev.promotionalMedias.filter(
        (_, i) => i.toString() !== deleteMediaResourceIndex
      ); // Remove media by index
      return {
        ...prev,
        isDirty: true,
        promotionalMedias: updatedMedias
      };
    });
  };

  const clearMediaPreview = () => {
    setPreviewItemType('');
    setPreviewItem('');
  };

  const onMenuSelected = (id: number, menu: MenuList, idString?: string) => {
    setFileUpload('Media');
    if (menu.textMenu === 'Delete') {
      setIsEditMedia(false);
      setDeleteMediaResourceIndex(idString!);
      setShowDeleteMediaConfirm(true);
    }
    if (menu.textMenu === 'Edit') {
      setEditMediaResourceIndex(idString!);

      let value = marketingData.promotionalMedias[parseInt(idString!)].mediaUrl;

      setSelectedImageUrl(value);
      setIsEditMedia(true);
      clearMediaPreview();

      setEntityEditorIsVisible(!entityEditorIsVisible);
    }
  };

  const onAddMedia = () => {
    setFileUpload('Media');
    setExtensionUploadedFile('image/*, video/*, application/pdf');
    setEntityEditorIsVisible(true);
  };

  const onConfirmDeleteMedia = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      deleteMedia();
    }

    setShowDeleteMediaConfirm(false);
  };

  const toggleEntityEditor = () =>
    setEntityEditorIsVisible(!entityEditorIsVisible);

  useEffect(() => {
    generateMediaPopupMenu();
  }, []);

  const handleEntityEditorSubmit = (
    uploadedImage: UploadedImage,
    target: FileUpload
  ) => {
    const mimeType = uploadedImage?.mimeType || '';
    const [type, subtype] = mimeType.split('/');
    const resourceType = mimeType.startsWith('application') ? subtype : type;
    const newMedia: PromotionalMedia = {
      mediaType: resourceType,
      mediaUrl: uploadedImage.blobUrl
    };
    if (!isEditMedia) {
      setMarketingData((prev) => ({
        ...prev,
        isDirty: true,
        promotionalMedias: [...prev.promotionalMedias, newMedia]
      }));
    } else {
      updateMedia(newMedia);
    }

    toggleEntityEditor();
  };

  const onSaveHandler = async () => {
    let error = '';
    try {
      appStore.showLoading();
      const entity = marketingData;

      const applyError = (errorText: string) => {
        error = errorText;
        throw new Error('Some fields are required');
      };

      if (!entity.about) {
        applyError('About is required');
      }

      if (!entity.recommendedDuration) {
        applyError('Recommended duration is required');
      }

      if (!entity.recommendedPlayers) {
        applyError('Recommended number of players is required');
      }

      if (!entity.playersPerTeam) {
        applyError('Players per team is required');
      }

      if (!entity.howItWorks) {
        applyError('How it works is required');
      }

      const sellingPointsFilter = entity.sellingPoints.filter(
        (selling) => selling !== ''
      );
      const learningOutcomeFilter = entity.learningOutcome.filter(
        (outcome) => outcome !== ''
      );
      if (!sellingPointsFilter.length) {
        applyError('Key selling points is required');
      }

      if (!learningOutcomeFilter.length) {
        applyError('Learning outcomes is required');
      }

      // remove empty selling points and learning outcome from state
      setMarketingData((prev) => ({
        ...prev,
        sellingPoints: prev.sellingPoints.filter((e) => e !== ''),
        learningOutcome: prev.learningOutcome.filter((e) => e !== '')
      }));

      if (marketingData.promotionalMedias.length !== 0) {
        const mediaResp = (await Promise.all(
          marketingData.promotionalMedias.map(async (e) => {
            if (!e.mediaUrl.startsWith('blob')) {
              return e;
            }
            const gameId = state.gameId;
            const gameVer = state.gameDocument?.version;
            const dateString = formatDate(new Date(), 'yyMMddHHmmssSSS');
            const fileNamePrefix = `${gameId}-${dateString}-${gameVer}`;

            const respBlob = await fetch(e.mediaUrl);
            const blob = await respBlob.blob();
            const file = new File([blob], fileNamePrefix, { type: blob.type });
            const respUpload = await PostResourceAsync(file);
            return { mediaUrl: respUpload.value, mediaType: e.mediaType };
          })
        )) as PromotionalMedia[];

        entity.promotionalMedias = mediaResp;
      }

      if (!isEditMode) {
        await CreateGameMarketingAsync(state.gameId!, entity!);
      } else {
        await UpdateGameMarketingAsync(state.gameId!, entity!);
      }

      toastStore.show('Sales & Marketing', <div>Data saved</div>, 'success');
    } catch (ex) {
      console.error(ex);
      toastStore.show('Sales & Marketing', <div>{error}</div>, 'error');
    } finally {
      setMarketingData((prev) => ({ ...prev, isDirty: false }));
      appStore.hideLoading();
    }
  };

  const onReload = async () => {
    try {
      appStore.showLoading();
      const documentLatestResponse = await GetGameDocumentLatestAsync(
        state.gameId!
      );
      if (documentLatestResponse) {
        const content = await GetGameDocumentContentAsync(
          state.gameId!,
          documentLatestResponse.id!
        );

        setState((state) => ({
          ...state,
          isLoaded: true,
          gameDocument: content
        }));

        const gameMarketing = await GetGameMarketingAsync(state.gameId!);

        if (!_.some(gameMarketing, _.isNull)) {
          setMarketingData((prev) => ({ ...prev, ...gameMarketing }));
          setIsEditMode(true);
        }
      }
    } catch (err) {
      toastStore.show(
        '',
        <div>Refresh failed. Please try again.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onPublish = async () => {
    try {
      appStore.showLoading();
      if (!state.isValid || !state.gameId) {
        throw Error();
      }
      let updatedGameDocument = await PublishGameDocumentAsync(
        state.gameId!,
        state.gameDocument!
      );
      setState((state) => ({
        ...state,
        isDirty: false,
        gameDocument: updatedGameDocument
      }));

      const latest = await GetGameDocumentLatestAsync(
        parseInt(state.gameId.toString())
      );

      if (latest) {
        setGameStatus(latest.status as string);
      }

      toastStore.show('Game overview', <div>Game published.</div>, 'success');
    } catch (err) {
      toastStore.show(
        'Game overview',
        <div>Game failed to publish. Please try again.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onSubmitToGlobal = async () => {
    try {
      appStore.showLoading();
      if (!state.isValid || !state.gameId) {
        throw Error();
      }
      let updatedGameDocument = await SubmitToGlobalLibraryGameDocumentAsync(
        state.gameId!,
        state.gameDocument!
      );
      setState((state) => ({
        ...state,
        isDirty: false,
        gameDocument: updatedGameDocument
      }));

      const latest = await GetGameDocumentLatestAsync(
        parseInt(state.gameId.toString())
      );

      if (latest) {
        setGameStatus(latest.status as string);
      }

      toastStore.show(
        'Game overview',
        <div>Game submitted and waiting for approval.</div>,
        'success'
      );
    } catch (err) {
      toastStore.show(
        'Game overview',
        <div>Game failed to submit. Please try again.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      toggleDialog();
    }
  };

  useEffect(() => {
    const fetchGameMarketing = async () => {
      if (state.gameId) {
        try {
          appStore.showLoading();
          const latest = await GetGameDocumentLatestAsync(
            parseInt(state.gameId.toString())
          );

          if (latest) {
            setGameStatus(latest.status as string);
            const response = await GetGameMarketingAsync(state.gameId);

            if (!_.some(response, _.isNull)) {
              setMarketingData((prev) => ({ ...prev, ...response }));
              setIsEditMode(true);
            }
          }
        } catch (err) {
          console.error(err);
        } finally {
          appStore.hideLoading();
        }
      }
    };

    fetchGameMarketing();
  }, [state]);

  return (
    <>
      <Toolbar
        title={'Sales & Marketing'}
        buttonHelpSupport={{
          title: 'Global library submission guide',
          url: 'https://forum.catalystglobal.com/t/4551'
        }}
        showDefaultContent={false}>
        <div className={'d-flex align-items-center'}>
          <code className={'me-1'}>v{state.gameDocument?.version}</code>
          <Button
            onClick={onReload}
            themeColor={'info'}
            className={'me-1'}
            fillMode={'flat'}>
            <span className={'material-symbols-outlined'}>sync</span>
          </Button>
        </div>
        <Button
          onClick={onSaveHandler}
          disabled={!marketingData.isDirty}
          themeColor={'secondary'}
          className={'me-1'}>
          Save
        </Button>
        {gameStatus === 'Published' && isEditMode && (
          <Button
            onClick={toggleDialog}
            themeColor={'primary'}
            className={'me-1'}>
            Submit to Global Library
          </Button>
        )}
        <Button
          onClick={onPublish}
          themeColor={'warning'}
          disabled={['', 'Pending'].includes(gameStatus)}
          className={'me-1'}>
          Publish
        </Button>
      </Toolbar>
      <div className={'d-flex mt-2 flex-column flex-xl-row'}>
        <Container className={'py-2 m-0 pl-0'}>
          <Row className={'gy-2 ml-0'}>
            <Col className={'info-box'}>
              To submit this game to the global library, you must provide
              mandatory sales and marketing information. Use this opportunity to
              explain the merits of your game.
            </Col>
          </Row>
          <Row className={'gy-2'}>
            <Col>
              <TextareaInput
                label={'About*'}
                name={'About'}
                value={marketingData.about}
                onChange={(e) => handleChange(e, 'about')}
                rows={4}
                maxLength={2048}
              />
            </Col>
          </Row>
          <Row className={'mt-2 gy-2'}>
            <Col xs={12} xl={4}>
              <FieldWrapper>
                <FormInput
                  className={'text-success'}
                  label={'Recommended duration*'}
                  name={'Recommended duration - Sales'}
                  value={marketingData.recommendedDuration}
                  onChange={(e) => handleChange(e, 'recommendedDuration')}
                />
              </FieldWrapper>
            </Col>
            <Col xs={12} xl={4}>
              <FieldWrapper>
                <FormInput
                  className={'text-success'}
                  label={'Recommended Number of Players*'}
                  value={marketingData.recommendedPlayers}
                  name={'Recomended Number of Players - Sales'}
                  onChange={(e) => handleChange(e, 'recommendedPlayers')}
                />
              </FieldWrapper>
            </Col>
            <Col xs={12} xl={4}>
              <FieldWrapper>
                <FormInput
                  className={'text-success'}
                  label={'Players per team*'}
                  name={'Players per team - Sales'}
                  value={marketingData.playersPerTeam}
                  onChange={(e) => handleChange(e, 'playersPerTeam')}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row className={'gy-2 mt-3'}>
            <Col>
              <TextareaInput
                label={'How it works*'}
                name={'How it works'}
                value={marketingData.howItWorks}
                onChange={(e) => handleChange(e, 'howItWorks')}
                rows={4}
                maxLength={2048}></TextareaInput>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} className="d-flex flex-column">
              <Label>Key selling points*</Label>
              {marketingData.sellingPoints.map((item, index) => (
                <div
                  className="d-flex mt-2 align-items-center gap-2"
                  key={index}>
                  <FormInput
                    name={'Key selling points'}
                    value={item}
                    onChange={(event) =>
                      handleChangeList(event, 'sellingPoints', index)
                    }
                    required={true}
                    key={index}
                  />
                  {marketingData.sellingPoints.length > 1 && (
                    <Button
                      fillMode="flat"
                      onClick={() => handleRemoveList('sellingPoints', index)}>
                      <span className="material-symbols-outlined text-danger">
                        delete
                      </span>
                    </Button>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={12} className="d-flex mt-2 justify-content-end">
              <Button
                themeColor={'success'}
                onClick={() => handleAddList('sellingPoints')}>
                Add
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} className="d-flex flex-column">
              <Label>Learning outcomes*</Label>
              {marketingData.learningOutcome.map((item, index) => (
                <div
                  className="d-flex mt-2 align-items-center gap-2"
                  key={index}>
                  <FormInput
                    name={'Learning outcome'}
                    value={item}
                    onChange={(event) =>
                      handleChangeList(event, 'learningOutcome', index)
                    }
                    required={true}
                    key={index}
                  />
                  {marketingData.learningOutcome.length > 1 && (
                    <Button
                      fillMode="flat"
                      onClick={() =>
                        handleRemoveList('learningOutcome', index)
                      }>
                      <span className="material-symbols-outlined text-danger">
                        delete
                      </span>
                    </Button>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={12} className="d-flex mt-2 justify-content-end">
              <Button
                themeColor={'success'}
                onClick={() => handleAddList('learningOutcome')}>
                Add
              </Button>
            </Col>
          </Row>

          <Row className={'mt-2 gy-2'}>
            <Col xs={12}>
              <Label>Media</Label>
            </Col>
            <Col xs={12}>
              <div className="video-wrapper">
                {previewItemType === 'video' && (
                  <video
                    key={previewItem}
                    className="video"
                    loop
                    controls
                    preload="none">
                    <source src={previewItem} type="video/mp4"></source>
                  </video>
                )}
                {previewItemType === 'image' && (
                  <img src={previewItem} className={'img-fluid'} />
                )}

                {previewItemType === 'pdf' && (
                  <PdfViewerComponent source={previewItem}></PdfViewerComponent>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <div className={'thumb-nails d-flex flex-wrap'}>
                {marketingData.promotionalMedias?.length! > 0 &&
                  marketingData.promotionalMedias?.map(
                    (file: PromotionalMedia, index: number) => (
                      <div className={'d-flex'} key={index}>
                        <div
                          key={index}
                          className={
                            'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center cursor-pointer'
                          }
                          onClick={() =>
                            onClickMedia({
                              url: file.mediaUrl,
                              type: _.lowerCase(file.mediaType)
                            })
                          }>
                          {file && _.lowerCase(file.mediaType) === 'video' && (
                            <div
                              className={
                                'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                              }
                              style={{
                                backgroundImage: `url("${file.mediaUrl}")`,
                                backgroundPosition: 'center'
                              }}>
                              <button className={'btn text-light'}>
                                <span className={'material-symbols-outlined'}>
                                  play_arrow
                                </span>
                              </button>
                            </div>
                          )}
                          {file && _.lowerCase(file.mediaType) === 'image' && (
                            <img
                              src={file.mediaUrl ?? tempThumbnailUrl}
                              className={'rounded'}
                              alt={'media'}
                            />
                          )}

                          {file && _.lowerCase(file.mediaType) === 'pdf' && (
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: '50px' }}>
                              picture_as_pdf
                            </span>
                          )}
                        </div>
                        <PopupMenu
                          menus={menuList}
                          id={Math.floor(Math.random() * (30 - 1 + 1)) + 1}
                          onMenuSelected={onMenuSelected}
                          idString={index.toString()}
                        />
                      </div>
                    )
                  )}

                <div
                  className={
                    'w-8 h-8 border border-1 bg-light rounded d-flex flex-column justify-content-center align-items-center cursor-pointer text-decoration-none'
                  }
                  onClick={onAddMedia}>
                  <span className={'material-symbols-outlined text-primary'}>
                    add_photo_alternate
                  </span>
                  Add
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {entityEditorIsVisible && (
        <ResourceWindow
          toggleDialog={toggleEntityEditor}
          onSubmit={(uploadedFile) =>
            handleEntityEditorSubmit(uploadedFile, fileUpload!)
          }
          acceptedExtension={extensionUploadedFile}
          imageUrl={selectedImageUrl}
          imageSource={'Media'}
        />
      )}

      {showDeleteMediaConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteMedia}
          onClose={() => setShowDeleteMediaConfirm(false)}>
          Are you sure you want to remove the image/video?
        </YesNoDialog>
      )}

      {showDialog && (
        <Dialog
          width={'50vw'}
          title={
            <span className="fw-bold text-primary">
              Submit to global library
            </span>
          }
          onClose={toggleDialog}>
          <SubmitConfirmation
            toggleDialog={toggleDialog}
            isPendingApproval={gameStatus === 'Pending'}
            onSubmit={onSubmitToGlobal}
          />
        </Dialog>
      )}
    </>
  );
};

export default GameSalesMarketing;
