import { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import {
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Col, Container, Row } from 'react-bootstrap';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { TextareaInput } from '../../components/form-input';
import { StandardInput } from '../../components/forms';
import PopupMenu, { popupMenu as MenuList } from '../../components/popupMenu';
import { GameDocumentContext } from '../../contexts/game-document';
import { uuid } from '../../types/common-helper';
import {
  GameDocument,
  MediaOverview,
  Overview
} from '../../types/game-document';
import { ResourceEntity } from '../../types/game-document/';
import { LibraryCreateRequest, MediaLibrary } from '../../types/library';
import { StringifiedType } from '../../types/stringified-type';
import {
  AddResourceAsync,
  GetResourceEntity,
  GetResourceValue,
  UpdateGameDocState,
  UpdateResourceAsync
} from '../../utils/game-document';
import Toolbar from './toolbar';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import { Summary } from '../../components/overview';
import { useParams } from 'react-router-dom';
import { formatDateToDDMMYYYY } from '../../utils/date';
import CoverImage from '../../components/cover-image';
import LanguageComboBox from '../../components/combobox/language-combobox';
import { GameResponse } from '../../types/responses/game-response';
import { isEmpty } from 'lodash';
import { YesNoDialogResult } from '../../types/dialog-result';
import { useGameDocumentResources } from '../../hooks/use-game-document-resources';
import {
  DisplayLanguagePublished,
  GetDisplayLanguagePublished
} from '../../services/admin-display-language';
import MediaPreviewDialog from '../../components/dialogs/media-preview-dialog';
import { HtmlEditor } from '../../components/editor/html-editor';
import { GetGameSummaryAsync } from '../../services/games';
import { GameSummaryResponse } from '../../types/responses/game-summary-response';

type FileUpload = 'Background' | 'Logo' | 'Media';
export interface FileUrl {
  url?: string;
  type?: string;
}

export interface RequiredInput {
  name: string;
  errorMessage: string;
  minLength?: number;
  maxLength?: number;
}

export interface GameOverview {
  title?: string;
  longDescription?: string;
  shortDescription?: string;
}

const GameOverview = () => {
  const { gameId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const [localistation, setLocalisation] = useState<GameResponse>({
    languages: state.gameDocument?.overview?.languages,
    displayLanguages: state.gameDocument?.overview?.displayLanguages
  });

  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const [mediaFiles, setMediaFiles] = useState<MediaLibrary[]>([]);
  useState<StringifiedType<LibraryCreateRequest>>();
  const maxTitle = 256;
  const maxShortDescription = 256;
  const maxLongDescription = 2048;
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const [showDeleteMediaConfirm, setShowDeleteMediaConfirm] =
    useState<boolean>(false);
  const [tempThumbnailUrl, setTempThumbnailUrl] = useState<string>('');
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [extensionUploadedFile, setExtensionUploadedFile] =
    useState<string>('');
  const [deleteMediaResourceIndex, setDeleteMediaResourceIndex] =
    useState<string>('');
  const [editMediaResourceIndex, setEditMediaResourceIndex] =
    useState<string>('');
  const [isEditMedia, setIsEditMedia] = useState<boolean>(false);
  const [gameSummary, setGameSummary] = useState<GameSummaryResponse>({});
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    { name: 'Title', errorMessage: '' },
    { name: 'Name', errorMessage: '' },
    {
      name: 'Short description',
      errorMessage: '',
      maxLength: 256
    },
    {
      name: 'Information for Players',
      errorMessage: '',
      maxLength: 2048
    }
  ]);
  const [displayLanguages, setDisplayLanguages] = useState<
    DisplayLanguagePublished[]
  >([]);

  const [gameOverview, setGameOverview] = useState<GameOverview | undefined>();

  const generateMediaPopupMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setMenuList(newMenus);
  };

  const onConfirmDeleteMedia = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      deleteMedia();
    }

    setShowDeleteMediaConfirm(false);
  };

  const deleteMedia = () => {
    let newMedia = { ...state!.gameDocument! };

    newMedia.overview!.medias.splice(parseInt(deleteMediaResourceIndex), 1);

    setState((prev) => UpdateGameDocState(prev, newMedia));

    clearMediaPreview();
  };

  const onMenuSelected = (id: number, menu: MenuList, idString?: string) => {
    setFileUpload('Media');
    if (menu.textMenu === 'Delete') {
      setIsEditMedia(false);
      setDeleteMediaResourceIndex(idString!);
      setShowDeleteMediaConfirm(true);
    }
    if (menu.textMenu === 'Edit') {
      setEditMediaResourceIndex(idString!);

      let resourceId =
        state.gameDocument?.overview?.medias[parseInt(idString!)].value;

      setSelectedImageUrl(GetResourceValue(state.gameDocument!, resourceId!));
      setIsEditMedia(true);
      clearMediaPreview();

      setEntityEditorIsVisible(!entityEditorIsVisible);
    }
  };

  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  // events for the input fields
  const onUpdateLocalisations = (event: ComboBoxChangeEvent) => {
    const { name } = event.target as {
      name: keyof Overview;
    };

    setLocalisation((prevLocalisation) => ({
      ...prevLocalisation,
      [name]: event.value
    }));

    setState((prev) => {
      const localisation = { ...prev };
      localisation!.isDirty = true;
      localisation!.gameDocument!.overview![name] = event.value as never;
      if (name === 'displayLanguages') {
        localisation!.gameDocument!.overview!.displayLanguagesUrl =
          displayLanguages.find(
            (i) => i.languageName === event.value
          )?.blobFileUrl;
      }
      return localisation;
    });
  };

  const getResourceByName = (name: string) =>
    state.gameDocument?.resources?.find((f) => f.name === name)!;

  const onUpdateResource = (resource: ResourceEntity) => {
    UpdateResourceAsync(state.gameDocument!, resource.id, resource).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const onUpdateGameDocumentName = (
    gameDocument: GameDocument,
    title: string
  ) => {
    let newGame = gameDocument;
    newGame.name = title;
    setState((state) => UpdateGameDocState(state, newGame, true));
  };

  const onUpdatePlayersPerTeam = (event: InputChangeEvent) => {
    setState((prev) => {
      const newPlayersPerTeams = { ...prev };
      newPlayersPerTeams!.isDirty = true;
      newPlayersPerTeams!.gameDocument!.overview!.playersPerTeam! =
        parseInt(event.value) < 0 ? 0 : parseInt(event.value);
      return newPlayersPerTeams;
    });
  };

  const toggleEntityEditor = () =>
    setEntityEditorIsVisible(!entityEditorIsVisible);

  const clearMediaPreview = () => {
    setPreviewItemType('');
    setPreviewItem('');
  };

  const handleEntityEditorSubmit = (
    uploadedImage: UploadedImage,
    target: FileUpload
  ) => {
    clearMediaPreview();
    //append into resources
    const resourceId = uuid();

    if (target === 'Media') {
      const resourceType = uploadedImage?.mimeType?.split('/')[0];

      if (!uploadedImage.resource) {
        AddResourceAsync(
          state.gameDocument!,
          resourceId,
          uploadedImage?.fileName,
          resourceType,
          uploadedImage.blobUrl,
          resourceId,
          uploadedImage.size
        ).then((response) =>
          setState((prev) => UpdateGameDocState(prev, response))
        );
      }

      if (!isEditMedia) {
        state.gameDocument?.overview?.medias.push({
          type: resourceType,
          value: uploadedImage.resource
            ? uploadedImage.resource.id!
            : resourceId
        });
      } else {
        let newMedia = { ...state!.gameDocument! };
        newMedia.overview!.medias[parseInt(editMediaResourceIndex)].value =
          uploadedImage.resource ? uploadedImage.resource.id! : resourceId;

        setState((prev) => UpdateGameDocState(prev, newMedia));

        setIsEditMedia(false);
        setEditMediaResourceIndex('');
      }
    }

    if (target === 'Background') {
      if (
        state.gameDocument?.overview?.headerResId &&
        state.gameDocument?.overview?.headerResId !== ''
      ) {
        // Update background resource
        if (uploadedImage.resource) {
          state.gameDocument!.overview!.headerResId = uploadedImage.resource.id;
        }
        gameDocumentFiles.updateResourceFile(
          state.gameDocument.overview.headerResId!,
          uploadedImage
        );
      } else {
        state.gameDocument!.overview!.headerResId = resourceId;
        // New resource
        AddResourceAsync(
          state.gameDocument!,
          uploadedImage?.fileName,
          uploadedImage?.fileName,
          'image',
          uploadedImage.blobUrl,
          resourceId,
          uploadedImage.size
        ).then((response) =>
          setState((prev) => UpdateGameDocState(prev, response))
        );
      }
    }

    if (target === 'Logo') {
      if (
        state.gameDocument?.overview?.logoResId &&
        state.gameDocument?.overview?.logoResId !== ''
      ) {
        if (uploadedImage.resource) {
          state.gameDocument!.overview!.logoResId = uploadedImage.resource.id;
        }
        gameDocumentFiles.updateResourceFile(
          state.gameDocument.overview.logoResId!,
          uploadedImage
        );
      } else {
        state.gameDocument!.overview!.logoResId = resourceId;
        // New resource
        AddResourceAsync(
          state.gameDocument!,
          uploadedImage?.fileName,
          uploadedImage?.fileName,
          'image',
          uploadedImage.blobUrl,
          resourceId,
          uploadedImage.size
        ).then((response) =>
          setState((prev) => UpdateGameDocState(prev, response))
        );
      }
    }

    if (entityEditorIsVisible) {
      setEntityEditorIsVisible(false);
    }
  };

  const onEditLogo = () => {
    setFileUpload('Logo');
    setSelectedImageUrl(
      GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.overview?.logoResId!
      )
    );
    setExtensionUploadedFile('image/*');
    setEntityEditorIsVisible(true);
  };
  const onEditBackground = () => {
    setFileUpload('Background');
    setSelectedImageUrl(
      GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.overview?.headerResId!
      )
    );
    setExtensionUploadedFile('image/*');
    setEntityEditorIsVisible(true);
  };

  const onAddMedia = () => {
    setFileUpload('Media');
    setExtensionUploadedFile('image/*, video/*');
    setEntityEditorIsVisible(true);
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));

      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url as any);
      }
    }
  };

  const generateUrlFiles = () => {
    if (mediaFiles) {
      mediaFiles.forEach((file) => {
        if (file.localBlobUrl === '' || file.localBlobUrl === undefined) {
          if (file.mediaFile) {
            const blobUrl = URL.createObjectURL(file.mediaFile as Blob);
            file.localBlobUrl = blobUrl;
            setTempThumbnailUrl(blobUrl);
            setMediaFiles(mediaFiles);
          } else {
            if (file.mediaId && !file.mediaFile) {
              if (!file.mimeType) return;
            }
          }
        }
      });
    }
  };

  const fieldValidation = (
    key: string,
    value: string,
    maxLength?: number
  ): boolean => {
    if (value === '') {
      setRequiredInputs(
        requiredInputs.map((item) =>
          item.name === key
            ? {
                name: item.name,
                errorMessage:
                  key === 'Short description'
                    ? `Input Description is required`
                    : `Input ${key} is required`
              }
            : item
        )
      );
      return false;
    }

    if (maxLength) {
      if (value.length > maxLength) {
        requiredInputs.map((item) =>
          item.name === key
            ? {
                name: item.name,
                errorMessage:
                  key === 'Short description'
                    ? `Input Description is maximum length ${maxLength} characters`
                    : `Input ${key} is maximum length ${maxLength} characters`
              }
            : item
        );
        return false;
      }
    }

    setRequiredInputs(
      requiredInputs.map((item) =>
        item.name === key
          ? {
              name: item.name,
              errorMessage: ''
            }
          : item
      )
    );

    return true;
  };

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    const itemIndex = requiredInputs.findIndex((item) => item.name === name);
    let isValid = true;
    if (itemIndex !== -1) {
      // validation needed
      isValid =
        fieldValidation(name!, value as string) &&
        !isEmpty(
          GetResourceValue(
            state.gameDocument!,
            state.gameDocument?.overview?.shortDescriptionResId!
          )
        ) &&
        !isEmpty(
          GetResourceValue(
            state.gameDocument!,
            state.gameDocument?.overview?.longDescriptionResId!
          )
        );
    }
    let resource = getResourceByName(name!);

    if (resource) {
      resource.value = event.target.value?.toString();
      onUpdateResource(resource);
      if (gameOverview) {
        if (name === 'Title') {
          gameOverview.title = event.target.value?.toString();
        }

        setGameOverview(gameOverview);
      }
    }

    if (name === 'Name') {
      //Overview title changes also update into gameDocument.name
      onUpdateGameDocumentName(state.gameDocument!, value as string);
    }

    setState((prev) => {
      const newState = { ...prev };
      newState!.isValid = isValid;
      newState.isDirty = true;
      return newState;
    });
  };

  const handleTextAreaChange = (event: TextAreaChangeEvent) => {
    const itemRequired = requiredInputs.find(
      (item) => item.name === event.target.name
    );
    let isValid = true;
    if (itemRequired!) {
      // validation needed
      isValid = fieldValidation(
        event.target.name!,
        event.value as string,
        itemRequired.maxLength as number
      );
      if (event.target.name === 'Short description') {
        isValid =
          isValid &&
          !isEmpty(
            GetResourceValue(
              state.gameDocument!,
              state.gameDocument?.overview?.titleResId!
            )
          ) &&
          !isEmpty(
            GetResourceValue(
              state.gameDocument!,
              state.gameDocument?.overview?.longDescriptionResId!
            )
          );
      } else {
        isValid =
          isValid &&
          !isEmpty(
            GetResourceValue(
              state.gameDocument!,
              state.gameDocument?.overview?.titleResId!
            )
          ) &&
          !isEmpty(
            GetResourceValue(
              state.gameDocument!,
              state.gameDocument?.overview?.shortDescriptionResId!
            )
          );
      }
    }
    let resource = getResourceByName(event.target.name!);
    if (resource) {
      resource.value = event.value;
      const resourceEntity = resource as ResourceEntity;
      onUpdateResource(resourceEntity);

      if (gameOverview) {
        if (event.target.name === 'Short description') {
          gameOverview.shortDescription = event.target.value?.toString();
        }
      }
    }

    setState((prev) => {
      const newState = { ...prev };
      newState!.isValid = isValid;
      return newState;
    });
  };

  const HandleHtmlEditorChange = (value: string, name: string) => {
    const itemRequired = requiredInputs.find((item) => item.name === name);
    let isValid = true;
    if (itemRequired!) {
      // validation needed
      isValid = fieldValidation(
        name,
        value as string,
        itemRequired.maxLength as number
      );
    }

    let resource = getResourceByName(name);
    if (resource) {
      resource.value = value;
      const resourceEntity = resource as ResourceEntity;
      onUpdateResource(resourceEntity);

      if (gameOverview) {
        if (name === 'Long description') {
          gameOverview.longDescription = value;
        }

        setGameOverview(gameOverview);
      }
    }

    setState((prev) => {
      const newState = { ...prev };
      newState!.isValid = isValid;
      return newState;
    });
  };

  const getDisplayLanguages = async () => {
    const response = await GetDisplayLanguagePublished();
    setDisplayLanguages(response.data);
  };

  useEffect(() => {
    if (state.gameId) {
      document.title = `${state.gameDocument?.name}`;
      getDisplayLanguages();

      //check if the overview object doesn't exist
      if (
        state.gameDocument &&
        !state.gameDocument?.hasOwnProperty('overview')
      ) {
        const emptyOverview: Overview = {
          titleResId: uuid(),
          languages: '',
          displayLanguages: '',
          displayLanguagesUrl: '',
          longDescriptionResId: uuid(),
          shortDescriptionResId: uuid(),
          playersPerTeam: 0,
          learningOutcomeResId: uuid(),
          recommendedDurationResId: uuid(),
          recommendedNumberOfPlayersResId: uuid(),
          logoResId: '',
          headerResId: '',
          medias: []
        };

        setState((prev) => {
          const newState = { ...prev };
          newState!.gameDocument!.overview = emptyOverview;
          newState!.gameDocument!.resources.push(
            {
              id: emptyOverview.titleResId ?? '',
              description: '',
              name: 'Title',
              type: 'text',
              value: ''
            },
            {
              id: emptyOverview.longDescriptionResId ?? '',
              description: '',
              name: 'Long description',
              type: 'text',
              value: ''
            },
            {
              id: emptyOverview.shortDescriptionResId ?? '',
              description: '',
              name: 'Short description',
              type: 'text',
              value: ''
            },
            {
              id: emptyOverview.learningOutcomeResId ?? '',
              description: '',
              name: 'Learning outcome',
              type: 'textarea',
              value: ''
            },
            {
              id: emptyOverview.recommendedDurationResId ?? '',
              description: '',
              name: 'Recommended duration',
              type: 'text',
              value: ''
            },
            {
              id: emptyOverview.recommendedNumberOfPlayersResId ?? '',
              description: '',
              name: 'Recommended number of players',
              type: 'text',
              value: ''
            }
          );
          return newState;
        });
      }
    }

    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    generateMediaPopupMenu();
  }, []);

  useEffect(() => {
    if (state.gameDocument && !gameOverview) {
      let overviewData: GameOverview = {};
      overviewData.title = GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.overview?.titleResId!
      );

      overviewData.shortDescription = GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.overview?.shortDescriptionResId!
      );

      overviewData.longDescription = GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.overview?.longDescriptionResId!
      );

      setGameOverview(overviewData);

      UpdateLongDescriptionTypeResource();
    }
  }, [state.gameDocument]);

  useEffect(() => {
    generateUrlFiles();
    //eslint-disable-next-line
  }, [mediaFiles]);

  const getGameSummary = () => {
    if (gameId && gameId !== 'new') {
      GetGameSummaryAsync(parseInt(gameId)).then((response) => {
        setGameSummary(response);
      });
    }
  };

  const UpdateLongDescriptionTypeResource = () => {
    let resource = GetResourceEntity(
      state.gameDocument!,
      state.gameDocument?.overview?.longDescriptionResId!
    );

    if (resource.type !== 'text-html') {
      resource.type = 'text-html';
      const resourceEntity = resource as ResourceEntity;
      onUpdateResource(resourceEntity);
    }
  };

  useEffect(() => {
    setLocalisation({
      languages: state.gameDocument?.overview?.languages,
      displayLanguages: state.gameDocument?.overview?.displayLanguages
    });
    //eslint-disable-next-line
  }, [state.gameDocument?.overview?.languages]);

  useEffect(() => {
    getGameSummary();
    //eslint-disable-next-line
  }, [gameId, state.gameDocument?.version]);

  const htmlEditor = useMemo(
    () => (
      <HtmlEditor
        value={gameOverview?.longDescription ?? ''}
        handleHtmlChange={(e) => {
          HandleHtmlEditorChange(e.html, 'Long description');
        }}
      />
    ),
    [gameOverview?.longDescription]
  );

  return (
    <>
      <Toolbar title={'Overview'} />
      <div className={'d-flex mt-2 flex-column flex-xl-row'}>
        <Container className="pl-0">
          <Row className={'mt-2'}>
            <Col>
              <Row className={'gy-2'}>
                <StandardInput
                  label={'Name'}
                  name={'Name'}
                  value={state.gameDocument?.name}
                  onChange={handleInputChange}
                  required={true}
                  validationMessage={
                    requiredInputs?.find((item) => item.name === 'Name')
                      ?.errorMessage
                  }
                  maxLength={maxTitle}
                />

                <Col>
                  <TextareaInput
                    label={'Description'}
                    name={'Short description'}
                    value={gameOverview?.shortDescription ?? ''}
                    onChange={handleTextAreaChange}
                    rows={4}
                    maxLength={maxShortDescription}
                    error={
                      requiredInputs?.find(
                        (item) => item.name === 'Short description'
                      )?.errorMessage
                    }></TextareaInput>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={'mt-2 gy-2'}>
            <Col>
              <FieldWrapper>
                <Label>Language</Label>
                <LanguageComboBox
                  name={'languages'}
                  onChange={(e) => onUpdateLocalisations(e)}
                  clearButton={false}
                  value={localistation.languages}
                  isDisplayLanguages={true}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <Label>Display Language</Label>
                <LanguageComboBox
                  isDisplayLanguages={true}
                  name={'displayLanguages'}
                  onChange={(e) => onUpdateLocalisations(e)}
                  clearButton={false}
                  value={localistation.displayLanguages}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col xs={12}>
              <Label>Game Info</Label>
              <div className="info-box">
                This information is displayed to players on the info tab pregame
                and during the game. Include all relevant information and
                guidance.
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CoverImage
                imageUrl={
                  state.gameDocument! !== undefined
                    ? GetResourceValue(
                        state.gameDocument!,
                        state.gameDocument?.overview?.headerResId!
                      )
                    : ''
                }
                onDropFiles={(uploadedFile) =>
                  handleEntityEditorSubmit(uploadedFile, 'Background')
                }>
                <Button fillMode={'flat'} onClick={onEditBackground}>
                  <span className={'material-symbols-outlined'}>more_vert</span>
                </Button>
              </CoverImage>
            </Col>
          </Row>
          <Row className={'mt-2'}>
            <Col xs={'auto'}>
              <Label>Icon</Label>
              <CoverImage
                imageUrl={
                  state.gameDocument! !== undefined
                    ? GetResourceValue(
                        state.gameDocument!,
                        state.gameDocument?.overview?.logoResId!
                      )
                    : ''
                }
                containerStyle={'rounded'}
                onDropFiles={(uploadedFile) =>
                  handleEntityEditorSubmit(uploadedFile, 'Logo')
                }
                isfullWidth={false}>
                <Button fillMode={'flat'} onClick={onEditLogo} rounded={'full'}>
                  <span className={'material-symbols-outlined'}>more_vert</span>
                </Button>
              </CoverImage>
            </Col>
            <Col>
              <StandardInput
                label={'Title'}
                name={'Title'}
                value={gameOverview?.title ?? ''}
                onChange={handleInputChange}
                required={true}
                validationMessage={
                  requiredInputs?.find((item) => item.name === 'Title')
                    ?.errorMessage
                }
                maxLength={maxTitle}
              />
              <Col xs={12} className="mt-2">
                <Col sm={12} md={12}>
                  <Label>Information for Players</Label>
                  {htmlEditor}
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className={'mt-2 gy-2'}>
            <Col xs={12}>
              <Label>Game Media</Label>
            </Col>
            <Col xs={12}>
              <div className={'thumb-nails d-flex flex-wrap'}>
                {state.gameDocument?.overview?.medias?.length! > 0 &&
                  state.gameDocument?.overview?.medias?.map(
                    (file: MediaOverview, index: number) => (
                      <div className={'d-flex'} key={index}>
                        <div
                          key={index}
                          className={
                            'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center cursor-pointer'
                          }
                          onClick={() =>
                            onClickMedia({
                              url: state.gameDocument?.resources.find(
                                (f) => f.id === file.value
                              )?.value as string,
                              type: file.type
                            })
                          }>
                          {file && file.type === 'video' && (
                            <div
                              className={
                                'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                              }
                              style={{
                                backgroundImage: `url("${
                                  state.gameDocument?.resources.find(
                                    (f) => f.id === file.thumbnailValue
                                  )?.value ?? ''
                                }")`,
                                backgroundPosition: 'center'
                              }}>
                              <button className={'btn text-light'}>
                                <span className={'material-symbols-outlined'}>
                                  play_arrow
                                </span>
                              </button>
                            </div>
                          )}
                          {file && file.type === 'image' && (
                            <img
                              src={
                                state.gameDocument?.resources.find(
                                  (f) => f.id === file.value
                                )?.value ?? tempThumbnailUrl
                              }
                              className={'rounded'}
                              alt={''}
                            />
                          )}
                        </div>
                        <PopupMenu
                          menus={menuList}
                          id={Math.floor(Math.random() * (30 - 1 + 1)) + 1}
                          onMenuSelected={onMenuSelected}
                          idString={index.toString()}></PopupMenu>
                      </div>
                    )
                  )}
                <div
                  className={
                    'w-8 h-8 border border-1 bg-light rounded d-flex flex-column justify-content-center align-items-center cursor-pointer text-decoration-none'
                  }
                  onClick={onAddMedia}>
                  <span className={'material-symbols-outlined text-primary'}>
                    add_photo_alternate
                  </span>
                  Add
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Summary
          author={gameSummary?.createdBy}
          authorAvatar={gameSummary?.creatorAvatar}
          createdDate={
            gameSummary?.createdDateUtc &&
            formatDateToDDMMYYYY(gameSummary.createdDateUtc, true)
          }
          updateDate={
            gameSummary?.modifiedDateUtc &&
            formatDateToDDMMYYYY(gameSummary.modifiedDateUtc, true)
          }
          version={gameSummary?.version}
          gameDocument={state?.gameDocument}
          isGameOverview={true}
        />
      </div>

      {entityEditorIsVisible ? (
        <ResourceWindow
          toggleDialog={toggleEntityEditor}
          onSubmit={(uploadedFile) =>
            handleEntityEditorSubmit(uploadedFile, fileUpload!)
          }
          acceptedExtension={extensionUploadedFile}
          imageUrl={selectedImageUrl}
          imageSource={
            fileUpload === 'Background'
              ? 'Game Design | Overview - Banner'
              : fileUpload === 'Logo'
                ? 'Game Design | Overview - Game Icon'
                : fileUpload === 'Media'
                  ? 'Game Design | Overview - Media'
                  : undefined
          }
        />
      ) : null}

      {previewItem && previewItemType && (
        <MediaPreviewDialog
          title="Media Preview"
          mediaType={previewItemType}
          mediaUrl={previewItem}
          onClose={() => {
            setPreviewItem('');
            setPreviewItemType('');
          }}
        />
      )}

      {showDeleteMediaConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteMedia}
          onClose={() => setShowDeleteMediaConfirm(false)}>
          Are you sure you want to remove the image/video?
        </YesNoDialog>
      )}
    </>
  );
};

export default GameOverview;
