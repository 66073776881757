import { GameMarketing } from '../types/marketing';
import { DeleteAsync, GetAsync, PatchAsync, PostAsync } from './api';

const GameMarketingEndpoint = (gameId: number) => `games/${gameId}/marketing`;

/**
 * Gets the marketing data of a specific game.
 * @param gameId The ID of the game.
 * @returns The game marketing data object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameMarketingAsync = async (gameId: number) =>
  GetAsync<GameMarketing>(GameMarketingEndpoint(gameId));

/**
 * Creates marketing data for a specific game.
 * @param gameId The ID of the game.
 * @param data The data to be created.
 * @returns The created game marketing data object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const CreateGameMarketingAsync = async (gameId: number, data: any) =>
  PostAsync<GameMarketing>(GameMarketingEndpoint(gameId), data);

/**
 * Updates marketing data for a specific game.
 * @param gameId The ID of the game.
 * @param data The data to be updated.
 * @returns The updated game marketing data object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateGameMarketingAsync = async (gameId: number, data: any) =>
  PatchAsync<GameMarketing>(GameMarketingEndpoint(gameId), data);

/**
 * Deletes marketing data for a specific game.
 * @param gameId The ID of the game.
 * @returns A success response or confirmation.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteGameMarketingAsync = async (gameId: number) =>
  DeleteAsync<GameMarketing>(GameMarketingEndpoint(gameId));
