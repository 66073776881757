import {
  ApiBaseUrl,
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  GetBearerHeader,
  jsonHeaders,
  PatchAsync,
  PostAsync,
  PutAsync
} from './api';
import { GameResponse } from '../types/responses/game-response';
import {
  AddGameDocumentAsync,
  AddGameDocumentContentAsync,
  GetApprovedGameDocumentContentAsync,
  GetApprovedGameDocumentLatestAsync,
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from './json-document';
import { Theme } from '../types/theme';
import { GetResourceEntity, UpdateResourceAsync } from '../utils/game-document';
import { GameDocumentVersionResponse } from '../types/responses/game-document-version-response';
import { GameDocument } from '../types/game-document';
import { Resource } from '../types/game-document/entities/resource';
import { GameSummaryResponse } from '../types/responses/game-summary-response';

const GamesEndpoint = `games`;
const EventGamesEndpoint = `event/games`;

/**
 * Add Game encapsulated in a paged payload.
 * @returns Game object.
 * @param game - the game object
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const AddGamesAsync = async (game: GameResponse) =>
  PostAsync<GameResponse>(GamesEndpoint, game);

/**
 * Gets ALL Games encapsulated in a paged payload.
 * @returns all Game objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGamesAsync = async (params?: string) =>
  GetAllAsync<GameResponse>(`${GamesEndpoint}?${params}`);

/**
 * Gets ALL published Games encapsulated in a paged payload.
 * @returns all Game objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetPublishedGamesAsync = async (params?: string) =>
  GetAllAsync<GameResponse>(`${EventGamesEndpoint}?${params}`);

/**
 * Gets ALL Aprroved Games encapsulated in a paged payload.
 * @returns all Aprroved Game objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGlobalGamesAsync = async (params?: string) =>
  GetAllAsync<GameResponse>(`library/${GamesEndpoint}?${params}`);

/**
 * Gets ALL Games from organisation encapsulated in a paged payload.
 * @returns all Game objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGamesOrganisationAsync = async (
  params?: string,
  organisationId?: number
) =>
  GetAllAsync<GameResponse>(
    `organisations/${organisationId}/${GamesEndpoint}?${params}`
  );

/**
 * Gets ALL Pending Games encapsulated in a paged payload.
 * @returns all Game objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetPendingGamesAsync = async (params?: string) =>
  GetAllAsync<GameResponse>(`admin/${GamesEndpoint}?${params}`);

/**
 * Gets the Game by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameAsyncById = async (gameId: number) =>
  GetAsync<GameResponse>(`${GamesEndpoint}/${gameId}`);

/**
 * Gets the Game summary by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game Summary object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameSummaryAsync = async (gameId: number) =>
  GetAsync<GameSummaryResponse>(`${GamesEndpoint}/${gameId}/summary`);

/**
 * Gets the Game summary by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game Summary object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameSummaryAsAdminAsync = async (gameId: number) =>
  GetAsync<GameSummaryResponse>(`admin/${GamesEndpoint}/${gameId}/summary`);

/**
 * Gets the Game summary by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game Summary object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetApprovedSummaryAsync = async (gameId: number) =>
  GetAsync<GameSummaryResponse>(`library/${GamesEndpoint}/${gameId}/summary`);

/**
 * Gets the Approved Game by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetApprovedGameAsyncById = async (gameId: number) =>
  GetAsync<GameResponse>(`library/${GamesEndpoint}/${gameId}`);

/**
 * Gets the Pending Game by id.
 * @param gameId - the ID of the Game to retrieve
 * @returns the Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetPendingGameAsyncById = async (gameId: number) =>
  GetAsync<GameResponse>(`admin/${GamesEndpoint}/${gameId}`);

/**
 * Updates the Game by completely replacing it.
 * @param gameId - the ID of the entity to update [PUT]
 * @param game - the Game to update [PUT]
 * @returns the updated Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PatchGameAsync = async (
  gameId: number,
  game: any | GameResponse
) => PatchAsync<GameResponse>(`${GamesEndpoint}/${gameId}`, game);

/**
 * Applies a PATCH to the Game. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param gameId - the ID of the Game to PATCH
 * @param game - the Game or partial Game to update [PATCH]
 * @returns the updated Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PutGameAsync = async (gameId: number, game: GameResponse) =>
  PutAsync<GameResponse>(`${GamesEndpoint}/${gameId}`, game);

/**
 * Deletes a Game by id.
 * @param gameId - the ID of the Game to DELETE
 * @returns the deleted Game object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteGameAsync = async (gameId: number) =>
  DeleteAsync<GameResponse>(`${GamesEndpoint}/${gameId}`);

/**
 * Gets the Game Document by id.
 * @param gameId - the ID of the Game Document to retrieve
 * @returns the Game Document object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameDocumentAsync = async (gameId: number) =>
  GetAsync<GameResponse>(`${GamesEndpoint}/${gameId}/document`);

export const CopyGameAsync = async (
  gameId: number,
  isFromGlobalLibrary: boolean
) => {
  let game: GameResponse;
  let gameDocumentLatest: GameDocumentVersionResponse;
  let gameDocumentContent: GameDocument;
  if (isFromGlobalLibrary) {
    game = await GetApprovedGameAsyncById(gameId);
    gameDocumentLatest = await GetApprovedGameDocumentLatestAsync(gameId);
    gameDocumentContent = await GetApprovedGameDocumentContentAsync(
      gameId,
      gameDocumentLatest.id!
    );
  } else {
    game = await GetGameAsyncById(gameId);
    gameDocumentLatest = await GetGameDocumentLatestAsync(gameId);
    gameDocumentContent = await GetGameDocumentContentAsync(
      gameId,
      gameDocumentLatest.id!
    );
  }

  const gameRequest: GameResponse = {
    ...game,
    id: 0,
    name: `COPY_${game.name}`,
    ...(isFromGlobalLibrary && { source: 'Imported' })
  };

  const gameResponse: GameResponse = await AddGamesAsync(gameRequest);

  if (gameResponse && gameResponse.id! > 0) {
    let newFilename: string[] = gameDocumentLatest.fileName?.split('-') ?? [];

    if (newFilename && newFilename.length > 0) {
      newFilename[0] = gameResponse.id!.toString();

      const gameDocumentRequest: GameDocumentVersionResponse = {
        ...gameDocumentLatest,
        gameId: gameResponse.id,
        version: '0.0.1',
        fileName: `${newFilename.join('-')}`,
        status: 'new'
      };

      const gameDocumentVersionResponse: GameDocumentVersionResponse =
        await AddGameDocumentAsync(gameResponse.id!, gameDocumentRequest);

      if (gameDocumentVersionResponse && gameDocumentVersionResponse.id! > 0) {
        const resourceEntity: Resource = GetResourceEntity(
          gameDocumentContent,
          gameDocumentContent.overview?.titleResId!
        );
        // update title value
        resourceEntity.value = gameRequest.name;

        // update resource
        gameDocumentContent = await UpdateResourceAsync(
          gameDocumentContent,
          gameDocumentContent.overview?.titleResId!,
          resourceEntity
        );

        gameDocumentContent.version = '0.0.1';

        await AddGameDocumentContentAsync(
          gameResponse.id!,
          gameDocumentVersionResponse.id!,
          gameDocumentContent
        );
      }
    }
  }
};

/**
 * Add theme.
 * @returns Theme.
 * @param gameId - The game ID
 * @param theme - The theme object
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostThemeAsync = async (gameId: number, theme: Theme) =>
  PostAsync<Theme>(`${GamesEndpoint}/${gameId}/themes`, theme);

/**
 * Update theme.
 * @returns Theme.
 * @param gameId - The game ID
 * @param theme - The theme object
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutThemeAsync = async (gameId: number, theme: Theme) =>
  PutAsync<Theme>(`${GamesEndpoint}/${gameId}/themes`, theme);

/**
 * Get theme.
 * @returns Theme.
 * @param gameId - The game ID
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetThemeAsync = async (gameId: number) =>
  GetAsync<Theme>(`${GamesEndpoint}/${gameId}/themes`);

/**
 * Launches the latest version of the game on the selected environment.
 * @param gameId - the ID of the Game to LAUNCH
 * @param environmentId - the ID of the environment to launch on
 * @returns Game url string.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const LaunchGameAsync = async (
  gameId: number,
  environmentId: number
) => {
  return fetch(`${ApiBaseUrl}/${GamesEndpoint}/${gameId}/launch`, {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      ...GetBearerHeader()
    },
    body: environmentId.toString()
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text().then((string) => string);
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
};
