import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';

interface PdfViewerProps {
  source: string | File; // Accepts either a URL or a local File object
}

const PdfViewerComponent: React.FC<PdfViewerProps> = ({ source }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    // Handle local files by creating an object URL
    if (source instanceof File) {
      const url = URL.createObjectURL(source);
      setFileUrl(url);
    } else {
      setFileUrl(source); // Directly use the URL for remote sources
    }

    // Clean up the object URL on unmount
    return () => {
      if (source instanceof File) {
        URL.revokeObjectURL(fileUrl as string);
      }
    };
  }, [source]);

  return (
    <div style={{ height: '700px', width: '100%' }}>
      {fileUrl ? (
        <PDFViewer url={fileUrl ?? ''} style={{ height: '100%' }} />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfViewerComponent;
