import { useEffect, useState } from 'react';
import { BreadcrumbLocation, PrivatePage } from '../components/private-page';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Label } from '@progress/kendo-react-labels';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toastStore } from '../stores/toast-store';
import { formatDateToDDMMYYYY } from '../utils/date';
import Hero from '../components/hero';
import { getParameterByName } from '../utils/urlParameter';
import { GameResponse } from '../types/responses/game-response';
import { GameDocument } from '../types/game-document';
import {
  CopyGameAsync,
  GetApprovedGameAsyncById,
  GetApprovedSummaryAsync,
  GetGameAsyncById,
  GetGameSummaryAsync
} from '../services/games';
import {
  GetApprovedGameDocumentContentAsync,
  GetApprovedGameDocumentLatestAsync,
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../services/json-document';
import { GetResourceValue } from '../utils/game-document';
import { Summary } from '../components/overview';
import { appStore } from '../stores/app-store';
import { FileUrl } from './game-designer/game-overview';
import _ from 'lodash';
import PdfViewerComponent from '../components/media/pdf-viewer';
import { GameSummaryResponse } from '../types/responses/game-summary-response';

export type PageType = 'My library' | 'Global library';

export function LibraryPage() {
  const { id } = useParams();
  const libraryID = parseInt(id!);

  const [game, setGames] = useState<GameResponse>();
  const [gameDocumentContent, setGameDocumentContent] =
    useState<GameDocument>();
  const [gameSummary, setGameSummary] = useState<GameSummaryResponse>();
  const navigate = useNavigate();
  const [page, setPage] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLocation[]>([]);
  const [pageType, setPageType] = useState<PageType>('Global library');
  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  useEffect(() => {
    let page = getParameterByName('page', window.location.href);

    if (page) {
      setPage(page);
    }
  }, []);

  useEffect(() => {
    if (page && page !== '') {
      if (id) {
        fetchGame(libraryID);
      }
      if (page.toLowerCase() === 'mylibrary') {
        setPageType('My library');
        setBreadcrumbs([
          {
            label: 'Dashboard',
            href: 'dashboard'
          },
          {
            label: 'My Library',
            href: 'mylibrary/games'
          },
          {
            label: 'Details',
            href: `global-games/${libraryID}`
          }
        ] as BreadcrumbLocation[]);
      } else {
        setPageType('Global library');
        setBreadcrumbs([
          {
            label: 'Dashboard',
            href: 'dashboard'
          },
          {
            label: 'Global Library',
            href: 'global-games'
          },
          {
            label: 'Details',
            href: `global-games/${libraryID}`
          }
        ] as BreadcrumbLocation[]);
      }
    }
  }, [page]);

  const fetchGame = async (gameId: number) => {
    try {
      appStore.showLoading();
      let game;
      let summary;
      let gameDocumentLatest;
      let gameDocumentContent;

      if (page.toLowerCase() === 'mylibrary') {
        game = await GetGameAsyncById(gameId);
        gameDocumentLatest = await GetGameDocumentLatestAsync(gameId);
        gameDocumentContent = await GetGameDocumentContentAsync(
          gameId,
          gameDocumentLatest.id!
        );
        summary = await GetGameSummaryAsync(gameId);
      } else {
        game = await GetApprovedGameAsyncById(gameId);
        gameDocumentLatest = await GetApprovedGameDocumentLatestAsync(gameId);
        gameDocumentContent = await GetApprovedGameDocumentContentAsync(
          gameId,
          gameDocumentLatest.id!
        );
        summary = await GetApprovedSummaryAsync(gameId);
      }

      setGames(game);
      setGameSummary(summary);
      setGameDocumentContent(gameDocumentContent);
    } catch (ex) {
      console.error('Error message:', ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const handleBack = () => {
    if (pageType === 'Global library') {
      navigate('/global-games');
    } else if (pageType === 'My library') {
      navigate('/mylibrary/games');
    }
  };

  const onCopyLibrary = async () => {
    try {
      appStore.showLoading();

      await CopyGameAsync(libraryID, page.toLowerCase() !== 'mylibrary');

      toastStore.show(
        'My library',
        <div>
          Game copied to My Library.
          <br />
          <Link to={'/mylibrary/games'}>Go to My Library.</Link>
        </div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show('My library', 'Failed to copy to My Library.', 'error');
    } finally {
      appStore.hideLoading();
    }
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));

      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url);
      }
    }
  };
  return (
    <PrivatePage breadcrumb={breadcrumbs} pageTitle={pageType}>
      <div className={'d-flex justify-content-between align-items-center'}>
        <h2 className={'m-0'}>{game?.name}</h2>
        <div className={'d-flex gap-2'}>
          <Button
            variant={'secondary'}
            className={'text-white'}
            onClick={handleBack}>
            Back
          </Button>
          <Button
            variant={'primary'}
            className={'text-white'}
            onClick={onCopyLibrary}>
            Copy to My Templates
          </Button>
        </div>
      </div>
      <hr />

      <div className={'d-flex mt-2 flex-column flex-xl-row'}>
        <Container>
          <Row>
            <Col xs={12} className={'mb-3'}>
              <Hero
                src={
                  gameDocumentContent &&
                  GetResourceValue(
                    gameDocumentContent!,
                    gameDocumentContent?.overview?.headerResId!
                  )
                }
                editable={false}
              />
              <div
                className={'d-flex h-4 align-items-center'}
                style={{
                  background: 'rgb(0, 58, 100)'
                }}>
                <div>
                  <h4 className={'text-light fw-bold ml-22 mb-0'}>
                    {game?.name}
                  </h4>
                </div>
              </div>
            </Col>

            <Col xs={12} className={'mb-5 w-100'}>
              <Col md={3}>
                <div className={'d-flex flex-column'}>
                  <div
                    className={'round-card rounded-full h-9 w-9 mr-12 bg-white'}
                    style={{
                      marginTop: '-130px',
                      backgroundImage: `url("${
                        gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent,
                          gameDocumentContent?.overview?.logoResId!
                        )
                      }")`
                    }}
                  />
                </div>
              </Col>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <p className={'fw-bold'}>{game && game.about}</p>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <Row>
                <Col xs={4}>
                  <span>Players</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.recommendedPlayers}
                  </p>
                </Col>
                <Col xs={4}>
                  <span>Duration</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.recommendedDuration}
                  </p>
                </Col>
                <Col xs={4}>
                  <span>Players per Team</span>
                  <p className={'text-success fw-bold'}>
                    {game && game.playersPerTeam}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <Label className={'fw-bold'}>How it works</Label>
              <p>{game && game.howItWorks}</p>
            </Col>

            <Col xs={12} className={'mb-3'}>
              <div className={'learning-outcomebox'}>
                <Label className={'fw-bold'}>Learning outcomes</Label>
                <ul>
                  {game &&
                    game.learningOutcome?.map((outcome, idx) => (
                      <li key={idx}>{outcome}</li>
                    ))}
                </ul>
              </div>
            </Col>

            <Col>
              <Label className={'fw-bold'}>Unique selling points</Label>
              <ul>
                {game &&
                  game.sellingPoints?.map((selling, idx) => (
                    <li key={idx}>{selling}</li>
                  ))}
              </ul>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <div className="video-wrapper">
                {previewItemType === 'video' && (
                  <video
                    key={previewItem}
                    className="video"
                    loop
                    controls
                    preload="none">
                    <source src={previewItem} type="video/mp4" />
                  </video>
                )}
                {previewItemType === 'image' && (
                  <img src={previewItem} className={'img-fluid'} />
                )}

                {previewItemType === 'pdf' && (
                  <PdfViewerComponent source={previewItem}></PdfViewerComponent>
                )}
              </div>
            </Col>

            <Col xs={12} className={'mb-2'}>
              <div className={'thumb-nails d-flex flex-wrap'}>
                {game?.promotionalMedias &&
                  game.promotionalMedias.length > 0 &&
                  game.promotionalMedias.map((file, index) => (
                    <div
                      key={index}
                      className={
                        'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center cursor-pointer'
                      }
                      onClick={() =>
                        onClickMedia({
                          url: file.mediaUrl,
                          type: _.lowerCase(file.mediaType)
                        })
                      }>
                      {file && _.lowerCase(file.mediaType) === 'video' && (
                        <div
                          className={
                            'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                          }
                          style={{
                            backgroundImage: `url("${file.mediaUrl}")`,
                            backgroundPosition: 'center'
                          }}>
                          <button className={'btn text-light'}>
                            <span className={'material-symbols-outlined'}>
                              play_arrow
                            </span>
                          </button>
                        </div>
                      )}
                      {file && _.lowerCase(file.mediaType) === 'image' && (
                        <img
                          src={file.mediaUrl ?? ''}
                          className={'rounded'}
                          alt={'media'}
                        />
                      )}

                      {file && _.lowerCase(file.mediaType) === 'pdf' && (
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: '50px' }}>
                          picture_as_pdf
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className={'mb-2'}>
              <hr />
            </Col>
          </Row>
        </Container>
        <Summary
          author={gameSummary?.createdBy}
          authorAvatar={gameSummary?.creatorAvatar}
          createdDate={
            gameSummary?.createdDateUtc &&
            formatDateToDDMMYYYY(gameSummary?.createdDateUtc!, true)
          }
          updateDate={
            gameSummary?.modifiedDateUtc &&
            formatDateToDDMMYYYY(gameSummary?.modifiedDateUtc!, true)
          }
          version={gameSummary?.version}
        />
      </div>
    </PrivatePage>
  );
}
